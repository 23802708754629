import React from 'react'
import { courses } from '../../../Data/Data';
import { useThemes } from '../../../UserContext/UserContext';
import Footer from '../../Visiteurs/FootentContent/Footer';
import { useNavigate } from 'react-router-dom';
import AdminCoursNavBars from './AdminCoursNavBars';

function AdminCours() {
  const { isDarVisitLoggined,SetDetailCours } = useThemes()
  const navig = useNavigate()
  return (
    <div className={`w-full overflow-y-auto ${isDarVisitLoggined === "dark" ? null : 'bg-gray-100'}  overflow-x-hidden h-[87vh]`}>
      <div className='min-h-[87vh] p-4'>
        <AdminCoursNavBars/>
        <h1 className="text-2xl font-bold mb-4">Listes des cours</h1>
        <div className='flex w-full justify-between mb-4 items-center'>
          <div className='flex w-[60%] items-center'>
            <div className='w-full'>
              <input className='p-2 w-full bg-transparent outline-none rounded-s border focus:border focus:border-[#3ad12c]' placeholder='Rechercher le cours' />
            </div>
            <button className="bg-[#3ad12c86] cursor-pointer border border-[#3ad12c] hover:bg-[#3ad12c] text-white font-semibold  p-2 rounded-e inline-block">
              Recherche
            </button>
          </div>
          <button onClick={() => navig("/cours/ajout")} className='border px-3 py-1 rounded text-lg border-[#3ad12c86] text-[#3ad12c86] hover:text-[#3ad12c] hover:border-[#3ad12c]'>
            Ajouter
          </button>
        </div>

        <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-4 mb-4">
          {courses.map((d, i) => (
            <div key={i} className={`rounded-md ${isDarVisitLoggined === "dark" ? 'bg-gray-800' : 'bg-white'} p-4`}>
              <img src="https://file3.chinastory.cn/group1/M00/CB/77/CgoTp2BRseKAUAYrAA45N8sSgB842.jpeg" alt="Introduction à la Biodiversité au Burundi" className="rounded-md mb-2" />
              <h2 className="text-lg font-semibold mb-2 text-[#3ad12c]">{d.titre}</h2>
              <p className="">{d.context}</p>
              <div className='flex flex-col'>
                <div className='w-full  flex justify-between'>
                  <div className="font-semibold py-2  rounded inline-block text-yellow-500">Niveau: {d.niveau}</div>
                  <div className="py-2  rounded inline-block">Durée: 10 semaines</div>
                </div>
                <div className='flex w-full justify-between items-center'>
                  <div onClick={()=>navig("/cours/modif")} className=' border p-2 border-blue-600 rounded text-blue-600 hover:scale-105 transition-all cursor-pointer'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-pencil-square" viewBox="0 0 16 16">
                      <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                      <path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5z" />
                    </svg>
                  </div>
                  <div  onClick={() => { navig("/cours/detail"); SetDetailCours(d) }}  className=' border p-2 border-[#3ad12c] rounded text-[#3ad12c] hover:scale-105 transition-all cursor-pointer'>

                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-eye-fill" viewBox="0 0 16 16">
                      <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0" />
                      <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8m8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7" />
                    </svg>
                  </div>
                  <div className=' border p-2 border-[red] rounded text-[red] hover:scale-105 transition-all cursor-pointer'>

                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-trash3-fill" viewBox="0 0 16 16">
                      <path d="M11 1.5v1h3.5a.5.5 0 0 1 0 1h-.538l-.853 10.66A2 2 0 0 1 11.115 16h-6.23a2 2 0 0 1-1.994-1.84L2.038 3.5H1.5a.5.5 0 0 1 0-1H5v-1A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5m-5 0v1h4v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5M4.5 5.029l.5 8.5a.5.5 0 1 0 .998-.06l-.5-8.5a.5.5 0 1 0-.998.06m6.53-.528a.5.5 0 0 0-.528.47l-.5 8.5a.5.5 0 0 0 .998.058l.5-8.5a.5.5 0 0 0-.47-.528M8 4.5a.5.5 0 0 0-.5.5v8.5a.5.5 0 0 0 1 0V5a.5.5 0 0 0-.5-.5" />
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default AdminCours