import React, { useState } from 'react'
import { problems } from '../../../Data/Data'
import { useThemes } from '../../../UserContext/UserContext'
import SignaleForm from './SignaleForm'
import { Drawer } from 'rsuite'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
const comments = [
    {
        author: "Marie Durant",
        content: "J'espère que le problème sera résolu rapidement.",
        date: "2023-08-27",
    },
    {
        author: "Pierre Martin",
        content: "N'hésitez pas à appeler un plombier en urgence.",
        date: "2023-08-28",
    },
]


function Signale() {
    const { isDark ,HundlwScrollTop} = useThemes()
    const navig = useNavigate()
    const [isCommenatire, GetisCommenatire] = useState(false)
    const [newComment, setNewComment] = useState('');

    const handleCommentSubmit = (e) => {
        
        if (newComment.trim() === '') {
            toast.warning("Le champ de commentaire ne doit pas vide !!")
            return
        } else {
            toast.success("Merci à votre commentaire")
            setNewComment('');
            GetisCommenatire(false)
        }
      

    }


    return (
        <div className=' mt-[18vh]  overflow-hidden'>
            <div onClick={() => {navig("/signal");HundlwScrollTop()}} className='w-7 m-2 h-7 bg-[#3ad12c] rounded-full cursor-pointer flex justify-center items-center text-white'>
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-arrow-left-short" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5" />
                </svg>
            </div>
            <div className="container mx-auto p-4">
                <div data-aos="fade-up" className="grid grid-cols-1 gap-6">

                    <div


                        className={`${isDark === "dark" ? 'bg-gray-800' : 'bg-white'} rounded-lg p-6`}
                    >
                        <div className='h-[60vh]'>
                            <img className='w-auto h-full' src='https://cdn.futura-sciences.com/sources/images/qr/QR_Pression_eau_1%201920%20px.jpg' alt='  ' />
                        </div>

                        <h2 className="text-xl font-semibold mb-2">Jean Dupont</h2>
                        <p className="">
                            <strong>Contact :</strong> jean.dupont@example.com
                        </p>
                        <div className=" text-lg my-2">
                            Fuite d'eau dans la salle de bain.

                            Mercredi 21 août, de nombreux foyers ont connu des coupures d'eau ou une pression plus basse à Challans, Soullans et au Perrier.

                            Mercredi 21 août, de nombreux foyers ont connu des coupures d'eau ou une pression plus basse à Challans, Soullans et au Perrier.

                            Mercredi 21 août, de nombreux foyers ont connu des coupures d'eau ou une pression plus basse à Challans, Soullans et au Perrier.
                        </div>
                        <p className="">
                            <strong>Lieu :</strong> Rue de la République, Paris
                        </p>
                        <p className="">
                            <strong>Gravité :</strong> critical
                        </p>
                        <p className="">
                            <strong>Actions :</strong> J'ai coupé l'eau principale.
                        </p>
                        <p className="">
                            <strong>Attentes :</strong> Réparation immédiate.
                        </p>
                    </div>
                </div>
                <div className='flex justify-end w-full'>
                    <div onClick={() => GetisCommenatire(!isCommenatire)} class="bg-[#3ad12c] cursor-pointer hover:scale-105 transition-all text-white font-bold py-2 px-4 rounded-lg inline-block mt-4">
                        Votre commentaire
                    </div>
                </div>
                {isCommenatire && (
                    <div className="mt-4">
                        <textarea
                            className="w-full h-[15em] rounded-md border outline bg-transparent focus:border-[#3ad12c] border-gray-300 p-2"
                            placeholder="Ajouter un commentaire..."
                            value={newComment}
                            onChange={(e) => setNewComment(e.target.value)}
                        ></textarea>
                        <button
                            className="bg-[#3ad12c] hover:scal105 transition-all text-white font-medium py-2 px-4 rounded-md mt-2"
                            onClick={handleCommentSubmit}
                        >
                            Envoyer
                        </button>
                    </div>
                )}
                <hr />
                <div className='mb-10 text-lg'>
                    Commentaires
                </div>
                {comments.map((comment, i) => (
                    <div key={i} data-aos="fade-up" className={` mt-3 ${isDark === "dark" ? 'bg-gray-800' : 'bg-white'} shadow-md rounded-lg p-6`}>
                        <div className="flex items-start">
                            <div className="rounded-full bg-[#3ad12c] text-white w-8 h-8 flex items-center justify-center mr-4">
                                {comment.author.charAt(0).toUpperCase()}
                            </div>
                            <div>
                                <h3 className="font-bold mb-1">{comment.author}</h3>
                                <p className="">{comment.content}</p>
                                <div className='flex items-center gap-2'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi text-[#3ad12c] bi-calendar-check" viewBox="0 0 16 16">
                                        <path d="M10.854 7.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7.5 9.793l2.646-2.647a.5.5 0 0 1 .708 0" />
                                        <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5M1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4z" />
                                    </svg>
                                    <div className="text-sm">{comment.date}</div>    </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default Signale