import React, { useRef, useState } from 'react'
import Footer from '../../Visiteurs/FootentContent/Footer'
import { useThemes } from '../../../UserContext/UserContext'
import { useNavigate } from 'react-router-dom'
import { Drawer } from 'rsuite'
function AdminFaitsDetail() {
    const { isDarVisitLoggined } = useThemes()
    const navig = useNavigate()
    const [isModifi, SetIsModifi] = useState(false)
    const inputRefssous_titre = useRef(null)
    return (
        <div className={`w-full overflow-y-auto ${isDarVisitLoggined === "dark" ? null : 'bg-gray-100'}  overflow-x-hidden h-[87vh]`}>
            <div className="w-full flex justify-start">
                <div onClick={() => navig("/fait")} className="text-[#3ad12c] p-2 cursor-pointer">
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-arrow-left-circle-fill" viewBox="0 0 16 16">
                        <path d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0m3.5 7.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5z" />
                    </svg>
                </div>
            </div>

            <h2 class="text-2xl mt-5 p-3 font-bold mb-4">10 Faits Environnementaux et Climatiques Essentiels pour le Burundi</h2>
            <div class="grid grid-cols-4 gap-4 p-3">
                {[1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1].map((_, i) => (
                    <div className='p-5 rounded-e-xl  border-l-2 border-[#3ad12c]  bg-[#3ad12c2a]'>
                        <div class="text-lg font-bold mb-2">{i + 1} .Déforestation</div>
                        <p class="">Le taux de déforestation atteint 9% par an, principalement pour l'agriculture et la production de charbon de bois.</p>
                        <div className='flex w-full mt-5 justify-between items-center'>
                            <div onClick={() => SetIsModifi(true)} className=' border p-2 border-blue-600 rounded text-blue-600 hover:scale-105 transition-all cursor-pointer'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-pencil-square" viewBox="0 0 16 16">
                                    <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                                    <path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5z" />
                                </svg>
                            </div>
                            <div className=' border p-2 border-[red] rounded text-[red] hover:scale-105 transition-all cursor-pointer'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-trash3-fill" viewBox="0 0 16 16">
                                    <path d="M11 1.5v1h3.5a.5.5 0 0 1 0 1h-.538l-.853 10.66A2 2 0 0 1 11.115 16h-6.23a2 2 0 0 1-1.994-1.84L2.038 3.5H1.5a.5.5 0 0 1 0-1H5v-1A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5m-5 0v1h4v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5M4.5 5.029l.5 8.5a.5.5 0 1 0 .998-.06l-.5-8.5a.5.5 0 1 0-.998.06m6.53-.528a.5.5 0 0 0-.528.47l-.5 8.5a.5.5 0 0 0 .998.058l.5-8.5a.5.5 0 0 0-.47-.528M8 4.5a.5.5 0 0 0-.5.5v8.5a.5.5 0 0 0 1 0V5a.5.5 0 0 0-.5-.5" />
                                </svg>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
            <p class=" m-4 text-[20px]"><span className='text-[25px] text-[#3ad12c]'>Source</span>: Atlas de la Biodiversité du Burundi</p>
            <Footer />
            <Drawer placement='right' size="xs" open={isModifi} onClose={() => SetIsModifi(false)}>
                <Drawer.Header>
                    <Drawer.Title>Modifier</Drawer.Title>
                </Drawer.Header>
                <Drawer.Body>
                    <div className='flex flex-col mt-5'>
                        <div className="w-full">
                            <input
                                ref={inputRefssous_titre}
                                className="appearance-none block  w-full bg-transparent text-grey-darker border border-red rounded  p-2 mb-3"
                                placeholder={`Sous titre`}
                            />
                        </div>
                        <div className="flex justify-center my-5  w-[98%]">
                            <button type="submit" htmlFor="send" className="mt-3 cursor-pointer w-full flex justify-center bg-[#3ad12c] p-2 rounded text-white">
                                Modifier
                            </button>
                        </div>
                    </div>
                </Drawer.Body>
            </Drawer>
        </div>
    )
}

export default AdminFaitsDetail