import React from 'react'
import { useThemes } from '../../../UserContext/UserContext'
import { useNavigate } from 'react-router-dom'

function AdiminRapportDetail() {
    const { isDarVisitLoggined } = useThemes()
    const navig = useNavigate()

    return (
        <div className={`w-full overflow-y-auto ${isDarVisitLoggined === "dark" ? null : 'bg-gray-100'}  overflow-x-hidden h-[87vh]`}>
            <div className="w-full flex justify-start">
                <div onClick={() => navig("/rapport")} className="text-[#3ad12c] p-2 cursor-pointer">
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-arrow-left-circle-fill" viewBox="0 0 16 16">
                        <path d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0m3.5 7.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5z" />
                    </svg>
                </div>
            </div>
            <div class={` p-4 m-4 text-white rounded-lg bg-[#3ad12c] shadow-md`}>
                <h1 class="text-xl font-bold">1. Déforestation</h1>
                <p class="mt-2">Taux de couverture forestière : 6,6% en 2023 (contre 11,3% en 2000)</p>
            </div>

            <div class={`p-6 m-4 rounded-lg ${isDarVisitLoggined === "dark" ? 'bg-gray-800' : 'bg-white'} shadow-md`}>
                <h2 class="text-lg font-semibold">Actions réalisées :</h2>
                <ul class="list-disc list-inside mt-2">
                    <li>Plantation de 50 millions d'arbres (2018-2023)</li>
                    <li>Création de 3 nouvelles réserves forestières</li>
                    <li>Création de 3 nouvelles réserves forestières</li>
                    <li>Création de 3 nouvelles réserves forestières</li>
                    <li>Création de 3 nouvelles réserves forestières</li>
                    <li>Création de 3 nouvelles réserves forestières</li>
                    <li>Création de 3 nouvelles réserves forestières</li>
                    <li>Création de 3 nouvelles réserves forestières</li>
                    <li>Création de 3 nouvelles réserves forestières</li>
                </ul>

                <h2 class="text-lg font-semibold mt-4">Actions à entreprendre :</h2>
                <ul class="list-disc list-inside mt-2">
                    <li>Atteindre 15% de couverture forestière d'ici 2030</li>
                    <li>Mettre en place un système de surveillance par satellite</li>
                    <li>Mettre en place un système de surveillance par satellite</li>
                    <li>Mettre en place un système de surveillance par satellite</li>
                    <li>Mettre en place un système de surveillance par satellite</li>
                    <li>Mettre en place un système de surveillance par satellite</li>
                    <li>Mettre en place un système de surveillance par satellite</li>
                    <li>Mettre en place un système de surveillance par satellite</li>
                    <li>Mettre en place un système de surveillance par satellite</li>
                    <li>Mettre en place un système de surveillance par satellite</li>
                    <li>Mettre en place un système de surveillance par satellite</li>
                    <li>Mettre en place un système de surveillance par satellite</li>
                </ul>
            </div>
        </div>
    )
}

export default AdiminRapportDetail