import React, { useState } from 'react';
import { toast } from 'react-toastify';

const RessourceFormulaire = () => {
  const [image, setImage] = useState(null);
  const [preview, setPreview] = useState('');
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [errors, setErrors] = useState({});

  const validateForm = () => {
    const newErrors = {};
    
    // Image validation
    if (!image) {
      newErrors.image = 'Une image est requise.';
    } else if (!['image/jpeg', 'image/png'].includes(image.type)) {
      newErrors.image = 'Le fichier doit être une image JPEG ou PNG.';
    } else if (image.size > 2 * 1024 * 1024) { // Limiting file size to 2MB
      newErrors.image = 'La taille de l\'image ne doit pas dépasser 2 Mo.';
    }
    
    // Title validation
    if (title.trim().length < 3) {
      newErrors.title = 'Le titre doit contenir au moins 3 caractères.';
    }
    
    // Description validation
    if (description.trim().length < 10) {
      newErrors.description = 'La description doit contenir au moins 10 caractères.';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      toast.success("Form submitted");
      console.log('Form submitted:', { image, title, description });
    }
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImage(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="w-full sm:w-[60%] mx-auto p-6  shadow-md rounded-lg">
      <div className="mb-4">
      <label htmlFor="image" className={`bg-transparent cursor-pointer py-3 pl-2 flex w-full border rounded ${errors.image ? 'border-red-500' : 'focus:ring-teal-300'}`}>
                    Image
                </label>
        <input
          type="file"
          className="block w-full text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 focus:outline-none"
          id="image"
          name="image"
          hidden
          onChange={handleImageChange}
        />
        {preview && (
          <div className="mt-4">
            <img src={preview} alt="" className="max-w-full h-auto rounded-lg border border-gray-300 shadow-sm" />
          </div>
        )}
        {errors.image && <small className="text-red-500">{errors.image}</small>}
      </div>

      <div className="mb-4">
        <label htmlFor="title-input" className="text-lg font-bold mb-2">Titre</label>
        <input
          type="text"
          className="block w-full p-3 bg-transparent border border-gray-300 rounded-lg focus:outline-none focus:border-[#3ad12c]"
          id="title-input"
          name="title"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />
        {errors.title && <small className="text-red-500">{errors.title}</small>}
      </div>

      <div className="mb-4">
        <label htmlFor="description-input" className="text-lg font-bold mb-2">Description</label>
        <textarea
          className="block w-full px-4 py-2 min-h-[15em] bg-transparent border border-gray-300 rounded-lg focus:outline-none focus:border-[#3ad12c]"
          id="description-input"
          name="description"
          rows="3"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        ></textarea>
        {errors.description && <small className="text-red-500">{errors.description}</small>}
      </div>

      <button
        type="submit"
      className="w-max mb-10 px-4 py-2 font-bold text-white bg-[#3ad12c] rounded-lg hover:scale-105 transition-all focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
      >
        Enregistrer
      </button>
    </form>
  );
};

export default RessourceFormulaire;
