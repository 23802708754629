export const teamMembers = [
    {
      name: "Sophia Dupont",
      role: "Directrice des partenariats",
      profile: "Expérience de 10 ans dans la gestion de projets internationaux et le développement de partenariats stratégiques."
    },
    {
      name: "Liam Mercier",
      role: "Responsable de l'innovation",
      profile: "Spécialiste de l'éducation et de la sensibilisation environnementale, avec un fort intérêt pour les nouvelles approches pédagogiques."
    },
    {
      name: "Olivia Rousseau",
      role: "Chargée de communication",
      profile: "Compétences avérées dans la conception et la mise en œuvre de campagnes de communication engageantes autour des enjeux environnementaux."
    },
    {
      name: "Lucas Fontaine",
      role: "Analyste de données",
      profile: "Expertise dans l'analyse de données et la création d'indicateurs de performance pour mesurer l'impact des initiatives environnementales."
    },
    {
      name: "Emma Girard",
      role: "Responsable de la formation",
      profile: "Expérience dans la conception et la mise en œuvre de programmes de formation pour les enseignants et les acteurs locaux."
    },
    {
      name: "Mathieu Roussel",
      role: "Coordinateur de projets",
      profile: "Compétences solides dans la gestion de projets complexes, de la planification à la mise en œuvre et à l'évaluation."
    },
    {
      name: "Léa Dupuis",
      role: "Responsable des partenariats",
      profile: "Expérience réussie dans l'établissement de partenariats durables avec des organisations gouvernementales et non gouvernementales."
    },
    {
      name: "Maxime Moreau",
      role: "Responsable de la logistique",
      profile: "Expertise dans la logistique et la gestion d'approvisionnements pour des programmes à grande échelle."
    },
    {
      name: "Camille Leroy",
      role: "Chargée de suivi et évaluation",
      profile: "Compétences avérées dans la conception et la mise en œuvre de systèmes de suivi et d'évaluation pour mesurer l'impact des initiatives."
    },
    {
      name: "Théo Mercier",
      role: "Spécialiste des technologies vertes",
      profile: "Connaissances approfondies des technologies vertes et de leur application dans les programmes éducatifs et de sensibilisation."
    },
    {
      name: "Chloé Lefebvre",
      role: "Responsable de la mobilisation communautaire",
      profile: "Expérience réussie dans l'engagement et la mobilisation des communautés locales autour des enjeux environnementaux."
    },
    {
      name: "Romain Fontaine",
      role: "Coordinateur des bénévoles",
      profile: "Expertise dans la gestion et la coordination d'équipes de bénévoles pour soutenir les initiatives sur le terrain."
    },
    {
      name: "Manon Durand",
      role: "Chargée de la durabilité",
      profile: "Compétences dans l'intégration des principes de durabilité dans la conception et la mise en œuvre des programmes."
    },
    {
      name: "Adrien Gérard",
      role: "Responsable de la recherche",
      profile: "Expérience dans la conduite de recherches et d'études pour éclairer les prises de décision et l'orientation stratégique."
    },
    {
      name: "Pauline Mercier",
      role: "Chargée de la mobilisation des jeunes",
      profile: "Expertise dans l'engagement et l'autonomisation des jeunes dans les initiatives environnementales."
    },
    {
      name: "Hugo Fontaine",
      role: "Coordinateur des formations",
      profile: "Compétences dans la conception et la mise en œuvre de programmes de formation adaptés aux différents publics cibles."
    },
    {
      name: "Juliette Leroy",
      role: "Responsable des partenariats internationaux",
      profile: "Expérience réussie dans l'établissement de partenariats internationaux dans le domaine de l'environnement et de l'éducation."
    },
    {
      name: "Thomas Mercier",
      role: "Chargé de la mobilisation des médias",
      profile: "Compétences avérées dans la conception et la mise en œuvre de stratégies de communication médiatique autour des enjeux environnementaux."
    },
    {
      name: "Anaïs Girard",
      role: "Responsable de la mobilisation des entreprises",
      profile: "Expertise dans l'engagement et la mobilisation du secteur privé autour des initiatives de développement durable."
    },
    {
      name: "Guillaume Roussel",
      role: "Coordinateur des volontaires internationaux",
      profile: "Expérience dans la gestion de programmes d'échanges et de volontariat international dans le domaine de l'environnement."
    }
  ];

  export const courses = [
    {
      image: 'https://example.com/course1.jpg',
      titre: 'Introduction to Python',
      context: 'Learn the basics of Python programming, covering variables, loops, and functions.',
      niveau: 'Débutant'
    },
    {
      image: 'https://example.com/course2.jpg',
      titre: 'Web Development with HTML & CSS',
      context: 'Build modern, responsive websites using HTML and CSS.',
      niveau: 'Débutant'
    },
    {
      image: 'https://example.com/course3.jpg',
      titre: 'JavaScript Essentials',
      context: 'Understand the core concepts of JavaScript and start building interactive web pages.',
      niveau: 'Intermédiaire'
    },
    {
      image: 'https://example.com/course4.jpg',
      titre: 'ReactJS for Beginners',
      context: 'Get started with ReactJS to create dynamic user interfaces.',
      niveau: 'Débutant'
    },
    {
      image: 'https://example.com/course5.jpg',
      titre: 'Node.js Crash Course',
      context: 'Learn the basics of server-side programming using Node.js.',
      niveau: 'Intermédiaire'
    },
    {
      image: 'https://example.com/course6.jpg',
      titre: 'Advanced JavaScript Concepts',
      context: 'Dive deeper into JavaScript with topics like closures, promises, and async/await.',
      niveau: 'Avancé'
    },
    {
      image: 'https://example.com/course7.jpg',
      titre: 'Data Structures in Python',
      context: 'Learn how to implement common data structures in Python.',
      niveau: 'Intermédiaire'
    },
    {
      image: 'https://example.com/course8.jpg',
      titre: 'Full-Stack Web Development',
      context: 'Master front-end and back-end development with this full-stack course.',
      niveau: 'Avancé'
    },
    {
      image: 'https://example.com/course9.jpg',
      titre: 'Introduction to Machine Learning',
      context: 'Start your journey in machine learning with this beginner-friendly course.',
      niveau: 'Débutant'
    },
    {
      image: 'https://example.com/course10.jpg',
      titre: 'Database Management with SQL',
      context: 'Learn how to manage and query databases using SQL.',
      niveau: 'Intermédiaire'
    },
    {
      image: 'https://example.com/course11.jpg',
      titre: 'Advanced ReactJS Patterns',
      context: 'Learn advanced patterns and techniques for building complex ReactJS applications.',
      niveau: 'Avancé'
    },
    {
      image: 'https://example.com/course12.jpg',
      titre: 'UI/UX Design Fundamentals',
      context: 'Understand the principles of user interface and user experience design.',
      niveau: 'Débutant'
    },
    {
      image: 'https://example.com/course13.jpg',
      titre: 'Building RESTful APIs with Node.js',
      context: 'Learn to create and manage RESTful APIs using Node.js and Express.',
      niveau: 'Intermédiaire'
    },
    {
      image: 'https://example.com/course14.jpg',
      titre: 'Cloud Computing with AWS',
      context: 'Explore cloud services and infrastructure with Amazon Web Services.',
      niveau: 'Avancé'
    },
    {
      image: 'https://example.com/course15.jpg',
      titre: 'Cybersecurity Basics',
      context: 'Learn the fundamentals of cybersecurity and protect your applications.',
      niveau: 'Débutant'
    },
    {
      image: 'https://example.com/course16.jpg',
      titre: 'React Native for Mobile Development',
      context: 'Build mobile applications using React Native for iOS and Android.',
      niveau: 'Intermédiaire'
    },
    {
      image: 'https://example.com/course17.jpg',
      titre: 'Docker & Kubernetes',
      context: 'Learn to containerize your applications and manage them with Kubernetes.',
      niveau: 'Avancé'
    },
    {
      image: 'https://example.com/course18.jpg',
      titre: 'Artificial Intelligence Concepts',
      context: 'Understand key concepts in artificial intelligence and start building AI models.',
      niveau: 'Avancé'
    },
    {
      image: 'https://example.com/course19.jpg',
      titre: 'Data Visualization with D3.js',
      context: 'Create stunning data visualizations using D3.js.',
      niveau: 'Intermédiaire'
    },
    {
      image: 'https://example.com/course20.jpg',
      titre: 'Introduction to Blockchain',
      context: 'Learn about the basics of blockchain technology and its applications.',
      niveau: 'Débutant'
    }
  ];
  
  export const animaux = [
    {
      image: "https://cdn.britannica.com/30/150930-120-D3D93F1E/lion-Namibia.jpg",
      nom: "Lion",
      titre: "Roi de la savane",
      name: "Panthera leo",
      nomParc: "Parc National de la Pendjari"
    },
    {
      image: "https://www.wwf.fr/sites/default/files/styles/page_cover_large_16_9/public/2023-06/elephants-se-suivant-savane.jpg?h=f5b644bb&itok=t_KeV6mc",
      nom: "Éléphant",
      titre: "Géant des plaines",
      name: "Loxodonta africana",
      nomParc: "Parc National d'Arusha"
    },
    {
      image: "https://cdn.futura-sciences.com/sources/images/girafe.jpg",
      nom: "Girafe",
      titre: "Sentinelle des savanes",
      name: "Giraffa camelopardalis",
      nomParc: "Réserve de Biosphère de Waza"
    },
    {
      image: "https://resize-europe1.lanmedia.fr/r/622,311,forcex,center-middle/img/var/europe1/storage/images/europe1/sciences/pays-bas-une-femelle-rhinoceros-meurt-lors-dun-essai-daccouplement-mene-par-un-zoo-4067044/57629423-1-fre-FR/Pays-Bas-une-femelle-rhinoceros-meurt-lors-d-un-essai-d-accouplement-mene-par-un-zoo.jpg",
      nom: "Rhinocéros",
      titre: "Colosse de la savane",
      name: "Rhinocerotidae",
      nomParc: "Parc National de Kruger"
    },
    {
      image: "https://lemagdesanimaux.ouest-france.fr/images/dossiers/2020-11/zebre-071717.jpg",
      nom: "Zèbre",
      titre: "Cheval rayé",
      name: "Equus quagga",
      nomParc: "Parc National de Serengeti"
    },
    {
      image: "https://d1jyxxz9imt9yb.cloudfront.net/medialib/4387/image/s768x1300/XKP166_reduced.jpg",
      nom: "Gazelle",
      titre: "Antilope agile",
      name: "Gazella",
      nomParc: "Réserve Nationale du Masaï Mara"
    },
    {
      image: "https://cdn.futura-sciences.com/sources/images/dossier/1594/12-1594.jpg",
      nom: "Guépard",
      titre: "Sprinteur de la savane",
      name: "Acinonyx jubatus",
      nomParc: "Réserve Nationale de Tsavo"
    },
    {
      image: "https://cdn.futura-sciences.com/sources/images/diaporama/1238-juveniles/album-faune-juvenile14.jpg",
      nom: "Léopard",
      titre: "Chasseur furtif",
      name: "Panthera pardus",
      nomParc: "Parc National de Moremi"
    },
    {
      image: "https://mammiferesafricains.org/wp-content/uploads/2011/08/Hippopotame_01.jpg",
      nom: "Hippopotame",
      titre: "Géant aquatique",
      name: "Hippopotamus amphibius",
      nomParc: "Parc National de Mana Pools"
    },
    {
      image: "https://cdn.britannica.com/13/151913-050-6B9CD5F3/Nile-crocodile-fish.jpg",
      nom: "Crocodile",
      titre: "Prédateur des rivières",
      name: "Crocodylus niloticus",
      nomParc: "Parc National de l'Okavango"
    },
    {
      image: "https://www.zoodelahautetouche.fr/sites/hautetouche/files/styles/1020x576/public/thumbnails/image/flamants_roses_c_gerard_proust.jpg?itok=-CNUC5xg",
      nom: "Flamant rose",
      titre: "Oiseau gracieux",
      name: "Phoenicopterus roseus",
      nomParc: "Lac Nakuru"
    },
    {
      image: "https://www.rustica.fr/images/elevage-d-autruche-periquet-4101-rust-l790-h526.jpg.webp",
      nom: "Autruche",
      titre: "Le plus grand oiseau",
      name: "Struthio camelus",
      nomParc: "Parc National d'Etosha"
    },
    {
      image: "https://cdn.futura-sciences.com/buildsv6/images/largeoriginal/e/6/f/e6f4c23ca9_50209651_hyene-tachetee.jpg",
      nom: "Hyène",
      titre: "Charognard tenace",
      name: "Crocuta crocuta",
      nomParc: "Réserve Nationale de Samburu"
    },
    {
      image: "https://lapauseinfo.fr/wp-content/uploads/2024/02/26771140-une-bleu-serpent-naturel-contexte-gratuit-photo-scaled.jpeg",
      nom: "Serpent",
      titre: "Reptile mystérieux",
      name: "Serpentes",
      nomParc: "Parc National du Mont Kenya"
    },
    {
      image: "https://zoodubonheur.wordpress.com/wp-content/uploads/2017/10/aigle.jpg?w=1024",
      nom: "Aigle",
      titre: "Rapace majestueux",
      name: "Aquila",
      nomParc: "Réserve Nationale du Selous"
    },
    {
      image: "https://i.ytimg.com/vi/7fj9aQDyItg/maxresdefault.jpg",
      nom: "Paon",
      titre: "Oiseau orné",
      name: "Pavo cristatus",
      nomParc: "Parc National de la Garamba"
    },
    {
      image: "https://mammiferesafricains.org/wp-content/uploads/2012/02/Buf08KNmm.jpg",
      nom: "Buffle",
      titre: "Hérissé et robuste",
      name: "Syncerus caffer",
      nomParc: "Parc National de Chobe"
    },
    {
      image: "https://www.monde-animal.fr/wp-content/uploads/2020/09/pronghorn-broute.jpg",
      nom: "Antilocapre",
      titre: "Gracieux coureur",
      name: "Antilocapra americana",
      nomParc: "Réserve Nationale d'Amboseli"
    },
    {
      image: "https://d3d0lqu00lnqvz.cloudfront.net/media/media/thumbnails/b4262808-2716-48f3-bf67-36c4aaec425d.jpg",
      nom: "Pingouin",
      titre: "Oiseau des mers froides",
      name: "Spheniscidae",
      nomParc: "Réserve Marine d'Addo"
    },
    {
      image: "https://b2523235.smushcdn.com/2523235/wp-content/uploads/2021/08/Ours-Slovenie-geoffrey-demestre.jpg?lossy=1&strip=1&webp=1",
      nom: "Ours",
      titre: "Grand prédateur des forêts",
      name: "Ursidae",
      nomParc: "Parc National de Bwindi"
    }
  ];
  

  export const Sligersfade = [
    {
      image: "https://file3.chinastory.cn/group1/M00/CB/77/CgoTp2BRseKAUAYrAA45N8sSgB842.jpeg",
      sujet: " Ensemble,Préservons notre Terre",
      description: "Rejoignez IMUHIRA  dans sa mission de protéction et restaurer l'environnement du Burundi pour les générations futures",
      textButton: " Rejoignez-nous"
    },
    {
      image: "https://www.cnmsante.fr/documents/20143/979375/preservons-la-terre.jpg/09620b62-1835-1bb8-744c-b22fd7ed8616?t=1583847095315",
      sujet: "Dégustation Gourmet",
      description: "Découvrez des plats délicieux préparés par nos chefs étoilés.",
      textButton: "En savoir plus"
    },
    {
      image: "https://cdn0.projetecolo.com/fr/posts/1/7/5/comment_proteger_l_environnement_571_orig.jpg",
      sujet: "Offres Spéciales",
      description: "Profitez de nos offres exclusives disponibles cette semaine.",
      textButton: "Voir les offres"
    },
    {
      image: "https://knowhow.distrelec.com/wp-content/uploads/2023/03/Earth-day-1920x800.png",
      sujet: "Nouveautés",
      description: "Découvrez les nouveaux plats ajoutés à notre menu cette saison.",
      textButton: "Explorer"
    },
    {
      image: "https://calendrier-lunaire.info/wp-content/uploads/2018/02/conservation-de-l-eau-au-jardin.jpg",
      sujet: "Événements Privés",
      description: "Réservez nos salles pour vos événements privés et d'entreprise.",
      textButton: "Réserver"
    },
    {
      image: "https://cdn.wikifarmer.com/wp-content/uploads/2024/07/Quest-ce-que-la-conservation-de-leau.jpg",
      sujet: "Menu Végétarien",
      description: "Savourez notre sélection de plats végétariens sains et délicieux.",
      textButton: "Découvrir"
    },
    {
      image: "https://img.freepik.com/photos-premium/dechets-bouteilles-plastique-pour-reutilisation-du-concept-recyclage-dans-environnement-membres-famille-aident-collecter-bouteilles-plastique_43780-2463.jpg",
      sujet: "Cuisine Internationale",
      description: "Voyagez à travers le monde avec nos plats de différentes cultures.",
      textButton: "Commander"
    },
    {
      image: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQXMsbWU3Yyj44TubREaCkQoPHSJmmlO7oJsw&s",
      sujet: "Repas à Emporter",
      description: "Commandez vos plats préférés à emporter pour un dîner à la maison.",
      textButton: "Commander maintenant"
    },
    {
      image: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTMdRSbWyyUXX8UR8tCZ0BrXWWtcpQtQybvHA&s",
      sujet: "Brunch du Dimanche",
      description: "Joignez-vous à nous pour un brunch copieux chaque dimanche.",
      textButton: "Réserver"
    },
    {
      image: "https://interservices.fr/blog/wp-content/uploads/2018/05/ecologie-jardinage-interservices-755x487.jpg",
      sujet: "Carte des Vins",
      description: "Découvrez notre sélection de vins fins pour accompagner votre repas.",
      textButton: "Voir la carte"
    },
    {
      image: "https://www.connexion-nature.com/wp-content/uploads/2021/02/bienfaits-biodiversite.png",
      sujet: "Carte des Vins",
      description: "Découvrez notre sélection de vins fins pour accompagner votre repas.",
      textButton: "Voir la plus"
    }
  ];
  
  export const questionsReponses = [
    {
      id: 1,
      question: "1. Quel est le principal objectif de l'agroécologie ?",
      options: ["Maximiser les rendements à court terme", "Créer des systèmes agricoles durables et résilients", "Promouvoir l'utilisation intensive de pesticides"],
      correctAnswer: "Créer des systèmes agricoles durables et résilients"
    },
    {
      id: 2,
      question: "2. Comment l'agroécologie peut-elle réduire l'impact environnemental de l'agriculture ?",
      options: ["En augmentant l'utilisation de pesticides", "En réduisant l'utilisation de pesticides", "En utilisant plus d'eau"],
      correctAnswer: "En réduisant l'utilisation de pesticides"
    },
    {
      id: 3,
      question: "3. Lequel de ces principes n'est PAS un principe fondamental de l'agroécologie ?",
      options: ["Diversifier les espèces dans l'agroécosystème", "Maximiser l'utilisation d'engrais chimiques", "Optimiser la disponibilité des nutriments"],
      correctAnswer: "Maximiser l'utilisation d'engrais chimiques"
    },
    {
      id: 4,
      question: "4. Quel est un avantage clé de l'agroécologie ?",
      options: ["Une meilleure résistance aux aléas climatiques", "Une utilisation accrue de produits chimiques", "Des monocultures plus efficaces"],
      correctAnswer: "Une meilleure résistance aux aléas climatiques"
    },
    {
      id: 5,
      question: "5. Quelle est une pratique courante en agroécologie ?",
      options: ["L'irrigation intensive", "La culture en rotation", "L'utilisation d'organismes génétiquement modifiés (OGM)"],
      correctAnswer: "La culture en rotation"
    },
    {
      id: 6,
      question: "6. Quelle est la principale préoccupation des agriculteurs utilisant l'agroécologie ?",
      options: ["Maximiser la production à tout prix", "Réduire les intrants chimiques", "Minimiser l'usage des ressources naturelles"],
      correctAnswer: "Réduire les intrants chimiques"
    },
    {
      id: 7,
      question: "7. L'agroécologie favorise-t-elle la biodiversité ?",
      options: ["Oui, elle encourage la diversité des cultures et des espèces", "Non, elle se concentre sur les monocultures", "Non, elle augmente l'usage des pesticides"],
      correctAnswer: "Oui, elle encourage la diversité des cultures et des espèces"
    },
    {
      id: 8,
      question: "8. Quel rôle joue l'agroécologie dans la sécurité alimentaire ?",
      options: ["Elle diminue les rendements", "Elle favorise des systèmes alimentaires durables", "Elle réduit l'accès aux produits alimentaires"],
      correctAnswer: "Elle favorise des systèmes alimentaires durables"
    },
    {
      id: 9,
      question: "9. Comment l'agroécologie contribue-t-elle à la lutte contre le changement climatique ?",
      options: ["En augmentant les émissions de gaz à effet de serre", "En réduisant l'empreinte carbone de l'agriculture", "En promouvant la déforestation"],
      correctAnswer: "En réduisant l'empreinte carbone de l'agriculture"
    },
    {
      id: 10,
      question: "10. Quel est l'un des défis de l'agroécologie ?",
      options: ["L'accès limité aux marchés", "Une réduction de la biodiversité", "Une dépendance accrue aux produits chimiques"],
      correctAnswer: "L'accès limité aux marchés"
    }
  ];
  

  export const evenements = [
    {
      date: '2024-09-15',
      heures: '09:00',
      emplacement: 'Centre Culturel de Bujumbura',
      title: 'Conférence sur le développement durable',
      description: 'Une conférence sur les stratégies de développement durable au Burundi.',
    },
    {
      date: '2024-08-15',
      heures: '10:30',
      emplacement: 'Parc National de la Rusizi',
      title: 'Atelier de préservation de la biodiversité',
      description: 'Un atelier interactif pour apprendre à préserver la biodiversité locale.',
    },
    {
      date: '2024-11-15',
      heures: '12:00',
      emplacement: 'Hôtel Club du Lac Tanganyika',
      title: 'Déjeuner réseautage',
      description: 'Un déjeuner pour rencontrer d\'autres acteurs du développement durable.',
    },
    {
      date: '2024-09-15',
      heures: '14:00',
      emplacement: 'Université du Burundi',
      title: 'Table ronde sur l\'éducation environnementale',
      description: 'Une discussion sur l\'importance de l\'éducation environnementale.',
    },
    {
      date: '2024-09-15',
      heures: '15:30',
      emplacement: 'Marché Central de Bujumbura',
      title: 'Visite guidée du marché éco-responsable',
      description: 'Découverte des initiatives éco-responsables au sein du marché central.',
    },
    {
      date: '2024-09-35',
      heures: '17:00',
      emplacement: 'Plage Saga Resha',
      title: 'Nettoyage de plage',
      description: 'Un événement communautaire pour nettoyer la plage et sensibiliser à la pollution marine.',
    },
    {
      date: '2024-09-15',
      heures: '18:30',
      emplacement: 'Cinéma CinéStar',
      title: 'Projection de film : "La Terre en danger"',
      description: 'Projection d\'un film documentaire sur les enjeux environnementaux mondiaux.',
    },
    {
      date: '2024-09-15',
      heures: '20:00',
      emplacement: 'Salle des Fêtes - Hôtel de Ville',
      title: 'Concert pour la planète',
      description: 'Un concert de musique pour soutenir les initiatives environnementales locales.',
    },
    {
      date: '2024-09-15',
      heures: '21:30',
      emplacement: 'Rooftop Bar - Hôtel Panoramique',
      title: 'Soirée de réseautage',
      description: 'Une soirée pour échanger avec d\'autres acteurs engagés dans le développement durable.',
    },
    {
      date: '2025-01-15',
      heures: '23:00',
      emplacement: 'Parc de l\'Indépendance',
      title: 'Observation des étoiles et de la pollution lumineuse',
      description: 'Une activité nocturne pour observer les étoiles et discuter de la pollution lumineuse.',
    },
  ];

  export const problems = [
    {
      name: "Jean Dupont",
      contact: "jean.dupont@example.com",
      description: "Fuite d'eau dans la salle de bain.",
      location: "Rue de la République, Paris",
      severity: "critical",
      actions: "J'ai coupé l'eau principale.",
      expectations: "Réparation immédiate.",
    },
    {
      name: "Marie Durant",
      contact: "marie.durant@example.com",
      description: "Lumières défectueuses dans le couloir.",
      location: "Avenue des Champs-Élysées, Paris",
      severity: "major",
      actions: "J'ai appelé l'électricien.",
      expectations: "Changement des lumières.",
    },
    {
      name: "Pierre Martin",
      contact: "pierre.martin@example.com",
      description: "Porte bloquée à l'entrée principale.",
      location: "Rue de la Liberté, Lyon",
      severity: "minor",
      actions: "J'ai essayé de déverrouiller la porte.",
      expectations: "Remplacement de la serrure.",
    },
    {
      name: "Julie Bernard",
      contact: "julie.bernard@example.com",
      description: "Ascenseur en panne.",
      location: "Boulevard de Strasbourg, Marseille",
      severity: "critical",
      actions: "J'ai prévenu le gardien.",
      expectations: "Réparation rapide.",
    },
    {
      name: "Lucie Petit",
      contact: "lucie.petit@example.com",
      description: "Infiltration d'eau dans le plafond.",
      location: "Rue des Fleurs, Lille",
      severity: "major",
      actions: "J'ai couvert les meubles.",
      expectations: "Inspection du plafond.",
    },
    {
      name: "Antoine Lefevre",
      contact: "antoine.lefevre@example.com",
      description: "Chauffage ne fonctionne pas.",
      location: "Rue des Tuileries, Bordeaux",
      severity: "critical",
      actions: "J'ai redémarré la chaudière.",
      expectations: "Réparation du chauffage.",
    },
    {
      name: "Sophie Garnier",
      contact: "sophie.garnier@example.com",
      description: "Fenêtre cassée dans le salon.",
      location: "Place de la Victoire, Nantes",
      severity: "major",
      actions: "J'ai mis une bâche temporaire.",
      expectations: "Remplacement de la fenêtre.",
    },
    {
      name: "Michel Dubois",
      contact: "michel.dubois@example.com",
      description: "Voiture bloquant l'entrée du garage.",
      location: "Avenue des Platanes, Nice",
      severity: "minor",
      actions: "J'ai noté la plaque d'immatriculation.",
      expectations: "Retrait de la voiture.",
    },
    {
      name: "Camille Laurent",
      contact: "camille.laurent@example.com",
      description: "Problème de pression d'eau dans la cuisine.",
      location: "Rue du Moulin, Toulouse",
      severity: "major",
      actions: "J'ai vérifié les robinets.",
      expectations: "Augmentation de la pression d'eau.",
    },
    {
      name: "Thomas Moreau",
      contact: "thomas.moreau@example.com",
      description: "Climatisation en panne.",
      location: "Boulevard Saint-Germain, Paris",
      severity: "critical",
      actions: "J'ai tenté de redémarrer le système.",
      expectations: "Réparation de la climatisation.",
    },
  ];