import React, { useState } from 'react'
import { useThemes } from '../../../UserContext/UserContext'
import { useNavigate } from 'react-router-dom'
import { Progress } from 'rsuite'
import Footer from '../../Visiteurs/FootentContent/Footer'

function AdminRessourceDetail() {
    const { isDarVisitLoggined, detailCours } = useThemes()
    const [progession, Getprogession] = useState(30)
    const navig = useNavigate()
    return (
        <div className={`w-full overflow-y-auto ${isDarVisitLoggined === "dark" ? null : 'bg-gray-100'}  overflow-x-hidden h-[87vh]`}>
            <div className="w-full flex justify-start">
                <div onClick={() => navig("/ressource")} className="text-[#3ad12c] p-2 cursor-pointer">
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-arrow-left-circle-fill" viewBox="0 0 16 16">
                        <path d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0m3.5 7.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5z" />
                    </svg>
                </div>
            </div>
            <div class={`rounded-md ${isDarVisitLoggined === "dark" ? 'bg-gray-800' : 'bg-white'} m-2  p-4`}>
                <img src="https://char.hypotheses.org/files/2017/10/Repertoire-ressources-documentaires.jpg" alt="Introduction à la Biodiversité au Burundi" class="rounded-md mb-2" />
                <h2 class="text-2xl font-semibold mb-2 ">{detailCours.titre}</h2>
            </div>




            <div class={`${isDarVisitLoggined === "dark" ? 'bg-gray-800' : 'bg-white'} mt-5 m-2 mx-auto px-4 py-8`}>

                <div class="space-y-2">
                    <div className='flex items-center'>
                        <div class="font-bold text-lg">Rapport sur l'état de l'environnement au Burundi 2023</div>
                    </div>
                </div>

                <div class="space-y-2 mt-10">
                    <div className='flex items-center'>
                        <div class="font-bold text-lg">
                            Un rapport détaillé sur la situation environnementale actuelle du Burundi, les défis et les opportunités.
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default AdminRessourceDetail