import React from 'react'
import { useThemes } from '../../../UserContext/UserContext'
import { useNavigate } from 'react-router-dom'
import Footer from '../../Visiteurs/FootentContent/Footer'

function AdminFaits() {
    const { isDarVisitLoggined } = useThemes()
    const navig = useNavigate()
    return (
        <div className={`w-full overflow-y-auto ${isDarVisitLoggined === "dark" ? null : 'bg-gray-100'}  overflow-x-hidden h-[87vh]`}>
            <div className='flex w-full p-5 justify-between mb-4 items-center'>
                <div className='flex w-[60%] items-center'>
                    <div className='w-full'>
                        <input className='p-2 w-full bg-transparent outline-none rounded-s border focus:border focus:border-[#3ad12c]' placeholder='Rechercher le cours' />
                    </div>
                    <button className="bg-[#3ad12c86] cursor-pointer border border-[#3ad12c] hover:bg-[#3ad12c] text-white font-semibold  p-2 rounded-e inline-block">
                        Recherche
                    </button>
                </div>
                <button onClick={() => navig("/fait/ajouter")} className='border px-3 py-1 rounded text-lg border-[#3ad12c86] text-[#3ad12c86] hover:text-[#3ad12c] hover:border-[#3ad12c]'>
                    Ajouter
                </button>
            </div>
            
            <div class="grid md:grid-cols-2 grid-cols-1 lg:grid-cols-3 gap-4 p-3">
                {[1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1].map((_, i) => (
                    <div onClick={()=>navig("/fait/detail")} class={`text-2xl text-center border-[#3ad12c] border-dashed cursor-pointer p-3 font-bold mb-4 border ${isDarVisitLoggined === "dark" ? 'bg-gray-800' : 'bg-white'} rounded-lg`}>
                        Faits Environnementaux et Climatiques Essentiels pour le Burundi
                    </div>
                ))}
            </div>
           

            <Footer />
        </div>
    )
}

export default AdminFaits