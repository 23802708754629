/* eslint-disable react/jsx-pascal-case */
import React, { useState } from 'react'
import { useThemes } from '../../../UserContext/UserContext'
import { useNavigate } from 'react-router-dom'
import { Drawer, Modal } from 'rsuite'
import { toast } from 'react-toastify'
import { evenements } from '../../../Data/Data'
import Evenement_A_venirForme from './Evenement_A_venirForme'


function Evenement_A_venir() {
    const { isDark } = useThemes()
    const navig = useNavigate()
    const [shoModalFomr, SetshoModalFomr] = useState(false)


    const [formData, setFormData] = useState({
        name: '',
        whatsapp: '',
        quarter: '',
        profession: '',
        info: '',
        tshirtSize: ''
    });

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.id]: e.target.value
        });
    };

    const validateForm = () => {
        const fields = ['name', 'whatsapp', 'quarter', 'profession', 'tshirtSize'];
        for (let field of fields) {
            if (!formData[field]) {
                const element = document.getElementById(field);
                element.focus();
                toast.error(<>Veuillez remplir le champ <span className="font-bold">{element.previousElementSibling.textContent}</span></>);
                return false;
            }
        }
        return true;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (validateForm()) {
            toast.success('Inscription réussie !');
            SetshoModalFomr(false)
        }
    };




    const [mois, setMois] = useState(new Date().getMonth());
    const [annee, setAnnee] = useState(new Date().getFullYear());
    const [dateSelectionnee, setDateSelectionnee] = useState(null);
    const [isDateSelectionnee, setIsDateSelectionnee] = useState(false);

    const dateActuelle = new Date();
    const moisNoms = ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'];

    const getDaysInMonth = (month, year) => new Date(year, month + 1, 0).getDate();
    const getFirstDayOfMonth = (month, year) => new Date(year, month, 1).getDay();

    const handlePrevMonth = () => {
        if (mois === 0) {
            setMois(11);
            setAnnee(annee - 1);
        } else {
            setMois(mois - 1);
        }
        setDateSelectionnee(null)
    };

    const handleNextMonth = () => {
        if (mois === 11) {
            setMois(0);
            setAnnee(annee + 1);
        } else {
            setMois(mois + 1);
        }
        setDateSelectionnee(null)
    };

    const daysInMonth = getDaysInMonth(mois, annee);
    const firstDay = getFirstDayOfMonth(mois, annee);

    // Créer un ensemble des dates avec des événements
    const datesAvecEvenements = new Set(evenements.map(e => e.date));

    const joursCalendrier = [];
    for (let i = 0; i < firstDay; i++) {
        joursCalendrier.push(<div key={`empty-${i}`} />);
    }

    for (let day = 1; day <= daysInMonth; day++) {
        const dateString = `${annee}-${String(mois + 1).padStart(2, '0')}-${String(day).padStart(2, '0')}`;
        const isCurrentDate = dateActuelle.getDate() === day && dateActuelle.getMonth() === mois && dateActuelle.getFullYear() === annee;
        const hasEvents = datesAvecEvenements.has(dateString);

        joursCalendrier.push(
            <div
                key={day}
                className={`${isDark === "dark" ? "bg-gray-800" : "bg-white"} 
                      ${hasEvents ? ' text-[#3ad12c] font-bold bg-[blue] ' : ''}
                      ${isCurrentDate ? 'font-bold bg-[orange] text-white' : ''}
                      rounded-lg shadow-md py-2 px-3 cursor-pointer`}
                onClick={() => { setDateSelectionnee(dateString); setIsDateSelectionnee(true) }}
            >
                {day}
            </div>
        );
    }

    // Filtrer les événements correspondant à la date sélectionnée
    const evenementsFiltrés = evenements.filter(e => e.date === dateSelectionnee);

    return (
        <div className=' mt-[18vh]  overflow-hidden'>
            <div onClick={() => navig("/communaute")} className='w-7 m-2 h-7 bg-[#3ad12c] rounded-full cursor-pointer flex justify-center items-center text-white'>
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-arrow-left-short" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5" />
                </svg>
            </div>
            <div class="w-full p-6 mx-auto">
                <p className='text-lg font-bold'>Découvrez et participez aux événements organisés par IMUHIRA et nos partenaires pour un Burundi plus vert et durable.</p>
                <div class="mb-6 flex justify-end mt-5 space-x-4">
                    <div>
                        <label for="category" class="block  font-medium mb-2">Catégorie</label>
                        <select id="category" class="block w-full border p-2 bg-transparent focus:border-[#3ad12c] border-gray-300 rounded-md outline-none sm:text-sm">
                            <option className='text-black' hidden value="">Toutes</option>
                            <option className='text-black' value="journee-reboisement">Journée de Reboisement</option>
                            <option className='text-black' value="atelier-fabrication">Atelier : Fabrication</option>
                            <option className='text-black' value="conference">Conférence</option>
                        </select>
                    </div>
                    <div>
                        <label for="province" class="block  font-medium mb-2">Province</label>
                        <select id="province" class="block w-full border p-2 bg-transparent focus:border-[#3ad12c] border-gray-300 rounded-md outline-none sm:text-sm">

                            <option className='text-black' hidden value="">Toutes</option>
                            <option className='text-black' value="muramvya">Muramvya</option>
                            <option className='text-black' value="gitega">Gitega</option>
                            <option className='text-black' value="bujumbura">Bujumbura</option>
                        </select>
                    </div>
                    <div>
                        <label for="date" class="block  font-medium mb-2">Date</label>
                        <input type="date" id="date" class="block w-full border p-2 bg-transparent focus:border-[#3ad12c] border-gray-300 rounded-md outline-none sm:text-sm" />
                    </div>
                </div>
                <div class="grid grid-cols-3 gap-6">
                    <div class=" rounded-lg shadow-md overflow-hidden">
                        <img src="../image/conference.jpg" alt="Journée de Reboisement à Kibira" class="w-full h-48 object-cover" />
                        <div class="p-4">
                            <h3 class="text-lg font-bold mb-2">Journée de Reboisement à Kibira</h3>
                            <p class=" mb-4">15 juillet 2023</p>
                            <p>Forêt de Kibira, Province de Muramvya<br />Participez à notre journée de reboisement et aidez-nous à restaurer la magnifique forêt de Kibira.</p>
                            <div onClick={() => navig("/communaute/event/detail")} class="mt-4 inline-block  hover:scale-105 cursor-pointer bg-[#3ad12c] text-white px-4 py-2 rounded-md">Plus d'infos</div>
                        </div>
                    </div>
                    <div class=" rounded-lg shadow-md overflow-hidden">
                        <img src="../image/conference.jpg" alt="Atelier : Fabrication de Produits Ménagers Écologiques" class="w-full h-48 object-cover" />
                        <div class="p-4">
                            <h3 class="text-lg font-bold mb-2">Atelier : Fabrication de Produits Ménagers Écologiques</h3>
                            <p class=" mb-4">22 juillet 2023</p>
                            <p>Centre Culturel de Gitega, Province de Gitega<br />Apprenez à fabriquer vos propres produits ménagers écologiques et économiques.</p>
                            <div class="mt-4 inline-block  hover:scale-105 cursor-pointer bg-[#3ad12c] text-white px-4 py-2 rounded-md">Plus d'infos</div>
                        </div>
                    </div>
                    <div class=" rounded-lg shadow-md overflow-hidden">
                        <img src="../image/conference.jpg" alt="Conférence : Le Burundi face au Changement Climatique" class="w-full h-48 object-cover" />
                        <div class="p-4">
                            <h3 class="text-lg font-bold mb-2">Conférence : Le Burundi face au Changement Climatique</h3>
                            <p class=" mb-4">5 août 2023</p>
                            <p>Université du Burundi, Bujumbura<br />Une journée de conférences et de débats sur les défis et les solutions face au changement climatique au Burundi.</p>
                            <div class="mt-4 inline-block  hover:scale-105 cursor-pointer bg-[#3ad12c] text-white px-4 py-2 rounded-md">Plus d'infos</div>
                        </div>
                    </div>
                </div>


                <div class={` mt-6 py-8 ${isDark === "dark" ? null : 'bg-gray-100'}`}>
                    <div class="max-w-6xl mx-auto">
                        <h2 class="text-2xl font-bold mb-6">Calendrier des Événements</h2>
                        <div className="flex justify-between mb-6">
                            <button onClick={handlePrevMonth} className={` px-4 ${isDark === "dark" ? 'bg-gray-800' : 'bg-gray-200'}  py-2 rounded-lg`}>Mois Précédent</button>
                            <div className="text-lg font-bold">{moisNoms[mois]} {annee}</div>
                            <button onClick={handleNextMonth} className={` px-4 py-2 ${isDark === "dark" ? 'bg-gray-800' : 'bg-gray-200'} rounded-lg`}>Mois Suivant</button>
                        </div>


                        <div class="grid grid-cols-7 gap-4 mb-6">
                            {['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'].map((jour, index) => (
                                <div key={index} className="text-gray-500 font-medium">{jour}</div>
                            ))}
                            {joursCalendrier}
                        </div>

                        <Modal open={isDateSelectionnee} onClose={() => setIsDateSelectionnee(false)} size="sm">
                            <Modal.Header>
                                <Modal.Title> <h3 className="text-lg font-bold mb-4">Événements pour le {dateSelectionnee}</h3></Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <div className='p-2'>
                                    {evenementsFiltrés.length > 0 ? (
                                        <ul>
                                            {evenementsFiltrés.map((event, index) => (
                                                <li key={index} className={`rounded-lg my-4 shadow-md ${isDark === "dark" ? "bg-gray-800" : "bg-white"} p-6`}>
                                                    <div className="font-bold">{event.heures} - {event.title}</div>
                                                    <div>{event.emplacement}</div>
                                                    <div>{event.description}</div>
                                                </li>
                                            ))}
                                        </ul>
                                    ) : (
                                        <p>Aucun événement prévu pour cette date.</p>
                                    )}
                                </div>
                            </Modal.Body>
                        </Modal>
                        
                        <div class={`rounded-lg shadow-md ${isDark === "dark" ? "bg-gray-800" : "bg-white"} p-6`}>
                            <h3 class="text-lg font-bold mb-4">Proposer un Événement</h3>
                            <p class="mb-6">Vous avez une idée d'événement pour promouvoir le développement durable au Burundi ? Partagez-la avec nous !</p>
                            <div onClick={() => SetshoModalFomr(true)} class="inline-block cursor-pointer bg-[#3ad12c] hover:scale-105 text-white px-4 py-2 rounded-md">Soumettre une Proposition</div>
                        </div>
                    </div>
                </div>
            </div>
            <Drawer open={shoModalFomr} size="full" placement='top' onClose={() => SetshoModalFomr(false)} >
                <Drawer.Header>
                    <Drawer.Title className='text-center uppercase'>Proposer un Événement</Drawer.Title>
                </Drawer.Header>
                <Drawer.Body>
                   <Evenement_A_venirForme/>
                </Drawer.Body>
            </Drawer>
        </div>
    )
}

export default Evenement_A_venir