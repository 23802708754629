import React, { useRef, useState } from 'react'
import { useThemes } from '../../../UserContext/UserContext'
import { toast } from 'react-toastify'

function DonationForm() {
    const { isDark } = useThemes()
    const [montant, GetMontant] = useState('')
    const elementRef = useRef(null)
    const hundleSubmite = (e) => {
        e.preventDefault()
        if (montant.trim() == "") {
            toast.warning("Le champ de montant est obligatpire !!")
            if (elementRef && elementRef.current) {
                elementRef.current.focus()
            }
            return false
        } else {
            toast.success("Merci pour votre générosité ")
        }
    }
    return (
        <div>
            <div className="inset-0 bg-opacity-50 h-full flex items-start justify-center z-50">
                <div className={` ${isDark === "dark" ? 'bg-gray-800' : 'bg-white'} p-6 rounded-lg shadow-lg max-w-md mx-auto`}>
                    <h2 className="text-2xl font-bold mb-4">Faire un don</h2>
                    <p className="mb-4  text-center">
                        Merci pour votre générosité ! Votre don aidera à financer des projets importants.
                    </p>
                    <p>
                        <p className="mb-4 text-center">
                            Votre contribution financière joue un rôle crucial dans l'amélioration de l'éducation.
                            Elle aide à financer des fournitures pédagogiques, l'entretien des jardins scolaires,
                            et bien plus encore. Chaque don compte et fait une différence significative.
                        </p>
                    </p>
                    <form onSubmit={hundleSubmite}>
                        <div className="mb-4">
                            <label htmlFor="amount" className="block  font-semibold mb-2">Montant</label>
                            <input
                               
                                type="number"
                                id="amount"
                                onInput={(e) => GetMontant(e.target.value)}
                                ref={elementRef}
                                className="block w-full px-4 py-2 border bg-transparent border-gray-300 rounded-lg focus:outline-none focus:border-[#3ad12c]"

                            />
                        </div>
                        <button type="submit" className="w-full bg-[#3ad12c] text-white py-2 px-4 rounded-lg hover:scale-105 transition-all focus:outline-none focus:ring-2 focus:ring-green-400 focus:ring-opacity-50">
                            Soumettre
                        </button>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default DonationForm