import React from 'react'
import Footer from '../../Visiteurs/FootentContent/Footer'
import { useThemes } from '../../../UserContext/UserContext'
import { useNavigate } from 'react-router-dom'

function AdiminRapport() {
  const { isDarVisitLoggined } = useThemes()
  const navig = useNavigate()
  return (
    <div className={`w-full overflow-y-auto ${isDarVisitLoggined === "dark" ? null : 'bg-gray-100'}  overflow-x-hidden h-[87vh]`}>
      <div class="rounded-lg  p-6 md:p-8">
        <div class=" ">
          <div class=" mx-auto">
            <div class="rounded-lg">
              <div class="p-6">

                <div class="flex items-center justify-between w-full mb-4">
                  <div>


                    <select class="bg-transparent border cursor-pointer border-gray-300 rounded-md py-2 px-3 focus:outline-none focus:ring-2 focus:ring-[#3ad12c] focus:border-transparent">
                      <option className='text-black'>Tous</option>
                      <option className='text-black'> 5</option>
                      <option className='text-black'>10</option>
                      <option className='text-black'>20</option>
                    </select>
                  </div>

                  <div className='flex w-[60%] items-center'>
                    <div className='w-full'>
                      <input className='p-2 w-full bg-transparent outline-none rounded-s border focus:border focus:border-[#3ad12c]' placeholder='Rechercher une ressource' />
                    </div>
                    <div class="bg-[#3ad12c86] cursor-pointer border border-[#3ad12c] hover:bg-[#3ad12c] text-white font-semibold  p-2 rounded-e inline-block">
                      Recherche
                    </div>
                    <button onClick={() => navig("/rapport/ajouter")} className='border ml-5 px-3 py-1 rounded text-lg border-[#3ad12c86] text-[#3ad12c86] hover:text-[#3ad12c] hover:border-[#3ad12c]'>
                      Ajouter
                    </button>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
        <div class="grid grid-cols-1 md:grid-cols-3 gap-6">
          {[1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1].map(() => (
            <div class={`rounded-xl overflow-hidden  border-[1px] border-t-4 border-b-[#3ad12c] border-t-[#3ad12c] ${isDarVisitLoggined === "dark" ? 'bg-gray-800' : 'bg-white'}`}>
              <h2 class="text-xl font-bold mb-4 px-4 text-[#3ad12c] text-nowrap text py-4 text-start bg-[#3ad12c0e]  text-ellipsis  overflow-hidden">1. Déforestation</h2>
              <p class="mb-1 p-2 text-[18px]">Taux de couverture forestière : 6,6% en 2023 (contre 11,3% en 2000)</p>
              <div class="space-y-2 px-4">
                <p class="text-lg font-bold mt-4 mx-4 mb-2">Actions réalisées :</p>
                <ul class="list-disc pl-6 space-y-2  mx-5">
                  <li>Plantation de 50 millions d'arbres (2018-2023)</li>
                  <li>Création de 3 nouvelles réserves forestières</li>
                </ul>
                <div>
                  <p class="text-lg font-bold mt-4 mx-4 mb-2">Actions à entreprendre :</p>
                  <ul class="list-disc pl-6 space-y-2  mx-5">
                    <li>Atteindre 15% de couverture forestière d'ici 2030</li>
                    <li>Mettre en place un système de surveillance par satellite</li>
                  </ul>
                </div>
              </div>
              <div className='flex w-full justify-between items-center p-2'>
                <div title='Modifier' onClick={() => navig("/rapport/modifier")} className=' border p-2 border-blue-600 rounded text-blue-600 hover:scale-105 transition-all cursor-pointer'>
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-pencil-square" viewBox="0 0 16 16">
                    <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                    <path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5z" />
                  </svg>
                </div>
                <div title='Détail' onClick={() => { navig("/rapport/detail")}} className=' border p-2 border-[#3ad12c] rounded text-[#3ad12c] hover:scale-105 transition-all cursor-pointer'>
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-eye-fill" viewBox="0 0 16 16">
                    <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0" />
                    <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8m8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7" />
                  </svg>
                </div>
                <div  title='Supprimer' className=' border p-2 border-[red] rounded text-[red] hover:scale-105 transition-all cursor-pointer'>

                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-trash3-fill" viewBox="0 0 16 16">
                    <path d="M11 1.5v1h3.5a.5.5 0 0 1 0 1h-.538l-.853 10.66A2 2 0 0 1 11.115 16h-6.23a2 2 0 0 1-1.994-1.84L2.038 3.5H1.5a.5.5 0 0 1 0-1H5v-1A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5m-5 0v1h4v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5M4.5 5.029l.5 8.5a.5.5 0 1 0 .998-.06l-.5-8.5a.5.5 0 1 0-.998.06m6.53-.528a.5.5 0 0 0-.528.47l-.5 8.5a.5.5 0 0 0 .998.058l.5-8.5a.5.5 0 0 0-.47-.528M8 4.5a.5.5 0 0 0-.5.5v8.5a.5.5 0 0 0 1 0V5a.5.5 0 0 0-.5-.5" />
                  </svg>
                </div>

                <div title='Télécharger' className=' border p-2 border-fuchsia-700 rounded text-[fuchsia] hover:scale-105 transition-all cursor-pointer'>
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-download" viewBox="0 0 16 16">
                    <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5" />
                    <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708z" />
                  </svg>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default AdiminRapport