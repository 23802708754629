import React from 'react'
import NavBarsRessource from './NavBarsRessource'
import { useThemes } from '../../../UserContext/UserContext'

function Lois() {
    const { isDark } = useThemes()
    return (
        <div data-aos-duration="1000" class={` mt-[18vh] ${isDark === "dark" ? null : 'bg-gray-100'} overflow-hidden`}>
            <NavBarsRessource />
            <section class="resource-laws mb-12 p-8 ">
                <h2 data-aos="fade-up" class="text-2xl font-bold 0 mb-6">
                    Les principales lois régissant les ressources
                </h2>
                <ul data-aos="fade-up" class="list-disc   list-inside space-y-2 ">
                    <li class="text-lg ml-3">Loi sur la gestion durable des forêts</li>
                    <li class="text-lg ml-3">Loi sur la protection de l'environnement</li>
                    <li class="text-lg ml-3">Loi sur l'exploitation minière</li>
                    <li class="text-lg ml-3">Loi sur la pêche et l'aquaculture</li>
                    <li class="text-lg ml-3">Loi sur l'utilisation rationnelle de l'eau</li>
                </ul>
            </section>

            <section data-aos="fade-up" class="resource-laws mb-12 p-8 ">
                <h2 class="text-2xl font-bold  mb-8">
                    Détails des principales lois
                </h2>

                <div data-aos="fade-up" class={`flex  p-6 mb-8 ${isDark === "dark" ? "bg-gray-800" : 'bg-white'} shadow-lg rounded-lg`}>
                    <div>
                        <h3 class="text-xl font-semibold  mb-4">
                            Loi sur la gestion durable des forêts
                        </h3>
                        <p class=" mb-4">
                            Cette loi régit la gestion et l'exploitation des ressources forestières
                            de manière durable, en assurant la préservation de la biodiversité et
                            des écosystèmes forestiers. Ses principaux points sont :
                        </p>
                        <ul class="list-disc list-inside space-y-2 ">
                            <li class="text-lg ml-3">Planification de la gestion forestière à long terme</li>
                            <li class="text-lg ml-3">Régénération et reboisement obligatoires</li>
                            <li class="text-lg ml-3">Préservation des zones sensibles et des habitats naturels</li>
                            <li class="text-lg ml-3">Limitation des coupes rases et promotion de techniques d'exploitation écologiques</li>
                            <li class="text-lg ml-3">Sanctions en cas de non-respect des normes</li>
                        </ul>
                    </div>
                    <div>
                        <img src='  https://www.scidev.net/afrique-sub-saharienne/wp-content/uploads/sites/2/2019/08/measuring_the_circumference_of_a_tree_by_flickr_cifor.jpg' alt='' />
                    </div>
                </div>
                <div data-aos="fade-up" class={`law-card flex  gap-3 p-6 mb-8 ${isDark === "dark" ? "bg-gray-800" : 'bg-white'} shadow-lg rounded-lg`}>
                    <div>
                        <img src='https://tiredearth.com/images/720/Upload/Daniel%20Roux2018Author/environment%20protection.jpg' alt='' />
                    </div>
                    <div>
                        <h3 class="text-xl font-semibold  mb-4">
                            Loi sur la protection de l'environnement
                        </h3>
                        <p class=" mb-4">
                            Cette loi a pour objectif de protéger l'environnement contre les
                            pollutions et les nuisances, en encadrant les activités industrielles,
                            agricoles et autres susceptibles d'avoir un impact négatif. Ses
                            principaux axes sont :
                        </p>
                        <ul class="list-disc list-inside space-y-2 ">
                            <li class="text-lg ml-3">Évaluation environnementale obligatoire des projets</li>
                            <li class="text-lg ml-3">Normes de rejets et d'émissions polluantes</li>
                            <li class="text-lg ml-3">Responsabilité élargie des producteurs</li>
                            <li class="text-lg ml-3">Promotion des énergies renouvelables et de l'économie circulaire</li>
                            <li class="text-lg ml-3">Sanctions en cas de non-respect des réglementations</li>
                        </ul>
                    </div>

                </div>

                <div data-aos="fade-up" class={`law-card flex  gap-3 p-6 mb-8 ${isDark === "dark" ? "bg-gray-800" : 'bg-white'} shadow-lg rounded-lg`}>
                    <div>
                        <h3 class="text-xl font-semibold  mb-4">
                            Loi sur l'exploitation minière
                        </h3>
                        <p class=" mb-4">
                            Cette loi encadre l'exploration, l'exploitation et la gestion des
                            ressources minérales. Ses principales dispositions sont :
                        </p>
                        <ul class="list-disc list-inside space-y-2 ">
                            <li class="text-lg ml-3">Octroi de permis d'exploration et d'exploitation</li>
                            <li class="text-lg ml-3">Obligations de réhabilitation et de remise en état des sites</li>
                            <li class="text-lg ml-3">Redevances et taxes sur l'exploitation minière</li>
                            <li class="text-lg ml-3">Mesures de protection des écosystèmes et des communautés locales</li>
                            <li class="text-lg ml-3">Sanctions en cas de non-respect de la réglementation</li>
                        </ul>
                    </div>
                    <div className='h-80'>
                        <img className='h-full w-auto' src='https://or-argent.eu/wp-content/uploads/2022/02/mine-or.jpg' alt='' />
                    </div>
                </div>

                <div data-aos="fade-up" class={`law-card flex gap-3  p-6 mb-8 ${isDark === "dark" ? "bg-gray-800" : 'bg-white'} shadow-lg rounded-lg`}>
                    <div>
                        <img src='https://i.ytimg.com/vi/OlWwCNKp_fM/maxresdefault.jpg' alt='' />
                    </div>
                    <div>
                        <h3 class="text-xl font-semibold  mb-4">
                            Loi sur la pêche et l'aquaculture
                        </h3>
                        <p class=" mb-4">
                            Cette loi régit l'exploitation durable des ressources halieutiques et le
                            développement de l'aquaculture. Ses principaux éléments sont :
                        </p>
                        <ul class="list-disc list-inside space-y-2 ">
                            <li class="text-lg ml-3">Système de permis et de quotas de pêche</li>
                            <li class="text-lg ml-3">Réglementation des techniques de pêche et de l'aquaculture</li>
                            <li class="text-lg ml-3">Protection des espèces menacées et des écosystèmes aquatiques</li>
                            <li class="text-lg ml-3">Soutien à la recherche et à l'innovation dans le secteur</li>
                            <li class="text-lg ml-3">Sanctions en cas de pêche illégale ou de non-respect des normes</li>
                        </ul>
                    </div>
                </div>

                <div data-aos="fade-up" class={`law-card flex gap-3  p-6 mb-8 ${isDark === "dark" ? "bg-gray-800" : 'bg-white'} shadow-lg rounded-lg`}>
                    <div>
                        <h3 class="text-xl font-semibold  mb-4">
                            Loi sur l'utilisation rationnelle de l'eau
                        </h3>
                        <p class=" mb-4">
                            Cette loi vise à encadrer l'utilisation de la ressource en eau, à la
                            préserver et à en assurer une gestion durable. Ses principales
                            dispositions concernent :
                        </p>
                        <ul class="list-disc list-inside space-y-2 ">
                            <li class="text-lg ml-3">Système de redevances et de quotas pour l'usage de l'eau</li>
                            <li class="text-lg ml-3">Obligations de traitement et de recyclage des eaux usées</li>
                            <li class="text-lg ml-3">Protection des ressources en eau (nappes, cours d'eau, zones humides)</li>
                            <li class="text-lg ml-3">Promotion de techniques d'irrigation et de production économes en eau</li>
                            <li class="text-lg ml-3">Sanctions en cas de gaspillage ou de pollution des eaux</li>
                        </ul>
                    </div>
                    <div>
                        <img src='https://boiteaoutils.espace-mont-blanc.com/asset/img/pixb-irrigazione_ca.jpg' alt='' />
                    </div>
                </div>

            </section>
        </div>
    )
}

export default Lois