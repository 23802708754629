import React from 'react'
import NavBarsRessource from './NavBarsRessource'
function Climentique() {
    return (
        <div data-aos-duration="1000" class="rounded-lg mt-[18vh] overflow-hidden">
      <NavBarsRessource />
            <h2 data-aos="fade-up" class="text-2xl mt-5 p-3 font-bold mb-4">10 Faits Environnementaux et Climatiques Essentiels pour le Burundi</h2>
            <div class="grid grid-cols-4 gap-4 p-3">
                <div data-aos="fade-up"  className='p-5 rounded-e-xl  border-l-2 border-[#3ad12c]  bg-[#3ad12c2a]'>
                    <div class="text-lg font-bold mb-2">1. Déforestation</div>
                    <p class="">Le taux de déforestation atteint 9% par an, principalement pour l'agriculture et la production de charbon de bois.</p>
                </div>
                 <div data-aos="fade-up" className='p-5 rounded-e-xl  border-l-2 border-[#3ad12c]  bg-[#3ad12c2a]'>
                    <div class="text-lg font-bold mb-2">2. Baisse des pluies</div>
                    <p class="">Les précipitations ont diminué de 7% en 20 ans, avec des périodes de sécheresse plus fréquentes.</p>
                </div>
                 <div data-aos="fade-up" className='p-5 rounded-e-xl  border-l-2 border-[#3ad12c]  bg-[#3ad12c2a]'>
                    <div class="text-lg font-bold mb-2">3. Érosion des sols</div>
                    <p class="">L'érosion des sols affecte 80% des terres arables, entraînant une baisse de la productivité agricole.</p>
                </div>
                 <div data-aos="fade-up" className='p-5 rounded-e-xl  border-l-2 border-[#3ad12c]  bg-[#3ad12c2a]'>
                    <div class="text-lg font-bold mb-2">4. Extinction des espèces</div>
                    <p class="">Le Burundi a perdu 40% de sa biodiversité entre 1990 et 2020.</p>
                </div>
                 <div data-aos="fade-up" className='p-5 rounded-e-xl  border-l-2 border-[#3ad12c]  bg-[#3ad12c2a]'>
                    <div class="text-lg font-bold mb-2">5. Pollutions des eaux</div>
                    <p class="">La pollution des lacs et des rivières par les déchets et les pesticides menace les ressources en eau.</p>
                </div>
                 <div data-aos="fade-up" className='p-5 rounded-e-xl  border-l-2 border-[#3ad12c]  bg-[#3ad12c2a]'>
                    <div class="text-lg font-bold mb-2">6. Dégradation des terres</div>
                    <p class="">La dégradation des terres affecte 95% du territoire, rendant les sols moins fertiles.</p>
                </div>
                 <div data-aos="fade-up" className='p-5 rounded-e-xl  border-l-2 border-[#3ad12c]  bg-[#3ad12c2a]'>
                    <div class="text-lg font-bold mb-2">7. Recul des glaciers</div>
                    <p class="">Les glaciers du Rwenzori ont reculé de 50% en 50 ans, affectant les ressources en eau.</p>
                </div>
                 <div data-aos="fade-up" className='p-5 rounded-e-xl  border-l-2 border-[#3ad12c]  bg-[#3ad12c2a]'>
                    <div class="text-lg font-bold mb-2">8. Urbanisation rapide</div>
                    <p class="">L'urbanisation s'accélère, avec des impacts négatifs sur l'environnement.</p>
                </div>
                 <div data-aos="fade-up" className='p-5 rounded-e-xl  border-l-2 border-[#3ad12c]  bg-[#3ad12c2a]'>
                    <div class="text-lg font-bold mb-2">9. Pression sur les ressources</div>
                    <p class="">La forte croissance démographique (3% par an) exerce une pression importante sur les ressources naturelles.</p>
                </div>
                 <div data-aos="fade-up" className='p-5 rounded-e-xl  border-l-2 border-[#3ad12c]  bg-[#3ad12c2a]'>
                    <div class="text-lg font-bold mb-2">10. Vulnérabilité climatique</div>
                    <p class="">Le Burundi est très vulnérable aux impacts du changement climatique, notamment les sécheresses et les inondations.</p>
                </div>
            </div>
            <p class=" mt-4">Source: Atlas de la Biodiversité du Burundi</p>
        </div>
    )
}

export default Climentique