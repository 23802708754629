/* eslint-disable react/jsx-pascal-case */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { Routes, Route,} from "react-router-dom";
import './App.css'
import 'animate.css/animate.min.css';
import 'react-toastify/dist/ReactToastify.css';
import "apexcharts/dist/apexcharts.css";
import 'swiper/css';
import 'swiper/css/effect-fade';
import 'rsuite/dist/rsuite.min.css';
import 'aos/dist/aos.css';
import 'react-multi-carousel/lib/styles.css';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import "leaflet-geosearch/dist/geosearch.css";
import "leaflet/dist/leaflet.css";


import { ToastContainer } from 'react-toastify';
import Aos from "aos";
import { useThemes } from "./UserContext/UserContext";
import { CustomProvider } from "rsuite";
import NavBars from "./Compenent/Visiteurs/NavBars/NavBars";
import Cours from "./Compenent/Visiteurs/Cours/Cours";
import Projet from "./Compenent/Visiteurs/Projet/Projet";
import Communaute from "./Compenent/Visiteurs/Communaute/Communaute";
import Apropos from "./Compenent/Visiteurs/Apropos/Apropos";
import AcceuillComponent from "./Compenent/Visiteurs/Acceuil/AcceuillComponent";
import { useEffect } from "react";
import FootentContent from "./Compenent/Visiteurs/FootentContent/FootentContent";
import CoursDetail from "./Compenent/Visiteurs/Cours/CoursDetail";
import ModuleCours from "./Compenent/Visiteurs/Cours/ModuleCours";
import Soutenir from "./Compenent/Visiteurs/Projet/Soutenir";
import BenevoleProjets from "./Compenent/Visiteurs/Projet/BenevoleProjets";
import Forumu from "./Compenent/Visiteurs/Communaute/Forumu";
import Evenement_A_venir from "./Compenent/Visiteurs/Communaute/Evenement_A_venir";
import ParticiperAppropos from "./Compenent/Visiteurs/Apropos/ParticiperAppropos";
import EvenementDetail from "./Compenent/Visiteurs/Acceuil/EvenementDetail";
import Ressource from "./Compenent/Visiteurs/Ressource/Ressource";
import Animal from "./Compenent/Visiteurs/Ressource/Animal";
import Rapport from "./Compenent/Visiteurs/Ressource/Rapport";
import Climentique from "./Compenent/Visiteurs/Ressource/Climentique";
import Lois from "./Compenent/Visiteurs/Ressource/Lois";
import Convention from "./Compenent/Visiteurs/Ressource/Convention";
import ConventionRegion from "./Compenent/Visiteurs/Ressource/ConventionRegion";
import ODD from "./Compenent/Visiteurs/Ressource/ODD";
import Signale from "./Compenent/Visiteurs/Signal/Signale";
import DetailEvent_a_Venir from "./Compenent/Visiteurs/Communaute/DetailEvent_a_Venir";
import SignalerDetail from "./Compenent/Visiteurs/Signal/SignalerDetail";
import AnimalDetail from "./Compenent/Visiteurs/Ressource/AnimalDetail";
import SujetDetail from "./Compenent/Visiteurs/Communaute/SujetDetail";

// ....................admin.......................
import AdminNavrBars from "./Compenent/Admin/AdminNavrBars/AdminNavrBars";
import AdminAcceuillComponent from "./Compenent/Admin/AdminAcceuil/AdminAcceuilComponent";
import AdminCoursComponent from "./Compenent/Admin/AdminCours/AdminCoursComponent";
import AjouterCoursAdminComponent from "./Compenent/Admin/AdminCours/AjouterCoursAdminComponent";
import AdminCoursDetailComponent from "./Compenent/Admin/AdminCours/AdminCoursDetailComponent";
import AdminCoursModuleComponent from "./Compenent/Admin/AdminCours/AdminCoursModuleComponent";
import AdminCoursModifieeComponent from "./Compenent/Admin/AdminCours/AdminCoursModifieeComponent";
import AdminSignalComponent from "./Compenent/Admin/AdminSignal/AdminSignalComponent";
import AdminCoursProposeComponent from "./Compenent/Admin/AdminCours/AdminCoursProposeComponent";
import AdminCoursProposeDeatailComponent from "./Compenent/Admin/AdminCours/AdminCoursProposeDeatailComponent";
import AdminCoursProposeModulComponent from "./Compenent/Admin/AdminCours/AdminCoursProposeModulComponent";
import AdminSignalDetailComponent from "./Compenent/Admin/AdminSignal/AdminSignalDetailComponent";
import AdminSignalProposeComponent from "./Compenent/Admin/AdminSignal/AdminSignalProposeComponent";
import AdminSignalProposeDetailComponent from "./Compenent/Admin/AdminSignal/AdminSignalProposeDetailComponent";
import AdminRessourceComponent from "./Compenent/Admin/AdminRessource/AdminRessourceComponent";
import AdminRessourceAjouterComponent from "./Compenent/Admin/AdminRessource/AdminRessourceAjouterComponent";
import AdminRessourceDetailComponent from "./Compenent/Admin/AdminRessource/AdminRessourceDetailComponent";
import AdminRessourceModifierComponent from "./Compenent/Admin/AdminRessource/AdminRessourceModifierComponent";
import AdiminRapportComponent from "./Compenent/Admin/AdiminRapport/AdiminRapportComponent";
import AdiminRapportAjouterComponent from "./Compenent/Admin/AdiminRapport/AdiminRapportAjouterComponent";
import AdiminRapportDetailComponent from "./Compenent/Admin/AdiminRapport/AdiminRapportDetailComponent";
import AdiminRapportModifierComponent from "./Compenent/Admin/AdiminRapport/AdiminRapportModifierComponent";
import AdminFaitsComponent from "./Compenent/Admin/AdminFaits/AdminFaitsComponent";
import AdminFaitsDetailComponent from "./Compenent/Admin/AdminFaits/AdminFaitsDetailComponent";
import AdminFaitsAjouterCompoment from "./Compenent/Admin/AdminFaits/AdminFaitsAjouterCompoment";


function App() {
    const { HundlwScrollTop, isScroll, getWidth, isDark, isAdmin, SetOpenModal, isVisitor, isDarVisitLoggined, isGoingToSite } = useThemes()

    useEffect(() => {
        const initAos = () => {
            if (typeof Aos !== 'undefined') {
                Aos.init({
                    once: true,
                    duration: 1000,
                    easing: 'ease-out-cubic',
                });
            }
        };

        const timer = setTimeout(initAos, 500);
        const hundleLoadWindows = () => {
            //    on met la page spinner
        }
        const hundleClick = () => {
            SetOpenModal(false)
        }
        window.addEventListener('load', () => { hundleLoadWindows() });
        window.addEventListener('click', () => { hundleClick() });

        return () => {
            clearTimeout(timer);
            window.removeEventListener("load", () => { hundleLoadWindows() })
            window.removeEventListener("click", () => { hundleClick() })
        };
    }, []);


    return isVisitor ? (
        <CustomProvider theme={isDark}>
            <div
                className='h-[3px] fixed  bg-[#3ad12c] top-0   left-0 z-[2000]'
                style={{
                    width: `${getWidth * 100}%`,
                }}
            >
            </div>
            <NavBars />
            <div className="overflow-x-hidden">
                <Routes>
                    <Route path="/" Component={AcceuillComponent} />
                    <Route path="/signal" Component={Signale} />
                    <Route path="/signal/detail" Component={SignalerDetail} />

                    <Route path="/detail" Component={EvenementDetail} />

                    <Route path="/cours" Component={Cours} />
                    <Route path="/cours/detail" Component={CoursDetail} />
                    <Route path="/cours/module" Component={ModuleCours} />

                    <Route path="/ressource" Component={Ressource} />
                    <Route path="/ressource/espece" Component={Animal} />
                    <Route path="/ressource/espece/detail" Component={AnimalDetail} />
                    <Route path="/ressource/rapport" Component={Rapport} />
                    <Route path="/ressource/Environnementaux" Component={Climentique} />
                    <Route path="/ressource/lois" Component={Lois} />
                    <Route path="/ressource/Convention" Component={Convention} />
                    <Route path="/ressource/region" Component={ConventionRegion} />
                    <Route path="/ressource/ODD" Component={ODD} />

                    <Route path="/projet" Component={Projet} />
                    <Route path="/projet/Soutenir" Component={Soutenir} />
                    <Route path="/projet/benevol" Component={BenevoleProjets} />

                    <Route path="/communaute" Component={Communaute} />
                    <Route path="/communaute/forumu" Component={Forumu} />
                    <Route path="/communaute/forumu/detail" Component={SujetDetail} />
                    <Route path="/communaute/event" Component={Evenement_A_venir} />
                    <Route path="/communaute/event/detail" Component={DetailEvent_a_Venir} />
                    <Route path="/propos" Component={Apropos} />
                    <Route path="/propos/participer" Component={ParticiperAppropos} />

                </Routes>
                <div onClick={HundlwScrollTop}
                    className={`${isScroll ? 'opacity-100 pointer-events-auto' : 'opacity-0 pointer-events-none'} transition-all fixed w-[30px] h-[30px] flex justify-center items-center rounded-full cursor-pointer right-6 top-[90%] bg-[#617a5c] hover:bg-[#3ad12c] duration-75 text-white z-[30]`}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-capslock-fill" viewBox="0 0 16 16">
                        <path d="M7.27 1.047a1 1 0 0 1 1.46 0l6.345 6.77c.6.638.146 1.683-.73 1.683H11.5v1a1 1 0 0 1-1 1h-5a1 1 0 0 1-1-1v-1H1.654C.78 9.5.326 8.455.924 7.816zM4.5 13.5a1 1 0 0 1 1-1h5a1 1 0 0 1 1 1v1a1 1 0 0 1-1 1h-5a1 1 0 0 1-1-1z" />
                    </svg>
                </div>
            </div>
            <FootentContent />
            <ToastContainer />
        </CustomProvider>


    ) : isAdmin ? (
        <CustomProvider theme={isAdmin ? isDarVisitLoggined : isDark}>
            {isGoingToSite ?
                (
                    <>
                        <NavBars />
                        <div className="overflow-x-hidden">
                            <Routes>
                                <Route path="/" Component={AcceuillComponent} />
                                <Route path="/signal" Component={Signale} />
                                <Route path="/signal/detail" Component={SignalerDetail} />

                                <Route path="/detail" Component={EvenementDetail} />

                                <Route path="/cours" Component={Cours} />
                                <Route path="/cours/detail" Component={CoursDetail} />
                                <Route path="/cours/module" Component={ModuleCours} />

                                <Route path="/ressource" Component={Ressource} />
                                <Route path="/ressource/espece" Component={Animal} />
                                <Route path="/ressource/espece/detail" Component={AnimalDetail} />
                                <Route path="/ressource/rapport" Component={Rapport} />
                                <Route path="/ressource/Environnementaux" Component={Climentique} />
                                <Route path="/ressource/lois" Component={Lois} />
                                <Route path="/ressource/Convention" Component={Convention} />
                                <Route path="/ressource/region" Component={ConventionRegion} />
                                <Route path="/ressource/ODD" Component={ODD} />

                                <Route path="/projet" Component={Projet} />
                                <Route path="/projet/Soutenir" Component={Soutenir} />
                                <Route path="/projet/benevol" Component={BenevoleProjets} />

                                <Route path="/communaute" Component={Communaute} />
                                <Route path="/communaute/forumu" Component={Forumu} />
                                <Route path="/communaute/forumu/detail" Component={SujetDetail} />
                                <Route path="/communaute/event" Component={Evenement_A_venir} />
                                <Route path="/communaute/event/detail" Component={DetailEvent_a_Venir} />
                                <Route path="/propos" Component={Apropos} />
                                <Route path="/propos/participer" Component={ParticiperAppropos} />

                            </Routes>
                            <div onClick={HundlwScrollTop}
                                className={`${isScroll ? 'opacity-100 pointer-events-auto' : 'opacity-0 pointer-events-none'} transition-all fixed w-[30px] h-[30px] flex justify-center items-center rounded-full cursor-pointer right-6 top-[90%] bg-[#617a5c] hover:bg-[#3ad12c] duration-75 text-white z-[30]`}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-capslock-fill" viewBox="0 0 16 16">
                                    <path d="M7.27 1.047a1 1 0 0 1 1.46 0l6.345 6.77c.6.638.146 1.683-.73 1.683H11.5v1a1 1 0 0 1-1 1h-5a1 1 0 0 1-1-1v-1H1.654C.78 9.5.326 8.455.924 7.816zM4.5 13.5a1 1 0 0 1 1-1h5a1 1 0 0 1 1 1v1a1 1 0 0 1-1 1h-5a1 1 0 0 1-1-1z" />
                                </svg>
                            </div>
                        </div>
                    </>
                ) :
                (
                    <div className="w-full h-screen overflow-hidden">
                        <AdminNavrBars />
                        <Routes>
                            <Route path="/" Component={AdminAcceuillComponent} />

                            <Route path="/cours" Component={AdminCoursComponent} />
                            <Route path="/cours/ajout" Component={AjouterCoursAdminComponent} />
                            <Route path="/cours/detail" Component={AdminCoursDetailComponent} />
                            <Route path="/cours/module" Component={AdminCoursModuleComponent} />
                            <Route path="/cours/modif" Component={AdminCoursModifieeComponent} />
                            <Route path="/cours/propose" Component={AdminCoursProposeComponent} />
                            <Route path="/cours/propose/detail" Component={AdminCoursProposeDeatailComponent} />
                            <Route path="/cours/propose/module" Component={AdminCoursProposeModulComponent} />

                            <Route path="/signal" Component={AdminSignalComponent} />
                            <Route path="/signal/detail" Component={AdminSignalDetailComponent} />
                            <Route path="/signal/propose" Component={AdminSignalProposeComponent} />
                            <Route path="/signal/propose/detail" Component={AdminSignalProposeDetailComponent} />

                            <Route path="/ressource" Component={AdminRessourceComponent} />
                            <Route path="/ressource/ajouter" Component={AdminRessourceAjouterComponent} />
                            <Route path="/ressource/detail" Component={AdminRessourceDetailComponent} />
                            <Route path="/ressource/modifier" Component={AdminRessourceModifierComponent} />

                            <Route path="/rapport" Component={AdiminRapportComponent} />
                            <Route path="/rapport/ajouter" Component={AdiminRapportAjouterComponent} />
                            <Route path="/rapport/detail" Component={AdiminRapportDetailComponent} />
                            <Route path="/rapport/modifier" Component={AdiminRapportModifierComponent} />

                            <Route path="/fait" Component={AdminFaitsComponent} />
                            <Route path="/fait/detail" Component={AdminFaitsDetailComponent} />
                            <Route path="/fait/ajouter" Component={AdminFaitsAjouterCompoment} />
                        </Routes>
                    </div>
                )}

            <ToastContainer />
        </CustomProvider>
    ) : <>C'est null</>
}

export default App