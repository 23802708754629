import React from 'react'
import NavBarsRessource from './NavBarsRessource'

function Rapport() {
    return (
        <div data-aos-duration="1000" class="rounded-lg mt-[18vh] overflow-hidden">
      <NavBarsRessource />
            <div class="rounded-lg  p-6 md:p-8">
                   <p data-aos="fade-up" className='text-lg my-3'>Ce rapport présente une analyse approfondie des 15 défis environnementaux les plus pressants auxquels le Burundi est confronté. Pour chaque défi, nous détaillons la situation actuelle, les actions déjà entreprises et les mesures à mettre en place, le tout appuyé par des données chiffrées récentes.</p>
                <div class="grid grid-cols-1 md:grid-cols-3 gap-6">
                    <div data-aos="fade-up" class=" rounded-xl overflow-hidden  border-[1px] border-t-4 border-b-[#3ad12c] border-t-[#3ad12c]">
                        <h2 class="text-xl font-bold mb-4 px-4 text-[#3ad12c] text-nowrap text py-4 text-start bg-[#3ad12c0e]  text-ellipsis  overflow-hidden">1. Déforestation</h2>
                        <p class="mb-1 p-2 text-[18px]">Taux de couverture forestière : 6,6% en 2023 (contre 11,3% en 2000)</p>
                        <div class="space-y-2 px-4">
                            <p class="text-lg font-bold mt-4 mx-4 mb-2">Actions réalisées :</p>
                            <ul class="list-disc pl-6 space-y-2  mx-5">
                                <li>Plantation de 50 millions d'arbres (2018-2023)</li>
                                <li>Création de 3 nouvelles réserves forestières</li>
                            </ul>
                            <div>
                                <p class="text-lg font-bold mt-4 mx-4 mb-2">Actions à entreprendre :</p>
                                <ul class="list-disc pl-6 space-y-2  mx-5">
                                    <li>Atteindre 15% de couverture forestière d'ici 2030</li>
                                    <li>Mettre en place un système de surveillance par satellite</li>
                                </ul>
                            </div>
                        </div>
                        <div className="m-4 p-1.5 rounded-md hover:scale-105 transition-all cursor-pointer w-max text-[#3ad12c] border border-[#3ad12c]">
                            Téléchargement
                        </div>
                    </div>
                    <div data-aos="fade-up" class=" rounded-xl overflow-hidden  border-[1px] border-t-4 border-b-[#3ad12c] border-t-[#3ad12c]">
                        <h2 class="text-xl font-bold mb-4 px-4 text-[#3ad12c] text-nowrap text py-4 text-start bg-[#3ad12c0e]  text-ellipsis  overflow-hidden">2. Érosion des sols</h2>
                        <p class="mb-1 p-2 text-[18px]">90% des terres agricoles affectées par l'érosion</p>
                        <div class="space-y-2 px-4">
                            <p class="text-lg font-bold mt-4 mx-4 mb-2">Actions réalisées :</p>
                            <ul class="list-disc pl-6 space-y-2  mx-5">
                                <li>Installation de 20,000 km de courbes de niveau (2020-2023)</li>
                                <li>Formation de 100,000 agriculteurs aux techniques anti-érosives</li>
                            </ul>
                            <div>
                                <p class="text-lg font-bold mt-4 mx-4 mb-2">Actions à entreprendre :</p>
                                <ul class="list-disc pl-6 space-y-2  mx-5">
                                    <li>Restaurer 200,000 hectares de terres dégradées d'ici 2028</li>
                                    <li>Introduire des cultures de couverture sur 50% des terres agricoles</li>
                                </ul>
                            </div>
                        </div>
                        <div className="m-4 p-1.5 rounded-md hover:scale-105 transition-all cursor-pointer w-max text-[#3ad12c] border border-[#3ad12c]">
                            Téléchargement
                        </div>
                    </div>
                    <div data-aos="fade-up" class=" rounded-xl overflow-hidden  border-[1px] border-t-4 border-b-[#3ad12c] border-t-[#3ad12c]">
                        <h2 class="text-xl font-bold mb-4 px-4 text-[#3ad12c] text-nowrap text py-4 text-start bg-[#3ad12c0e]  text-ellipsis  overflow-hidden">3. Pollution du lac Tanganyika</h2>
                        <p class="mb-1 p-2 text-[18px]">Augmentation de 40% des niveaux de pollution depuis 2010</p>
                        <div class="space-y-2 px-4">
                            <p class="text-lg font-bold mt-4 mx-4 mb-2">Actions réalisées :</p>
                            <ul class="list-disc pl-6 space-y-2  mx-5">
                                <li>Installation de 5 stations d'épuration le long du littoral</li>
                                <li>Mise en place d'un système de surveillance en temps réel</li>
                            </ul>
                            <div>
                                <p class="text-lg font-bold mt-4 mx-4 mb-2">Actions à entreprendre :</p>
                                <ul class="list-disc pl-6 space-y-2  mx-5">
                                    <li>Réduire de 50% les rejets industriels d'ici 2026</li>
                                    <li>Restaurer 10,000 hectares de zones tampons côtières</li>
                                </ul>
                            </div>
                        </div>
                        <div className="m-4 p-1.5 rounded-md hover:scale-105 transition-all cursor-pointer w-max text-[#3ad12c] border border-[#3ad12c]">
                            Téléchargement
                        </div>
                    </div>
                    <div data-aos="fade-up" class=" rounded-xl overflow-hidden  border-[1px] border-t-4 border-b-[#3ad12c] border-t-[#3ad12c]">
                        <h2 class="text-xl font-bold mb-4 px-4 text-[#3ad12c] text-nowrap text py-4 text-start bg-[#3ad12c0e]  text-ellipsis  overflow-hidden">4. Gestion des déchets urbains</h2>
                        <p class="mb-1 p-4 text-[18px]">Seulement 40% des déchets urbains collectés et traités</p>
                        <h4 class="text-lg font-bold mb-2 mx-4">Actions réalisées :</h4>
                        <ul class="list-disc pl-6 space-y-2 mx-5">
                            <li>Mise en place de 1000 points de collecte à Buinumura</li>
                            <li>Lancement d'un programme de compostage touchant 50,000 ménages</li>
                        </ul>
                        <h4 class="text-lg font-bold mt-4 mx-4 mb-2">Actions à entreprendre :</h4>
                        <ul class="list-disc pl-6 space-y-2  mx-5">
                            <li>Atteindre 80% de collecte des déchets urbains d'ici 2027</li>
                            <li>Construire 3 centres de tri et de recyclage dans les grandes villes</li>
                        </ul>
                        <div className="m-4 p-1.5 rounded-md hover:scale-105 transition-all cursor-pointer w-max text-[#3ad12c] border border-[#3ad12c]">
                            Téléchargement
                        </div>
                    </div>

                    <div data-aos="fade-up" class=" rounded-xl overflow-hidden  border-[1px] border-t-4 border-b-[#3ad12c] border-t-[#3ad12c]">
                        <h2 class="text-xl font-bold mb-4 px-4 text-[#3ad12c] text-nowrap text py-4 text-start bg-[#3ad12c0e]  text-ellipsis  overflow-hidden">5. Perte de biodiversité</h2>
                        <p class="mb-1 p-2 text-[18px]">30% des espèces connues menacées d'extinction</p>
                        <h4 class="text-lg font-bold mt-4 mx-4 mb-2">Actions réalisées :</h4>
                        <ul class="list-disc pl-6 space-y-2  mx-5">
                            <li>Création d'une banque de gènes pour 500 espèces végétales endémiques</li>
                            <li>Établissement de 4 nouveaux corridors écologiques</li>
                        </ul>
                        <h4 class="text-lg font-bold mt-4 mx-4 mb-2">Actions à entreprendre :</h4>
                        <ul class="list-disc pl-6 space-y-2  mx-5">
                            <li>Augmenter la surface des aires protégées à 20% du territoire d'ici 2030</li>
                            <li>Mettre en œuvre des plans de conservation pour 100 espèces menacées</li>
                        </ul>
                        <div className="m-4 p-1.5 rounded-md hover:scale-105 transition-all cursor-pointer w-max text-[#3ad12c] border border-[#3ad12c]">
                            Téléchargement
                        </div>
                    </div>

                    <div data-aos="fade-up" class=" rounded-xl overflow-hidden  border-[1px] border-t-4 border-b-[#3ad12c] border-t-[#3ad12c]">
                        <h2 class="text-xl font-bold mb-4 px-4 text-[#3ad12c] text-nowrap text py-4 text-start bg-[#3ad12c0e]  text-ellipsis  overflow-hidden">6. Changement climatique</h2>
                        <p class="mb-1 p-2 text-[18px]">Augmentation de la température moyenne de 1.3°C depuis 1970</p>
                        <h4 class="text-lg font-bold mt-4 mx-4 mb-2">Actions réalisées :</h4>
                        <ul class="list-disc pl-6 space-y-2  mx-5">
                            <li>Adoption d'un Plan National d'Adaptation au Changement Climatique</li>
                            <li>Installation de 100 stations météorologiques automatiques</li>
                        </ul>
                        <h4 class="text-lg font-bold mt-4 mx-4 mb-2">Actions à entreprendre :</h4>
                        <ul class="list-disc pl-6 space-y-2  mx-5">
                            <li>Réduire les émissions de GES de 25% d'ici 2030 (par rapport à 2010)</li>
                            <li>Développer des variétés résistantes à la sécheresse pour 10 cultures principales</li>
                        </ul>
                        <div className="m-4 p-1.5 rounded-md hover:scale-105 transition-all cursor-pointer w-max text-[#3ad12c] border border-[#3ad12c]">
                            Téléchargement
                        </div>
                    </div>
                    <div data-aos="fade-up" class=" rounded-xl overflow-hidden  border-[1px] border-t-4 border-b-[#3ad12c] border-t-[#3ad12c]">
                        <h2 class="text-xl font-bold mb-4 px-4 text-[#3ad12c] text-nowrap text py-4 text-start bg-[#3ad12c0e]  text-ellipsis  overflow-hidden">7. Surexploitation des ressources halieutiques</h2>
                        <p class="mb-1 p-2 text-[18px]">Diminution de 50% des stocks de poissons depuis 2000</p>
                        <h4 class="text-lg font-bold mt-4 mx-4 mb-2">Actions réalisées :</h4>
                        <ul class="list-disc pl-6 space-y-2  mx-5">
                            <li>Mise en place de quotas pour 15 espèces commerciales</li>
                            <li>Création de 5 zones de protection intégrale dans le lac Tanganyika</li>
                        </ul>
                        <h4 class="text-lg font-bold mt-4 mx-4 mb-2">Actions à entreprendre :</h4>
                        <ul class="list-disc pl-6 space-y-2  mx-5">
                            <li>Étendre les zones de protection à 30% de la surface du lac d'ici 2028</li>
                            <li>Développer l'aquaculture durable pour produire 5000 tonnes/an d'ici 2030</li>
                        </ul>
                        <div className="m-4 p-1.5 rounded-md hover:scale-105 transition-all cursor-pointer w-max text-[#3ad12c] border border-[#3ad12c]">
                            Téléchargement
                        </div>
                    </div>

                    <div data-aos="fade-up" class=" rounded-xl overflow-hidden  border-[1px] border-t-4 border-b-[#3ad12c] border-t-[#3ad12c]">
                        <h2 class="text-xl font-bold mb-4 px-4 text-[#3ad12c] text-nowrap text py-4 text-start bg-[#3ad12c0e]  text-ellipsis  overflow-hidden">8. Accès à l'eau potable</h2>
                        <p class="mb-1 p-2 text-[18px]">61% de la population a accès à l'eau potable (2023)</p>
                        <h4 class="text-lg font-bold mt-4 mx-4 mb-2">Actions réalisées :</h4>
                        <ul class="list-disc pl-6 space-y-2  mx-5">
                            <li>Construction de 2000 points d'eau entre 2020 et 2023</li>
                            <li>Réhabilitation de 100 systèmes d'adduction d'eau existants</li>
                        </ul>
                        <h4 class="text-lg font-bold mt-4 mx-4 mb-2">Actions à entreprendre :</h4>
                        <ul class="list-disc pl-6 space-y-2  mx-5">
                            <li>Atteindre un taux d'accès à l'eau potable de 85% d'ici 2030</li>
                            <li>Mettre en place un système de traitement des eaux dans toutes les villes de plus de 50,000 habitants</li>
                        </ul>
                        <div className="m-4 p-1.5 rounded-md hover:scale-105 transition-all cursor-pointer w-max text-[#3ad12c] border border-[#3ad12c]">
                            Téléchargement
                        </div>
                    </div>

                    <div data-aos="fade-up" class=" rounded-xl overflow-hidden  border-[1px] border-t-4 border-b-[#3ad12c] border-t-[#3ad12c]">
                        <h2 class="text-xl font-bold mb-4 px-4 text-[#3ad12c] text-nowrap text py-4 text-start bg-[#3ad12c0e]  text-ellipsis  overflow-hidden">9. Agriculture non durable</h2>
                        <p class="mb-1 p-2 text-[18px]">85% des agriculteurs utilisent des pratiques non durables</p>
                        <h4 class="text-lg font-bold mt-4 mx-4 mb-2">Actions réalisées :</h4>
                        <ul class="list-disc pl-6 space-y-2  mx-5">
                            <li>Formation de 200,000 agriculteurs aux pratiques agroécologiques</li>
                            <li>Mise en place de 100 fermes-écoles démontrant les techniques durables</li>
                        </ul>
                        <h4 class="text-lg font-bold mt-4 mx-4 mb-2">Actions à entreprendre :</h4>
                        <ul class="list-disc pl-6 space-y-2  mx-5">
                            <li>Convertir 40% des terres agricoles aux pratiques durables d'ici 2030</li>
                            <li>Introduire un système de certification pour l'agriculture durable</li>
                        </ul>
                        <div className="m-4 p-1.5 rounded-md hover:scale-105 transition-all cursor-pointer w-max text-[#3ad12c] border border-[#3ad12c]">
                            Téléchargement
                        </div>
                    </div>



                    <div data-aos="fade-up" class=" rounded-xl overflow-hidden  border-[1px] border-t-4 border-b-[#3ad12c] border-t-[#3ad12c]">
                        <h2 class="text-xl font-bold mb-4 px-4 text-[#3ad12c] text-nowrap text py-4 text-start bg-[#3ad12c0e]  text-ellipsis  overflow-hidden">10. Pollution atmosphérique urbaine</h2>
                        <p class="mb-1 p-2 text-[18px]">Concentration de PM2.5 à Bujumbura : 35 µg/m³ (OMS recommande {"<"} 10 µg/m³)</p>
                        <h4 class="text-lg font-bold mt-4 mx-4 mb-2">Actions réalisées :</h4>
                        <ul class="list-disc pl-6 space-y-2  mx-5">
                            <li>Installation de 10 stations de surveillance de la qualité de l'air</li>
                            <li>Introduction de normes d'émission pour les véhicules importés</li>
                        </ul>
                        <h4 class="text-lg font-bold mt-4 mx-4 mb-2">Actions à entreprendre :</h4>
                        <ul class="list-disc pl-6 space-y-2  mx-5">
                            <li>Réduire les émissions de PM2.5 de 40% d'ici 2028</li>
                            <li>Introduire 500 bus électriques dans le transport public d'ici 2026</li>
                        </ul>
                        <div className="m-4 p-1.5 rounded-md hover:scale-105 transition-all cursor-pointer w-max text-[#3ad12c] border border-[#3ad12c]">
                            Téléchargement
                        </div>
                    </div>

                    <div data-aos="fade-up" class=" rounded-xl overflow-hidden  border-[1px] border-t-4 border-b-[#3ad12c] border-t-[#3ad12c]">
                        <h2 class="text-xl font-bold mb-4 px-4 text-[#3ad12c] text-nowrap text py-4 text-start bg-[#3ad12c0e]  text-ellipsis  overflow-hidden">11. Gestion des pesticides</h2>
                        <p class="mb-1 p-2 text-[18px]">75% des agriculteurs utilisent des pesticides de manière non conforme</p>
                        <h4 class="text-lg font-bold mt-4 mx-4 mb-2">Actions réalisées :</h4>
                        <ul class="list-disc pl-6 space-y-2  mx-5">
                            <li>Formation de 50,000 agriculteurs à l'utilisation contrôlée des pesticides</li>
                            <li>Interdiction de 20 pesticides dangereux en 2022</li>
                        </ul>
                        <div className="m-4 p-1.5 rounded-md hover:scale-105 transition-all cursor-pointer w-max text-[#3ad12c] border border-[#3ad12c]">
                            Téléchargement
                        </div>
                    </div>
                </div>
            </div>
        </div>


    )
}

export default Rapport