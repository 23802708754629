import React from 'react'
import { animaux } from '../../../Data/Data'
import NavBarsRessource from './NavBarsRessource'
import { useNavigate } from 'react-router-dom'
import { useThemes } from '../../../UserContext/UserContext'

function Animal() {
    const navig = useNavigate()
    const { HundlwScrollTop } = useThemes()
    return (
        <div div className='overflow-hidden'>
            <div data-aos-duration="1000" class="rounded-lg mt-[18vh]">
                <NavBarsRessource />
                <div data-aos="fade-up" class=" mx-auto">
                    <div class="rounded-lg">
                        <div class="p-6">
                            <p class="mb-4 text-lg">Découvrez ci-dessous une sélection d'espèces emblématiques de la biodiversité burundaise, avec leurs noms scientifiques, leurs correspondances en Kirundi, et leurs localisations principales.</p>

                            <div class="flex items-center justify-between w-full mb-4">


                                <div className='flex w-[100%] items-center'>
                                    <div className='w-full'>
                                        <input placeholder="Proposer le nom  d'un espèces " className='p-2 w-full bg-transparent outline-none rounded-s border focus:border focus:border-[#3ad12c]' />
                                    </div>
                                    <div class="bg-[#3ad12c86] cursor-pointer border border-[#3ad12c] hover:bg-[#3ad12c] text-white font-semibold  p-2 rounded-e inline-block">
                                        Recherche
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <div data-aos="fade-up" className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-8">
                {animaux.map((animal, index) => (
                    <div onClick={() => { navig("/ressource/espece/detail"); HundlwScrollTop() }} data-aos="fade-up" key={index} className=" cursor-pointer hover:scale-105 transition-all rounded-lg shadow-lg p-6">
                        <img src={animal.image} alt={animal.nom} className="w-full h-48 object-cover rounded-t-lg mb-4" />
                        <h2 className="text-xl font-bold">{animal.nom}</h2>
                        <p className="">{animal.titre}</p>
                        <p className="italic ">{animal.name}</p>
                        <p className="mt-4 text-sm ">{animal.nomParc}</p>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default Animal