import React, { useState } from 'react'
import { useThemes } from '../../../UserContext/UserContext'
import { useNavigate } from 'react-router-dom'
import { Progress } from 'rsuite'
import Footer from '../../Visiteurs/FootentContent/Footer'

function AdminCoursProposeDeatail() {
    const { isDarVisitLoggined, detailCours } = useThemes()
    const [progession, Getprogession] = useState(30)
    const navig = useNavigate()
    return (
        <div className={`w-full overflow-y-auto ${isDarVisitLoggined === "dark" ? null : 'bg-gray-100'}  overflow-x-hidden h-[87vh]`}>
            <div className="w-full flex justify-start">
                <div onClick={() => navig("/cours/propose")} className="text-[#3ad12c] p-2 cursor-pointer">
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-arrow-left-circle-fill" viewBox="0 0 16 16">
                        <path d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0m3.5 7.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5z" />
                    </svg>
                </div>
            </div>
            <div class={`rounded-md ${isDarVisitLoggined === "dark" ? 'bg-gray-800' : 'bg-white'} m-2  p-4`}>
                <img src="https://file3.chinastory.cn/group1/M00/CB/77/CgoTp2BRseKAUAYrAA45N8sSgB842.jpeg" alt="Introduction à la Biodiversité au Burundi" class="rounded-md mb-2" />
                <h2 class="text-2xl font-semibold mb-2 ">{detailCours.titre}</h2>

                <div className='flex flex-col text-lg'>
                    <div className='w-full mt-2 flex justify-start'>
                        <div class="inline-block "><span>Niveau:</span> <span className='font-semibold '>{detailCours.niveau}</span></div>
                        <div class="inline-block mx-2 px-2 border-l-2"><span>Durée</span>: <span className='font-semibold'>10 semaines</span></div>
                    </div>
                </div>
                <p class="mt-5">{detailCours.context}</p>
            </div>
            <div className={` ${isDarVisitLoggined === "dark" ? 'bg-gray-800' : 'bg-white'} m-2 p-1`}>
                <Progress.Line percent={progession} strokeWidth={30} strokeColor="#3ad12c" />
                <div className='pl-3 text-lg'><span className='font-bold'>Progression</span>:<span className='ml-2'>{progession}%</span></div>
            </div>

            <div class="container mt-[5em] mx-auto p-2">
                <div class="grid grid-cols-1 md:grid-cols-2 gap-8">
                    {[1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1].map(() => (
                        <div className={`border p-2 rounded-lg border-b-2  ${isDarVisitLoggined === "dark" ? 'bg-gray-800' : 'bg-white'} border-b-[#3ad12c]`}>
                            <h2 class="text-xl font-bold mb-2">Module 1 : Introduction à l'Agroécologie</h2>
                            <p class=" mb-4">Découvrez les principes fondamentaux de l'agroécologie et son application dans l'agriculture burundaise.</p>
                            <div className='flex justify-between'>
                                <div onClick={() => navig("/cours/propose/module")} class="bg-[#3ad12c86] w-max cursor-pointer hover:bg-[#3ad12c] text-white font-semibold py-2 px-4 rounded inline-block">Continuer le module</div>
                                <div class="bg-[blue] w-max cursor-pointer  text-white font-semibold py-2 px-4 rounded inline-block">Modifier</div>
                                <div class="bg-[red] w-max cursor-pointer  text-white font-semibold py-2 px-4 rounded inline-block">Supprimer</div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>

            <div class={`${isDarVisitLoggined === "dark" ? 'bg-gray-800' : 'bg-white'} mx-auto m-2 px-4 py-8`}>
                <h2 class="text-2xl font-bold mb-4">Ressources Complémentaires</h2>
                <ul class="space-y-2">
                    <div className='flex items-center'>
                        <div>
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#3ad12c" class="bi bi-arrow-right-short" viewBox="0 0 16 16">
                                <path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8" />
                            </svg>
                        </div>
                        <div class="text-blue-500 hover:text-blue-700 cursor-pointer">Guide Pratique de l'Agroécologie au Burundi (PDF)</div>
                    </div>



                    <div className='flex items-center'>
                        <div>
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#3ad12c" class="bi bi-arrow-right-short" viewBox="0 0 16 16">
                                <path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8" />
                            </svg>
                        </div>
                        <div class="text-blue-500 hover:text-blue-700 cursor-pointer">Forum de discussion sur l'Agroécologie</div>
                    </div>
                    <div className='flex items-center'>
                        <div>
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#3ad12c" class="bi bi-arrow-right-short" viewBox="0 0 16 16">
                                <path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8" />
                            </svg>
                        </div>
                        <div class="text-blue-500 hover:text-blue-700 cursor-pointer">Carte des Initiatives Agroécologiques au Burundi</div>
                    </div>
                </ul>


            </div>

            <div class={`${isDarVisitLoggined === "dark" ? 'bg-gray-800' : 'bg-white'} mt-5 m-2 mx-auto px-4 py-8`}>


                <h2 class="text-2xl font-bold mt-8 mb-4">Cours Recommandés</h2>
                <ul class="space-y-2">
                    <div className='flex items-center'>
                        <div>
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#3ad12c" class="bi bi-arrow-right-short" viewBox="0 0 16 16">
                                <path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8" />
                            </svg>
                        </div>
                        <div class="text-blue-500 hover:text-blue-700 cursor-pointer">Introduction à la Permaculture</div>
                    </div>
                    <div className='flex items-center'>
                        <div>
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#3ad12c" class="bi bi-arrow-right-short" viewBox="0 0 16 16">
                                <path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8" />
                            </svg>
                        </div>
                        <div class="text-blue-500 hover:text-blue-700 cursor-pointer">Gestion Durable de l'Eau</div>
                    </div>
                    <div className='flex items-center'>
                        <div>
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#3ad12c" class="bi bi-arrow-right-short" viewBox="0 0 16 16">
                                <path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8" />
                            </svg>
                        </div>
                        <div class="text-blue-500 hover:text-blue-700 cursor-pointer">Introduction à la Biodiversité du Burundi</div>
                    </div>
                </ul>
            </div>
            <Footer />
        </div>
    )
}

export default AdminCoursProposeDeatail