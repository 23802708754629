import React, { useState } from 'react'
import { problems } from '../../../Data/Data'
import { useThemes } from '../../../UserContext/UserContext'
import SignaleForm from './SignaleForm'
import { Drawer } from 'rsuite'
import { useNavigate } from 'react-router-dom'
import MapComponent from './MapComponent'

function Signale() {
  const { isDark, HundlwScrollTop } = useThemes()
  const navig = useNavigate()
  const [isSigner, GetisSigner] = useState(false)

  return (
    <div className=' mt-[18vh]  overflow-hidden'>
      <div className="container mx-auto p-4">
        <h1 className="text-3xl font-bold text-center mb-8">Liste des Problèmes Signalés</h1>
        <MapComponent />
        <div data-aos="fade-up" className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {problems.map((problem, index) => (
            <div
              onClick={() => { navig("/signal/detail"); HundlwScrollTop() }}
              key={index}
              className={`${isDark === "dark" ? 'bg-gray-800' : 'bg-white'} shadow-md rounded-lg p-6 cursor-pointer`}
            >
              <div>
                <img src='https://cdn.futura-sciences.com/sources/images/qr/QR_Pression_eau_1%201920%20px.jpg' alt='  ' />
              </div>

              <h2 className="text-xl font-semibold mb-2">{problem.name}</h2>
              <p className="">
                <strong>Contact :</strong> {problem.contact}
              </p>
              <p className=" text-lg">
                <strong>Description :</strong> {problem.description}
              </p>
              <p className="">
                <strong>Lieu :</strong> {problem.location}
              </p>
              <p className="">
                <strong>Gravité :</strong> {problem.severity}
              </p>
              <p className="">
                <strong>Actions :</strong> {problem.actions}
              </p>
              <p className="">
                <strong>Attentes :</strong> {problem.expectations}
              </p>
            </div>
          ))}
        </div>
        <div className='flex justify-end w-full'>
          <div onClick={() => GetisSigner(true)} class="bg-[#3ad12c] cursor-pointer hover:scale-105 transition-all text-white font-bold py-2 px-4 rounded-lg inline-block mt-4">
            Signaler un problème
          </div>
        </div>
      </div>

      <Drawer size="full" open={isSigner} placement='top' onClose={() => GetisSigner(false)}>
        <Drawer.Header>
          <Drawer.Title className='text-center uppercase'>Signaler un Problème</Drawer.Title>
        </Drawer.Header>
        <Drawer.Body>
          <SignaleForm />
        </Drawer.Body>
      </Drawer>
    </div>
  )
}

export default Signale