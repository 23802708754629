import React from 'react'
import NavBarsRessource from './NavBarsRessource'
import { useThemes } from '../../../UserContext/UserContext'

function ODD() {
    const { isDark } = useThemes()
    return (
        <div data-aos-duration="1000" class={` mt-[18vh] ${isDark === "dark" ? null : 'bg-gray-100'} overflow-hidden`}>
            <NavBarsRessource />
            <main class="container mx-auto p-6">
                <section class="mb-12">
                    <h2 class="text-2xl font-bold mb-4 ">Principales conventions internationales sur les ressources</h2>
                    <ul class="list-disc list-inside space-y-2">
                        <li class="text-lg ml-3">Convention sur la diversité biologique</li>
                        <li class="text-lg ml-3">Convention-cadre des Nations Unies sur les changements climatiques</li>
                        <li class="text-lg ml-3">Convention sur le commerce international des espèces de faune et de flore sauvages menacées d'extinction (CITES)</li>
                        <li class="text-lg ml-3">Convention des Nations Unies sur le droit de la mer</li>
                        <li class="text-lg ml-3">Convention de Ramsar sur les zones humides</li>
                    </ul>
                </section>

                <section>
                    <h2 class="text-2xl font-bold mb-6">Détails des principales conventions</h2>
                    <div class="space-y-6">
                        <div data-aos="fade-up" class={`law-card  p-6 mb-8 ${isDark === "dark" ? "bg-gray-800" : 'bg-white'} shadow-lg rounded-lg`}>
                            <h3 class="text-xl font-semibold mb-2 text-green-600">Convention sur la diversité biologique</h3>
                            <p class="mb-4">Cette convention, adoptée en 1992, a pour objectifs la conservation de la diversité biologique, l'utilisation durable de ses éléments et le partage juste et équitable des avantages découlant de l'exploitation des ressources génétiques. Ses principaux axes sont :</p>
                            <ul class="list-disc list-inside space-y-1">
                                <li class="text-lg ml-3">Identification et surveillance de la diversité biologique</li>
                                <li class="text-lg ml-3">Conservation in situ et ex situ des écosystèmes et des espèces</li>
                                <li class="text-lg ml-3">Utilisation durable des composantes de la diversité biologique</li>
                                <li class="text-lg ml-3">Accès aux ressources génétiques et partage des avantages</li>
                                <li class="text-lg ml-3">Coopération scientifique et technique</li>
                            </ul>
                        </div>

                        <div data-aos="fade-up" class={`law-card  p-6 mb-8 ${isDark === "dark" ? "bg-gray-800" : 'bg-white'} shadow-lg rounded-lg`}>
                            <h3 class="text-xl font-semibold mb-2 text-green-600">Convention-cadre des Nations Unies sur les changements climatiques</h3>
                            <p class="mb-4">Cette convention, adoptée en 1992, vise à stabiliser les concentrations de gaz à effet de serre dans l'atmosphère à un niveau qui empêche toute perturbation anthropique dangereuse du système climatique. Ses principaux éléments sont :</p>
                            <ul class="list-disc list-inside space-y-1">
                                <li class="text-lg ml-3">Objectifs de réduction des émissions de gaz à effet de serre</li>
                                <li class="text-lg ml-3">Adaptation aux impacts des changements climatiques</li>
                                <li class="text-lg ml-3">Transfert de technologies et soutien financier aux pays en développement</li>
                                <li class="text-lg ml-3">Mécanismes de marché pour réduire les émissions</li>
                                <li class="text-lg ml-3">Rapports et revues périodiques des engagements des États</li>
                            </ul>
                        </div>

                        <div data-aos="fade-up" class={`law-card  p-6 mb-8 ${isDark === "dark" ? "bg-gray-800" : 'bg-white'} shadow-lg rounded-lg`}>
                            <h3 class="text-xl font-semibold mb-2 text-green-600">Convention sur le commerce international des espèces de faune et de flore sauvages menacées d'extinction (CITES)</h3>
                            <p class="mb-4">Cette convention, adoptée en 1973, a pour objectif de réguler le commerce international des espèces animales et végétales menacées d'extinction. Ses principaux axes sont :</p>
                            <ul class="list-disc list-inside space-y-1">
                                <li class="text-lg ml-3">Listes d'espèces protégées avec différents niveaux de commerce réglementé</li>
                                <li class="text-lg ml-3">Système de permis et de contrôles aux frontières</li>
                                <li class="text-lg ml-3">Mesures sanctionnant le commerce illégal</li>
                                <li class="text-lg ml-3">Coopération internationale pour l'application de la convention</li>
                                <li class="text-lg ml-3">Soutien aux États pour la conservation des espèces</li>
                            </ul>
                        </div>

                        <div data-aos="fade-up" class={`law-card  p-6 mb-8 ${isDark === "dark" ? "bg-gray-800" : 'bg-white'} shadow-lg rounded-lg`}>
                            <h3 class="text-xl font-semibold mb-2 text-green-600">Convention des Nations Unies sur le droit de la mer</h3>
                            <p class="mb-4">Cette convention, adoptée en 1982, établit un cadre juridique global pour les activités menées dans les océans et les mers du globe. Ses principaux éléments sont :</p>
                            <ul class="list-disc list-inside space-y-1">
                                <li class="text-lg ml-3">Délimitation des zones maritimes (eaux territoriales, zone économique exclusive, etc.)</li>
                                <li class="text-lg ml-3">Droits et obligations des États côtiers et des États sans littoral</li>
                                <li class="text-lg ml-3">Réglementation de l'exploitation des ressources marines (halieutiques, minérales, etc.)</li>
                                <li class="text-lg ml-3">Protection et préservation du milieu marin</li>
                                <li class="text-lg ml-3">Règlement pacifique des différends</li>
                            </ul>
                        </div>

                        <div data-aos="fade-up" class={`law-card  p-6 mb-8 ${isDark === "dark" ? "bg-gray-800" : 'bg-white'} shadow-lg rounded-lg`}>
                            <h3 class="text-xl font-semibold mb-2 text-green-600">Convention de Ramsar sur les zones humides</h3>
                            <p class="mb-4">Cette convention, adoptée en 1971, a pour objectif la conservation et l'utilisation rationnelle des zones humides dans le monde entier. Ses principaux axes sont :</p>
                            <ul class="list-disc list-inside space-y-1">
                                <li class="text-lg ml-3">Identification et inscription de sites Ramsar</li>
                                <li class="text-lg ml-3">Gestion durable des zones humides inscrites</li>
                                <li class="text-lg ml-3">Coopération internationale pour la conservation des zones humides transfrontalières</li>
                                <li class="text-lg ml-3">Soutien technique et financier aux États membres</li>
                                <li class="text-lg ml-3">Sensibilisation du public à l'importance des zones humides</li>
                            </ul>
                        </div>
                    </div>
                </section>
            </main>

        </div>
    )
}

export default ODD