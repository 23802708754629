/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
import React, { forwardRef, useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom';

import { Badge, Popover, Radio, RadioGroup,  Whisper } from 'rsuite';
import { useThemes } from '../../../UserContext/UserContext';
import TranslateComponent from '../../TranslateComponent/TranslateComponent';
import { FadeLoader } from 'react-spinners';
import { Icon } from '@rsuite/icons';


function NavBarsAdmin() {

    const {SetisGoingToSite, isDarVisitLoggined, showNotifcation, GetShowNotifcation, triggerRef, close, handleChange } = useThemes()
    const navig = useNavigate()



    const [menu, SetMenue] = useState(false);
    const [mobile, setMobile] = useState(window.innerWidth < 1170);
    const [mobile11, setMobile11] = useState(window.innerWidth < 970);
    const [mobile12, setMobile12] = useState(window.innerWidth < 805);
    const [mobile13, setMobile13] = useState(window.innerWidth < 495);
    const [mobile1, setMobile1] = useState(window.innerWidth < 374);


    useEffect(() => {
        const handleResize = () => {
            setMobile(window.innerWidth < 1170);
            setMobile1(window.innerWidth < 374);
            setMobile11(window.innerWidth < 970);
            setMobile12(window.innerWidth < 805);
            setMobile13(window.innerWidth < 495);
            SetMenue(false);
        };

        const handleClick = () => {
            SetMenue(false);
        };

        window.addEventListener('resize', handleResize);
        window.addEventListener('click', handleClick);

        return () => {
            window.removeEventListener('resize', handleResize);
            window.removeEventListener('click', handleClick);
        };
    }, []);


  



    const deconnection = forwardRef((propos, ref) => (
        <svg {...propos} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi  bi-circle-half h-5 w-5" viewBox="0 0 16 16">
            <path fill-rule="evenodd" d="M10 12.5a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v2a.5.5 0 0 0 1 0v-2A1.5 1.5 0 0 0 9.5 2h-8A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h8a1.5 1.5 0 0 0 1.5-1.5v-2a.5.5 0 0 0-1 0z" />
            <path fill-rule="evenodd" d="M15.854 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 0 0-.708.708L14.293 7.5H5.5a.5.5 0 0 0 0 1h8.793l-2.147 2.146a.5.5 0 0 0 .708.708z" />
        </svg>

    ))


    const Sun = forwardRef((props, ref) => (
        <svg {...props} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi w-5 h-5 bi-sun" viewBox="0 0 16 16">
            <path d="M8 11a3 3 0 1 1 0-6 3 3 0 0 1 0 6m0 1a4 4 0 1 0 0-8 4 4 0 0 0 0 8M8 0a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 0m0 13a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 13m8-5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2a.5.5 0 0 1 .5.5M3 8a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2A.5.5 0 0 1 3 8m10.657-5.657a.5.5 0 0 1 0 .707l-1.414 1.415a.5.5 0 1 1-.707-.708l1.414-1.414a.5.5 0 0 1 .707 0m-9.193 9.193a.5.5 0 0 1 0 .707L3.05 13.657a.5.5 0 0 1-.707-.707l1.414-1.414a.5.5 0 0 1 .707 0m9.193 2.121a.5.5 0 0 1-.707 0l-1.414-1.414a.5.5 0 0 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .707M4.464 4.465a.5.5 0 0 1-.707 0L2.343 3.05a.5.5 0 1 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .708" />
        </svg>
    ));
    const Moon = forwardRef((props, ref) => (
        <svg {...props} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-moon-fill h-7 y-7 cursor-pointer" viewBox="0 0 16 16">
            <path d="M6 .278a.77.77 0 0 1 .08.858 7.2 7.2 0 0 0-.878 3.46c0 4.021 3.278 7.277 7.318 7.277q.792-.001 1.533-.16a.79.79 0 0 1 .81.316.73.73 0 0 1-.031.893A8.35 8.35 0 0 1 8.344 16C3.734 16 0 12.286 0 7.71 0 4.266 2.114 1.312 5.124.06A.75.75 0 0 1 6 .278" />
        </svg>
    ));
    const SemiMoon = forwardRef((props, ref) => (
        <svg {...props} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-circle-half h-5 w-5" viewBox="0 0 16 16">
            <path d="M8 15A7 7 0 1 0 8 1zm0 1A8 8 0 1 1 8 0a8 8 0 0 1 0 16" />
        </svg>
    ));




    const [isLoading1, GetisLoading1] = useState(true)
    const [isLoading2, GetisLoading2] = useState(true)


    const hundleLoading1 = () => {
        GetisLoading1(false)

    }

    const hundleLoading2 = () => {
        GetisLoading2(false)

    }


    return (
        <div className={`flex justify-between  ${isDarVisitLoggined === "dark" ? 'bg-gray-800' : 'bg-white'}   items-center px-2  border-b border-[#3ad12c] w-full h-[13vh]`}>
            <div onClick={() => navig("/")} className="flex justify-center items-center font-bold cursor-pointer">
                <div className='h-[20em] border-red-500 mt-7 w-auto'>
                    <img src='image/3.png' className='w-full h-full object-cover' alt='Logo' />
                </div>
                <div className='font-bold mx-4 font-serif text-[30px] text-[#3ad12c] uppercase'>Imuhira</div>
            </div>
            <div className='h-full w-max flex'>
                <div className='h-full  flex items-center'>
                    <TranslateComponent />
                    <button onClick={() => { GetShowNotifcation(!showNotifcation) }} className='mx-1 relative bg-[#3ad12c2d] hover:bg-[#3ad12c44] transition-all p-2 rounded cursor-pointer'>
                        <Badge content={9} className='absolute -top-1 text-[10px] -right-1' />
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#3ad12c" className="bi bi-bell-fill" viewBox="0 0 16 16">
                            <path d="M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2m.995-14.901a1 1 0 1 0-1.99 0A5 5 0 0 0 3 6c0 1.098-.5 6-2 7h14c-1.5-1-2-5.902-2-7 0-2.42-1.72-4.44-4.005-4.901" />
                        </svg>
                    </button>
                    <button onClick={() => { SetisGoingToSite(true); navig("/") }} className='mx-1 relative bg-[#3ad12c2d] hover:bg-[#3ad12c44] transition-all p-2 rounded cursor-pointer'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#3ad12c" className="bi bi-arrow-repeat" viewBox="0 0 16 16">
                            <path d="M11.534 7h3.932a.25.25 0 0 1 .192.41l-1.966 2.36a.25.25 0 0 1-.384 0l-1.966-2.36a.25.25 0 0 1 .192-.41m-11 2h3.932a.25.25 0 0 0 .192-.41L2.692 6.23a.25.25 0 0 0-.384 0L.342 8.59A.25.25 0 0 0 .534 9" />
                            <path fill-rule="evenodd" d="M8 3c-1.552 0-2.94.707-3.857 1.818a.5.5 0 1 1-.771-.636A6.002 6.002 0 0 1 13.917 7H12.9A5 5 0 0 0 8 3M3.1 9a5.002 5.002 0 0 0 8.757 2.182.5.5 0 1 1 .771.636A6.002 6.002 0 0 1 2.083 9z" />
                        </svg>
                    </button>
                    <div className='w-max h-max relative'>
                        <Whisper
                            placement='auto'
                            trigger='click'
                            ref={triggerRef}
                            speaker={
                                <Popover className="">
                                    <div>
                                        <div>
                                            <div onClick={() => { navig("/compte"); close() }} className='w-24 h-24 cursor-ponter relative  overflow-hidden'>

                                                {isLoading2 && <div className={`absolute  w-full z-[60]  h-full  pl-4 pt-2  top-0 left-0   flex items-center justify-center`}>
                                                    <FadeLoader
                                                        color="#36d7b7"
                                                        height={15}
                                                        width={2}
                                                        margin={-5}
                                                    />
                                                </div>
                                                }
                                                <img draggable='false' onLoad={hundleLoading2}
                                                    src="image/partenaire.jpg" alt="    " className='w-full h-full object-cover' />
                                            </div>
                                            <div onClick={() => { navig("/compte"); close() }} className=' mt-2  cursor-pointer text-[20px]'>Keza</div>
                                        </div>
                                        <hr />
                                        <p className="text-[17px] my-1 cursor-default">Thème</p>
                                        <RadioGroup name="radio-name" value={isDarVisitLoggined} onChange={handleChange}>
                                            <label htmlFor="lumi" className={`flex px-2 rounded cursor-pointer hover:bg-[#ff910063] w-full justify-between items-center ${isDarVisitLoggined === 'light' ? 'text-[#AF6E04]' : null}`}>
                                                <div className="flex items-center justify-start w-[15em]  ">
                                                    <div className="mr-2">
                                                        <Icon as={Sun} />
                                                    </div>
                                                    <div className="text-[18px]">Mode lumière</div>
                                                </div>
                                                <div className=" flex w-7"> <Radio id="lumi" checked={isDarVisitLoggined === 'light'} value="light"></Radio></div>
                                            </label>
                                            <label htmlFor="sombe" className={`flex px-2 rounded cursor-pointer hover:bg-[#ff910063] w-full justify-between items-center ${isDarVisitLoggined === 'dark' ? 'text-[#AF6E04]' : null}`}>
                                                <div className="flex items-center w-[15em] ">
                                                    <div className="mr-2"><Icon as={Moon} /></div>
                                                    <div className="text-[18px]">Mode sombre</div>
                                                </div>
                                                <div className=" flex w-7 "> <Radio id="sombe" checked={isDarVisitLoggined === 'dark'} value="dark"></Radio></div>
                                            </label>
                                        </RadioGroup>
                                        <div className="w-full h-[1px] my-2 bg-slate-300"></div>
                                        <div className={`flex p-2 mb-1 rounded cursor-pointer hover:bg-[#ff910063] w-full justify-between items-center `}>
                                            <div className="flex  w-[15em] items-center">
                                                <div className="mr-2"><Icon as={deconnection} /></div>
                                                <div className="text-[17px]">Deconnecte</div>
                                            </div>
                                        </div>
                                    </div>

                                </Popover>
                            }
                        >
                            <div className="flex justify-end items-center h-full  mr-1 px-3">
                                <div className={`cursor-pointer relative overflow-hidden w-[50px] h-[50px] rounded-full border-2 border-[#3ad12c]`}>

                                    {isLoading1 && <div className={`absolute  w-full z-[60]  h-full  pl-4 pt-2  top-0 left-0   flex items-center justify-center`}>
                                        <FadeLoader
                                            color="#36d7b7"
                                            height={mobile1 ? '' : 15}
                                            width={2}
                                            margin={-5}
                                        />
                                    </div>
                                    }
                                    <img draggable='false' onLoad={hundleLoading1}
                                        src="image/partenaire.jpg"  alt='    ' className='w-full h-full object-cover' />
                                </div>
                            </div>
                        </Whisper>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NavBarsAdmin








