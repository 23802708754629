/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import React, { useEffect, useState } from "react";
import { useLocation, Link, useNavigate } from "react-router-dom";
import { useThemes } from "../../../UserContext/UserContext";
function AdminSlide() {
    const { pathname } = useLocation()
    const { isDarVisitLoggined } = useThemes()
    const navig = useNavigate()

    let acceuil = /^\/acceuil.*/
    let cours = /^\/cours.*/
    let signal = /^\/signal.*/
    let ressource = /^\/ressource.*/
    let ressourceRapport = /^\/rapport.*/
    let ressourceFaits = /^\/fait.*/
    let ressourceEspece = /^\/ressource\/espece.*/
    let ressourceLois = /^\/ressource\/lois.*/
    let ressourceInternatio = /^\/ressource\/imterna.*/
    let ressourceRegion = /^\/ressource\/region.*/
    let ressourceOdd = /^\/ressource\/odd.*/


    const [mobile, SetMobile] = useState(window.innerWidth < 1292)
    const [mobile11, SetMobile11] = useState(window.innerWidth < 501)
    useEffect(() => {
        const hundleSize = () => {
            SetMobile(window.innerWidth < 1292)
            SetMobile11(window.innerWidth < 501)
        }

        window.addEventListener('resize', hundleSize)


        return () => window.removeEventListener('resize', hundleSize)
    }, [])

    const [isShowRessource, SetisShowRessource] = useState(false)
    return (
        <>
            <div className={`  border-r-2 border-[#3ad12c] w-[10em]  ${isDarVisitLoggined === "dark" ? 'bg-gray-800' : 'bg-white'}  overflow-y-auto overflow-x-hidden  h-[87vh] `}>
                <div className="p-1 mb-5">
                    <div className="flex-1 flex flex-col pt-5 overflow-x-hidden pb-4 overflow-y-auto">
                        <div className="flex-1 px-1 h-full  divide-y space-y-1">
                            <ul className="space-y-2 pb-2">
                                {acceuil.test(pathname) || pathname === "/" ? (
                                    <div className="hover:no-underline focus:no-underline cursor-pointer" onClick={() => { navig("/"); SetisShowRessource(false) }}>
                                        <li>
                                            <div className="text-lg  hover:no-underline text-[#3ad12c]  flex items-center font-bold p-2 ">
                                                <span className=" whitespace-nowrap">
                                                    Accueil
                                                </span>
                                            </div>
                                        </li>
                                    </div>
                                ) : (
                                    <div className="hover:no-underline focus:no-underline cursor-pointer" onClick={() => { navig("/"); SetisShowRessource(false) }}>
                                        <li>
                                            <div className="text-lg  font-normal hover:no-underline no-underline text-inherit   hover:text-gray-400  flex items-center p-2 ">
                                                <span className=" whitespace-nowrap">
                                                    Accueil
                                                </span>
                                            </div>
                                        </li>
                                    </div>
                                )}
                                {cours.test(pathname) ? (
                                    <div className="hover:no-underline focus:no-underline cursor-pointer" onClick={() => { navig("/cours"); SetisShowRessource(false) }}>
                                        <li>
                                            <div target="_blank"
                                                className="text-lg  hover:no-underline  text-[#3ad12c]  flex items-center font-bold p-2 "
                                            >
                                                <span className=" whitespace-nowrap first-letter:uppercase">
                                                    cours
                                                </span>
                                            </div>
                                        </li>
                                    </div>
                                ) : (
                                    <div className="hover:no-underline focus:no-underline cursor-pointer" onClick={() => { navig("/cours"); SetisShowRessource(false) }}>
                                        <li>
                                            <div

                                                className="text-lg  font-normal hover:no-underline  hover:text-gray-400 flex items-center p-2 "
                                            >
                                                <span className=" text-nowrap first-letter:uppercase">
                                                    cours
                                                </span>

                                            </div>
                                        </li>
                                    </div>
                                )}
                                {signal.test(pathname) ? (
                                    <div className="hover:no-underline focus:no-underline cursor-pointer" onClick={() => { navig("/signal"); SetisShowRessource(false) }}>
                                        <li>
                                            <div className="text-lg hover:no-underline  text-[#3ad12c]  flex items-center font-bold p-2 ">
                                                <span className=" whitespace-nowrap first-letter:uppercase">
                                                    signal
                                                </span>

                                            </div>
                                        </li>
                                    </div>
                                ) : (
                                    <div className="hover:no-underline focus:no-underline cursor-pointer" onClick={() => { navig("/signal"); SetisShowRessource(false) }}>
                                        <li>
                                            <div className="text-lg  font-normal hover:no-underline  hover:text-gray-400 flex items-center p-2 ">

                                                <span className=" whitespace-nowrap first-letter:uppercase">
                                                    signal
                                                </span>

                                            </div>
                                        </li>
                                    </div>
                                )}


                                <div className="hover:no-underline focus:no-underline">
                                    <li>
                                        <div className=" border-b pb-1 border-gray-600 text-lg whitespace-nowrap">
                                            Ressource
                                        </div>

                                        <div>
                                            <div className={`pl-3 text-nowrap text-ellipsis overflow-hidden mt-2 cursor-pointer  ${ressource.test(pathname) ? 'text-[#3ad12c]' : ''}`} onClick={() => { navig("/ressource") }}>Ressource</div>
                                            <div className={`pl-3 text-nowrap text-ellipsis overflow-hidden mt-2 cursor-pointer  ${ressourceRapport.test(pathname) ? 'text-[#3ad12c]' : ''}`} onClick={() => { navig("/rapport") }}>Rapport</div>
                                            <div className={`pl-3 text-nowrap text-ellipsis overflow-hidden mt-2 cursor-pointer  ${ressourceFaits.test(pathname) ? 'text-[#3ad12c]' : ''}`} onClick={() => { navig("/fait") }}>Faits</div>
                                            <div className={`pl-3 text-nowrap text-ellipsis overflow-hidden mt-2 cursor-pointer  ${ressourceEspece.test(pathname) ? '' : ''}`}>Espèces</div>
                                            <div className={`pl-3 text-nowrap text-ellipsis overflow-hidden mt-2 cursor-pointer  ${ressourceLois.test(pathname) ? '' : ''}`}>Lois</div>
                                            <div className={`pl-3 text-nowrap text-ellipsis overflow-hidden mt-2 cursor-pointer  ${ressourceInternatio.test(pathname) ? '' : ''}`}>International</div>
                                            <div className={`pl-3 text-nowrap text-ellipsis overflow-hidden mt-2 cursor-pointer  ${ressourceRegion.test(pathname) ? '' : ''}`}>Régional</div>
                                            <div className={`pl-3 text-nowrap text-ellipsis overflow-hidden mt-2 cursor-pointer  ${ressourceOdd.test(pathname) ? '' : ''}`}>ODD</div>
                                        </div>

                                    </li>
                                </div>

                            </ul>
                        </div>
                    </div>
                </div>


            </div>
        </>
    );
}

export default AdminSlide;





