import React from 'react'
import { courses } from '../../../Data/Data';
import { useThemes } from '../../../UserContext/UserContext';
import Footer from '../../Visiteurs/FootentContent/Footer';
import { useNavigate } from 'react-router-dom';
import AdminCoursNavBars from './AdminCoursNavBars';

function AdminCoursPropose() {
    const { isDarVisitLoggined, SetDetailCours } = useThemes()
    const navig = useNavigate()
    return (
        <div className={`w-full overflow-y-auto ${isDarVisitLoggined === "dark" ? null : 'bg-gray-100'}  overflow-x-hidden h-[87vh]`}>
            <div className='min-h-[87vh] p-4'>
                <AdminCoursNavBars />
                <h1 className="text-2xl font-bold mb-4">Listes des cours proposées</h1>
                <div className='flex w-full justify-between mb-4 items-center'>
                    <div className='flex w-[60%] items-center'>
                        <div className='w-full'>
                            <input className='p-2 w-full bg-transparent outline-none rounded-s border focus:border focus:border-[#3ad12c]' placeholder='Rechercher le cours' />
                        </div>
                        <button className="bg-[#3ad12c86] cursor-pointer border border-[#3ad12c] hover:bg-[#3ad12c] text-white font-semibold  p-2 rounded-e inline-block">
                            Recherche
                        </button>
                    </div>
                </div>

                <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-4 mb-4">
                    {courses.map((d, i) => (
                        <div key={i} className={`rounded-md ${isDarVisitLoggined === "dark" ? 'bg-gray-800' : 'bg-white'} p-4`}>
                            <img src="https://file3.chinastory.cn/group1/M00/CB/77/CgoTp2BRseKAUAYrAA45N8sSgB842.jpeg" alt="Introduction à la Biodiversité au Burundi" className="rounded-md mb-2" />
                            <h2 className="text-lg font-semibold mb-2 text-[#3ad12c]">{d.titre}</h2>
                            <p className="">{d.context}</p>
                            <div className='flex flex-col'>
                                <div className='w-full  flex justify-between'>
                                    <div className="font-semibold py-2  rounded inline-block text-yellow-500">Niveau: {d.niveau}</div>
                                    <div className="py-2  rounded inline-block">Durée: 10 semaines</div>
                                </div>
                                <div className='flex w-full justify-between items-center'>
                                    <div onClick={() => navig("/cours/propose/detail")} className=' border p-2 border-blue-600 rounded text-blue-600 hover:scale-105 transition-all cursor-pointer'>
                                        Détail
                                    </div>
                                    <div  className=' border p-2 border-[#3ad12c] rounded text-[#3ad12c] hover:scale-105 transition-all cursor-pointer'>
                                        Valider
                                    </div>
                                    <div className=' border p-2 border-[red] rounded text-[red] hover:scale-105 transition-all cursor-pointer'>
                                        Annuler
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default AdminCoursPropose