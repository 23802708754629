import React, { useRef, useState } from 'react'
import { toast } from 'react-toastify';

function SponsorForm() {
    const [nomComplet, GetnomComplet] = useState('')
    const elementRef = useRef(null)

    const [email, Getemail] = useState('')
    const elementemailRef = useRef(null)

    const hundleSubmite = (e) => {
        e.preventDefault();
        if (nomComplet.trim() == "") {
            toast.warning("Le nom complet est obligatpire !!")
            if (elementRef && elementRef.current) {
                elementRef.current.focus()
            }
            return false
        }else if (email.trim() === "") {
            toast.warning("L'email est obligatpire !!")
            if (elementemailRef && elementemailRef.current) {
                elementemailRef.current.focus()
            }
        } else {
            toast.success("Votre demande de parrainage a été soumise !");
        }

       
    }
    return (
        <div className='p-4'>
            <p className="mb-4 text-center">
                En tant que sponsor, vous aurez l'opportunité de recevoir une reconnaissance sur nos plateformes et lors de nos événements locaux. Merci de remplir le formulaire ci-dessous pour nous indiquer votre intérêt.
            </p>
            <form
                onSubmit={hundleSubmite}
            >
                <div className="mb-4">
                    <label htmlFor="name" className="block text-lg font-semibold mb-2">Nom</label>
                    <input
                        type="text"
                        id="name"
                        onInput={(e) => GetnomComplet(e.target.value)}
                        ref={elementRef}
                        className="block w-full  p-3 border border-gray-300 rounded-lg focus:outline-none focus:border-outline-none bg-transparent"
                        
                    />
                </div>
                <div className="mb-4">
                    <label htmlFor="email" className="block text-lg font-semibold mb-2">Email</label>
                    <input
                        type="email"
                        id="email"
                        onInput={(e) => Getemail(e.target.value)}
                        ref={elementemailRef}
                        className="block w-full  p-3 border border-gray-300 rounded-lg focus:outline-none focus:border-outline-none bg-transparent"
                        
                    />
                </div>
                <button type="submit" className="w-full bg-outline-none  text-white py-2 px-4 bg-[#3ad12c] rounded-lg hover:scale-105 transition-all focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-50">
                    Soumettre
                </button>
               
            </form>
        </div>
    )
}

export default SponsorForm