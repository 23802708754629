import React, { useState } from "react";

export default function SignaleForm() {
    const [formData, setFormData] = useState({
        name: "",
        contact: "",
        description: "",
        location: "",
        severity: "",
        actions: "",
        expectations: "",
        image: "",
    });

    const [errors, setErrors] = useState({});
    const [previewImages, setPreviewImages] = useState('');

    const handleChange = (e) => {
        if (e.target.name === "image") {
            const file = e.target.files[0];
            if (file) {
                setPreviewImages(URL.createObjectURL(file));
                setFormData({ ...formData, image: file });
            }
        } else {
            setFormData({ ...formData, [e.target.name]: e.target.value });
        }
    };

    const validateForm = () => {
        const newErrors = {};
        if (!formData.name) newErrors.name = "Nom est requis";
        if (!formData.contact) newErrors.contact = "Coordonnées sont requises";
        if (!formData.description) newErrors.description = "Description est requise";
        if (!formData.location) newErrors.location = "Lieu est requis";
        if (!formData.severity) newErrors.severity = "Gravité est requise";
        if (!formData.actions) newErrors.actions = "Actions sont requises";
        if (!formData.expectations) newErrors.expectations = "Attentes sont requises";
        if (!formData.image) newErrors.image = "Prenez l'image";

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (validateForm()) {
            // Envoyer les données du formulaire
            console.log("Formulaire soumis avec succès :", formData);
            // Réinitialisation du formulaire après soumission
            setFormData({
                name: "",
                contact: "",
                description: "",
                location: "",
                severity: "",
                actions: "",
                expectations: "",
                image: '',
            });
            setPreviewImages('');
        }
    };

    return (

        <form onSubmit={handleSubmit} className="w-full p-5">
            <div className="mb-4">
                <label htmlFor="name" className="block text-lg font-bold mb-2">
                    Votre nom :
                </label>
                <input
                    type="text"
                    id="name"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    className={`shadow appearance-none border rounded  p-3 w-full bg-transparent focus:border-[#3ad12c] outline-none focus:outline-none focus:shadow-outline ${errors.name ? "border-red-500" : ""
                        }`}
                    autoFocus
                />
                {errors.name && <p className="text-red-500 text-xs mt-1">{errors.name}</p>}
            </div>

            <div className="mb-4">
                <label htmlFor="contact" className="block text-lg font-bold mb-2">
                    Vos coordonnées :
                </label>
                <input
                    type="text"
                    id="contact"
                    name="contact"
                    value={formData.contact}
                    onChange={handleChange}
                    className={`shadow appearance-none border rounded  p-3 w-full bg-transparent focus:border-[#3ad12c] outline-none focus:outline-none focus:shadow-outline ${errors.contact ? "border-red-500" : ""
                        }`}

                />
                {errors.contact && <p className="text-red-500 text-xs mt-1">{errors.contact}</p>}
            </div>

            <div className="mb-4">
                <label htmlFor="description" className="block text-lg font-bold mb-2">
                    Description du problème :
                </label>
                <textarea
                    id="description"
                    name="description"
                    value={formData.description}
                    onChange={handleChange}
                    className={`shadow appearance-none border rounded  p-3 w-full bg-transparent focus:border-[#3ad12c] outline-none focus:outline-none focus:shadow-outline ${errors.description ? "border-red-500" : ""
                        }`}
                    rows="3"

                />
                {errors.description && <p className="text-red-500 text-xs mt-1">{errors.description}</p>}
            </div>

            <div className="mb-4">
                <label htmlFor="location" className="block text-lg font-bold mb-2">
                    Lieu du problème :
                </label>
                <input
                    type="text"
                    id="location"
                    name="location"
                    value={formData.location}
                    onChange={handleChange}
                    className={`shadow appearance-none border rounded  p-3 w-full bg-transparent focus:border-[#3ad12c] outline-none focus:outline-none focus:shadow-outline ${errors.location ? "border-red-500" : ""
                        }`}

                />
                {errors.location && <p className="text-red-500 text-xs mt-1">{errors.location}</p>}
            </div>

            <div className="mb-4">
                <label htmlFor="severity" className="block text-lg font-bold mb-2">
                    Gravité du problème :
                </label>
                <select
                    id="severity"
                    name="severity"
                    value={formData.severity}
                    onChange={handleChange}
                    className={`shadow appearance-none border rounded  p-3 w-full bg-transparent focus:border-[#3ad12c] outline-none focus:outline-none focus:shadow-outline ${errors.severity ? "border-red-500" : ""
                        }`}

                >
                    <option hidden value="">Sélectionner la gravité</option>
                    <option className="text-black" value="minor">Mineur</option>
                    <option className="text-black" value="major">Majeur</option>
                    <option className="text-black" value="critical">Critique</option>
                </select>
                {errors.severity && <p className="text-red-500 text-xs mt-1">{errors.severity}</p>}
            </div>

            <div className="mb-4">
                <label htmlFor="actions" className="block text-lg font-bold mb-2">
                    Actions déjà entreprises :
                </label>
                <textarea
                    id="actions"
                    name="actions"
                    value={formData.actions}
                    onChange={handleChange}
                    className={`shadow appearance-none border rounded  p-3 w-full bg-transparent focus:border-[#3ad12c] outline-none focus:outline-none focus:shadow-outline ${errors.actions ? "border-red-500" : ""
                        }`}
                    rows="3"

                />
                {errors.actions && <p className="text-red-500 text-xs mt-1">{errors.actions}</p>}
            </div>

            <div className="mb-4">
                <label htmlFor="expectations" className="block text-lg font-bold mb-2">
                    Vos attentes :
                </label>
                <textarea
                    id="expectations"
                    name="expectations"
                    value={formData.expectations}
                    onChange={handleChange}
                    className={`shadow appearance-none border rounded  p-3 w-full bg-transparent focus:border-[#3ad12c] outline-none focus:outline-none focus:shadow-outline ${errors.expectations ? "border-red-500" : ""
                        }`}
                    rows="3"

                />
                {errors.expectations && <p className="text-red-500 text-xs mt-1">{errors.expectations}</p>}
            </div>

            <div className="my-5 w-full flex h-max">
                <label htmlFor="image" className={`bg-transparent cursor-pointer py-3 pl-2 flex w-full border rounded ${errors.image ? 'border-red-500' : 'focus:ring-teal-300'}`}>
                    Image
                </label>
                <input
                    type="file"
                    id="image"
                    name="image"
                    hidden
                    accept="image/*"
                    onChange={handleChange}
                />
            </div>
            {previewImages && <img src={previewImages} alt="Rapport Preview" className="mb-4 h-48" />}



            <div className="flex justify-end">
                <button
                    type="submit"
                    className="bg-[#3ad12c] mb-5 hover:scale-105 transition-all text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                >
                    Envoyer
                </button>
            </div>
        </form>

    );
}
