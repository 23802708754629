import React, { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

function BenevoleProjets() {
    const nameRef = useRef(null);
    const emailRef = useRef(null);
    const phoneRef = useRef(null);
    const expertiseRef = useRef(null);
    const availabilityRef = useRef(null);
    const motivationRef = useRef(null);

    const validateForm = () => {
        // Validate name
        if (!nameRef.current.value) {
            toast.warning("Nom complet requis");
            nameRef.current.focus();
            return false;
        }

        // Validate email
        if (!emailRef.current.value) {
            toast.warning("Adresse e-mail requise");
            emailRef.current.focus();
            return false;
        } else if (!/\S+@\S+\.\S+/.test(emailRef.current.value)) {
            toast.warning('Adresse e-mail invalide');
            emailRef.current.focus();
            return false;
        }

        // Validate phone
        if (!phoneRef.current.value) {
            toast.warning('Numéro de téléphone requis');
            phoneRef.current.focus();
            return false;
        }

        // Validate expertise
        if (!expertiseRef.current.value) {
            toast.warning('Domaine d\'expertise requis');
            expertiseRef.current.focus();
            return false;
        }

        // Validate availability
        if (!availabilityRef.current.value) {
            toast.warning('Disponibilité requise');
            availabilityRef.current.focus();
            return false;
        }

        // Validate motivation
        if (!motivationRef.current.value) {
            toast.warning('Motivation requise');
            motivationRef.current.focus();
            return false;
        }

        return true;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (validateForm()) {
            // Retrieve values
            const formData = {
                name: nameRef.current.value,
                email: emailRef.current.value,
                phone: phoneRef.current.value,
                expertise: expertiseRef.current.value,
                availability: availabilityRef.current.value,
                motivation: motivationRef.current.value,
            };

            console.log('Form data:', formData);
            toast.success("Formulaire soumis avec succès");
        }
    };

    const navig = useNavigate()

    return (
        <div className='mt-[18vh] p-8 overflow-hidden'>
            <div onClick={() => navig("/projet")} className='w-7 m-2 h-7 bg-[#3ad12c] rounded-full cursor-pointer flex justify-center items-center text-white'>
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-arrow-left-short" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5" />
                </svg>
            </div>
            <div className="w-full mx-auto">
                <h1 className="text-3xl font-bold mb-6">Devenir bénévole pour le Projet Eco-Ecoles</h1>
                <div className="rounded-lg p-6">
                    <p className="mb-4 text-lg">Nous sommes à la recherche de bénévoles pour notre projet Eco-Ecoles. Votre engagement peut prendre différentes formes, comme l'animation d'ateliers de sensibilisation ou la participation à des activités sur le terrain.</p>
                    <div className='bg-[#3ad12c2a] p-2 rounded'>
                        <h2 className="text-lg font-bold mb-4">Comment pouvez-vous aider ?</h2>
                        <ul className="list-disc space-y-2">
                            <li className='ml-10 text-lg'>Animer des ateliers sur l'environnement dans les écoles</li>
                            <li className='ml-10 text-lg'>Aider à la collecte et à la valorisation des déchets dans les écoles</li>
                            <li className='ml-10 text-lg'>Participer à l'entretien des espaces verts et des potagers scolaires</li>
                            <li className='ml-10 text-lg'>Contribuer à la création de contenus pédagogiques</li>
                            <li className='ml-10 text-lg'>Assister dans la collecte de données pour l'évaluation du projet</li>
                        </ul>
                    </div>
                    <h2 className="text-lg font-bold mb-4 mt-6">Formulaire de candidature</h2>
                    <form className="space-y-4" onSubmit={handleSubmit}>
                        <div>
                            <label htmlFor="name" className="block font-medium mb-1 text-lg">Nom complet :</label>
                            <input
                                type="text"
                                id="name"
                                ref={nameRef}
                                className={`w-full border rounded-md px-3 py-2 outline-none bg-transparent focus:outline-none focus:ring-2 focus:ring-[#3ad12c] focus:border-[#3ad12c]`}
                            />
                        </div>
                        <div>
                            <label htmlFor="email" className="block font-medium mb-1 text-lg">Adresse e-mail :</label>
                            <input

                                id="email"
                                ref={emailRef}
                                className={`w-full border rounded-md px-3 py-2 outline-none bg-transparent focus:outline-none focus:ring-2 focus:ring-[#3ad12c] focus:border-[#3ad12c]`}
                            />
                        </div>
                        <div>
                            <label htmlFor="phone" className="block font-medium mb-1 text-lg">Numéro de téléphone :</label>
                            <input
                                type="tel"
                                id="phone"
                                ref={phoneRef}
                                className={`w-full border rounded-md px-3 py-2 outline-none bg-transparent focus:outline-none focus:ring-2 focus:ring-[#3ad12c] focus:border-[#3ad12c]`}
                            />
                        </div>
                        <div>
                            <label htmlFor="expertise" className="block font-medium mb-1 text-lg">Domaine d'expertise :</label>
                            <select
                                id="expertise"
                                ref={expertiseRef}
                                className={`w-full border rounded-md px-3 py-2 outline-none bg-transparent focus:outline-none focus:ring-2 focus:ring-[#3ad12c] focus:border-[#3ad12c]`}
                            >
                                <option className='text-black' value="">Choisissez un domaine</option>
                                <option className='text-black' value="domaine"> un domaine</option>
                            </select>
                        </div>
                        <div>
                            <label htmlFor="availability" className="block font-medium mb-1 text-lg">Disponibilité :</label>
                            <select
                                id="availability"
                                ref={availabilityRef}
                                className={`w-full border rounded-md px-3 py-2 outline-none bg-transparent focus:outline-none focus:ring-2 focus:ring-[#3ad12c] focus:border-[#3ad12c]`}
                            >
                                <option className='text-black' value="vide">Choisissez votre disponibilité</option>
                            </select>
                        </div>
                        <div>
                            <label htmlFor="motivation" className="block font-medium mb-1 text-lg">Pourquoi souhaitez-vous devenir bénévole pour le Projet Eco-Ecoles ?</label>
                            <textarea
                                id="motivation"
                                ref={motivationRef}
                                rows="3"
                                className={`w-full border rounded-md px-3 py-2 outline-none bg-transparent focus:outline-none focus:ring-2 focus:ring-[#3ad12c] focus:border-[#3ad12c]`}
                            ></textarea>
                        </div>
                        <button type="submit" className="bg-[#3ad12c]  text-white font-medium py-2 px-4 rounded-md">Envoyer ma candidature</button>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default BenevoleProjets;
