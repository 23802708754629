/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */

import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Footer from "../../Visiteurs/FootentContent/Footer";
import { useThemes } from "../../../UserContext/UserContext";


function AjouterCoursAdmin() {
    const { isDarVisitLoggined } = useThemes()
    const navig = useNavigate()

    const [mobile11, SetMobile11] = useState(window.innerWidth < 501);
    useEffect(() => {
        const hundlesSize = () => {
            SetMobile11(window.innerWidth < 501);
        };
        window.addEventListener('resize', hundlesSize);
        return () => {
            window.removeEventListener('resize', hundlesSize);
        };
    }, []);

    const [mobile, SetMobile] = useState(window.innerWidth <= 640);
    const [boutLoading, setboutLoading] = useState(false);

    useEffect(() => {
        const hundleSize = () => {
            SetMobile(window.innerWidth <= 640);
        };
        window.addEventListener('resize', hundleSize);
        return () => {
            window.removeEventListener('resize', hundleSize);
        };
    }, []);


    const [cours, setBlocs] = useState([
        {
            image: '',
            titre: '',
            date: '',
            min: '',
            heures: '',
            jours: '',
            semaines: '',
            mois: '',
            annee: '',
            niveaux: '',
            moodule: [{
                titre: '',
                moduleDescription: '',
                mooduleOption: [{
                    sous_titre: '',
                    sous_description: '',
                    photo_cover: '',
                    url: '',
                    preview: ''
                }]
            }],
            descript: '',
            quiz: [{
                question: '',
                reponse: '',
                option_choisir: [{ choix: '' }]
            }],
            ressources: [{
                titre: '',
                image_ressources: '',
                url: '',
                preview: ''
            }],
            preview: ''
        },
    ]);

    const addBloc = () => {
        setBlocs(prevBlocs => [
            ...prevBlocs,
            {
                image: '',
                titre: '',
                date: '',
                min: '',
                heures: '',
                jours: '',
                semaines: '',
                mois: '',
                annee: '',
                niveaux: '',
                moodule: [{
                    titre: '',
                    moduleDescription: '',
                    mooduleOption: [{
                        sous_titre: '',
                        sous_description: '',
                        photo_cover: '',
                        url: '',
                        preview: ''
                    }]
                }],
                descript: '',
                quiz: [{
                    question: '',
                    reponse: '',
                    option_choisir: [{ choix: '' }]
                }],
                ressources: [{
                    titre: '',
                    image_ressources: '',
                    url: '',
                    preview: ''
                }],
                preview: ''
            }
        ]);


        if (inputRefs.current[cours.length] && inputRefs.current[cours.length].moodule.length === 0) {
            // Ajout des références dans "moodule"
            inputRefs.current[cours.length].moodule.push({
                titre: React.createRef(),
                moduleDescription: React.createRef(),
                mooduleOption: [{
                    sous_titre: React.createRef(),
                    sous_description: React.createRef(),
                    photo_cover: React.createRef(),
                    url: React.createRef(),
                }]
            });
        }
        if (inputRefs.current[cours.length] && inputRefs.current[cours.length].quiz.length === 0) {
            // Ajout des références dans "moodule"
            inputRefs.current[cours.length].quiz.push({
                question: React.createRef(),
                reponse: React.createRef(),
                option_choisir: [{
                    choix: React.createRef(),
                }]
            });
        }
        if (inputRefs.current[cours.length] && inputRefs.current[cours.length].ressources.length === 0) {
            inputRefs.current[cours.length].ressources.push({
                titre: React.createRef(),
                reponse: React.createRef(),
                image_ressources: React.createRef(),

            });
        }
    };
    const removeBloc = (index) => {
        const newBlocs = [...cours];
        newBlocs.splice(index, 1);
        setBlocs(newBlocs);
    };
    const inputRefs = useRef([]);
    if (inputRefs.current.length !== cours.length) {
        inputRefs.current = Array(cours.length).fill({}).map((_, index) => ({
            image: React.createRef(null),
            titre: React.createRef(null),
            date: React.createRef(null),
            min: React.createRef(null),
            heures: React.createRef(null),
            jours: React.createRef(null),
            semaines: React.createRef(null),
            mois: React.createRef(null),
            annee: React.createRef(null),
            niveaux: React.createRef(null),
            descript: React.createRef(null),
            moodule: Array(cours[index].moodule.length).fill({}).map((__, tableIndex) => ({
                titre: React.createRef(null),
                moduleDescription: React.createRef(null),
                mooduleOption: Array(cours[index].moodule[tableIndex].mooduleOption.length).fill({}).map((__, tableIndexOption) => ({
                    sous_titre: React.createRef(null),
                    sous_description: React.createRef(null),
                    photo_cover: React.createRef(null),
                    url: React.createRef(null),
                })),
            })),

            quiz: Array(cours[index].quiz.length).fill({}).map((__, tableIndex) => ({
                question: React.createRef(null),
                reponse: React.createRef(null),
                option_choisir: Array(cours[index].quiz[tableIndex].option_choisir.length).fill({}).map((__, tableIndexOption) => ({
                    choix: React.createRef(null),
                })),
            })),
            ressources: Array(cours[index].ressources.length).fill({}).map((__, tableIndex) => ({
                titre: React.createRef(null),
                image_ressources: React.createRef(null),
                url: React.createRef(null),
            })),
        }));
    }

    const handleInputChange = (index, field, value) => {
        const newBlocs = [...cours];
        if (field === "image") {
            newBlocs[index].preview = URL.createObjectURL(value);
            newBlocs[index].image = value;
        } else {
            newBlocs[index][field] = value;
        }
        setBlocs(newBlocs);
    };

    // ...........................Module................
    const addMoodule = (index) => {
        const newBlocs = [...cours];
        newBlocs[index].moodule.push({ titre: '', moduleDescription: '', mooduleOption: [{ sous_titre: '', sous_description: '', photo_cover: '', url: '', preview: '' }] });
        setBlocs(newBlocs);
        // Vérifie si les références dans inputRefs sont synchronisées avec le nouveau moodule
        if (inputRefs.current[index].moodule.length !== newBlocs[index].moodule.length) {
            inputRefs.current[index].moodule.push({
                titre: React.createRef(),
                moduleDescription: React.createRef(),
                mooduleOption: [{
                    sous_titre: React.createRef(),
                    sous_description: React.createRef(),
                    photo_cover: React.createRef(),
                    url: React.createRef(),
                    preview: React.createRef()
                }]
            });
        }
    };
    const removeMoodule = (blocIndex, tableIndex) => {
        const newBlocs = [...cours];
        newBlocs[blocIndex].moodule.splice(tableIndex, 1);
        inputRefs.current[blocIndex].moodule.splice(tableIndex, 1);
        setBlocs(newBlocs);
    };
    const addMooduleOption = (blocIndex, moduleIndex) => {
        const newBlocs = [...cours];
        newBlocs[blocIndex].moodule[moduleIndex].mooduleOption.push({
            sous_titre: '',
            sous_description: '',
            photo_cover: '',
            url: '',
            preview: ''
        });
        setBlocs(newBlocs);
        // Synchroniser les références dans inputRefs pour les nouvelles options
        if (inputRefs.current[blocIndex].moodule[moduleIndex].mooduleOption.length !==
            newBlocs[blocIndex].moodule[moduleIndex].mooduleOption.length) {

            inputRefs.current[blocIndex].moodule[moduleIndex].mooduleOption.push({
                sous_titre: React.createRef(),
                sous_description: React.createRef(),
                photo_cover: React.createRef(),
                url: React.createRef(),
                preview: React.createRef()
            });
        }
    };
    const removeMooduleOption = (blocIndex, moduleIndex, optionIndex) => {
        const newBlocs = [...cours];
        newBlocs[blocIndex].moodule[moduleIndex].mooduleOption.splice(optionIndex, 1);
        setBlocs(newBlocs);
        if (inputRefs.current[blocIndex].moodule[moduleIndex].mooduleOption.length > optionIndex) {
            inputRefs.current[blocIndex].moodule[moduleIndex].mooduleOption.splice(optionIndex, 1);
        }
    };
    const handleModuleInputChange = (blocIndex, tableIndex, field, value) => {
        const newBlocs = [...cours];
        newBlocs[blocIndex].moodule[tableIndex][field] = value;
        setBlocs(newBlocs);
    };
    const handleModuleOptionInputChange = (blocIndex, tableIndex, optionIndex, field, value) => {
        const newBlocs = [...cours];
        if (field === 'photo_cover') {
            newBlocs[blocIndex].moodule[tableIndex].mooduleOption[optionIndex].preview = URL.createObjectURL(value);
            newBlocs[blocIndex].moodule[tableIndex].mooduleOption[optionIndex][field] = value;
        } else {
            newBlocs[blocIndex].moodule[tableIndex].mooduleOption[optionIndex][field] = value;
        }
        setBlocs(newBlocs);
    };


    // ...........................Quiz................
    const addQuiz = (index) => {
        const newBlocs = [...cours];
        newBlocs[index].quiz.push({ question: '', reponse: '', option_choisir: [{ choix: '' }] });
        setBlocs(newBlocs);
        // Vérifie si les références dans inputRefs sont synchronisées avec le nouveau moodule
        if (inputRefs.current[index].quiz.length !== newBlocs[index].quiz.length) {
            inputRefs.current[index].quiz.push({
                question: React.createRef(),
                reponse: React.createRef(),
                option_choisir: [{
                    choix: React.createRef(),
                }]
            });
        }
    };
    const removeQuiz = (blocIndex, tableIndex) => {
        const newBlocs = [...cours];
        newBlocs[blocIndex].quiz.splice(tableIndex, 1);
        inputRefs.current[blocIndex].quiz.splice(tableIndex, 1);
        setBlocs(newBlocs);
    };
    const addOption_choisir = (blocIndex, moduleIndex) => {
        const newBlocs = [...cours];
        newBlocs[blocIndex].quiz[moduleIndex].option_choisir.push({
            choix: ''
        });
        setBlocs(newBlocs);
        // Synchroniser les références dans inputRefs pour les nouvelles options
        if (inputRefs.current[blocIndex].quiz[moduleIndex].option_choisir.length !==
            newBlocs[blocIndex].quiz[moduleIndex].option_choisir.length) {
            inputRefs.current[blocIndex].quiz[moduleIndex].option_choisir.push({
                choix: React.createRef(),
            });
        }
    };
    const removeOption_choisir = (blocIndex, moduleIndex, optionIndex) => {
        const newBlocs = [...cours];
        newBlocs[blocIndex].quiz[moduleIndex].option_choisir.splice(optionIndex, 1);
        setBlocs(newBlocs);
        if (inputRefs.current[blocIndex].quiz[moduleIndex].option_choisir.length > optionIndex) {
            inputRefs.current[blocIndex].quiz[moduleIndex].option_choisir.splice(optionIndex, 1);
        }
    };
    const handleQuizInputChange = (blocIndex, tableIndex, field, value) => {
        const newBlocs = [...cours];
        newBlocs[blocIndex].quiz[tableIndex][field] = value;
        setBlocs(newBlocs);
    };
    const handleOption_choisirInputChange = (blocIndex, tableIndex, optionIndex, field, value) => {
        const newBlocs = [...cours];
        newBlocs[blocIndex].quiz[tableIndex].option_choisir[optionIndex][field] = value;
        setBlocs(newBlocs);
    };


    // ...........................Ressources................
    const addRessources = (index) => {
        const newBlocs = [...cours];
        newBlocs[index].ressources.push({ titre: '', image_ressources: '', url: '' });
        setBlocs(newBlocs);
        if (inputRefs.current[index].ressources.length !== newBlocs[index].ressources.length) {
            inputRefs.current[index].ressources.push({
                titre: React.createRef(),
                image_ressources: React.createRef(),
                url: React.createRef(),
            });
        }
    };
    const removeRessources = (blocIndex, tableIndex) => {
        const newBlocs = [...cours];
        newBlocs[blocIndex].ressources.splice(tableIndex, 1);
        inputRefs.current[blocIndex].ressources.splice(tableIndex, 1);
        setBlocs(newBlocs);
    };
    const handleRessourcesInputChange = (blocIndex, tableIndex, field, value) => {
        const newBlocs = [...cours];
        if (field === "image") {
            newBlocs[blocIndex].ressources[tableIndex].preview = URL.createObjectURL(value);
            newBlocs[blocIndex].ressources[tableIndex].image_ressources = value;
        } else {
            newBlocs[blocIndex].ressources[tableIndex][field] = value;
        }
        setBlocs(newBlocs);
    };

    // ....................................................................................................

    const animateAndScrollToRef = (ref) => {
        if (ref && ref.current) {
            ref.current.classList.add('animate__animated', 'animate__shakeX', 'border-2', 'border-red-500', 'outline-none',);
            setTimeout(() => {
                ref.current.classList.remove('animate__animated', 'animate__shakeX', 'border-2', 'border-red-500', 'outline-none',);
            }, 5000);
            ref.current.focus();
        }
    };


    const validateBloc = (bloc, index) => {
        const requiredFields = ['image', 'titre', 'date', 'min', 'heures', 'jours', "semaines", 'mois', "annee", "descript", "niveaux"];
        for (const field of requiredFields) {
            if (!bloc[field]) {
                toast.warning(<div>Le <strong>{
                    field === 'image' ? 'image  du cours' :
                        field === 'titre' ? 'titre du cours ' :
                            field === 'date' ? 'date du cours ' :
                                field === 'min' ? 'minute du cours ' :
                                    field === 'heures' ? 'heures du cours ' :
                                        field === 'jours' ? 'jours du cours ' :
                                            field === 'semaines' ? 'semaines du cours ' :
                                                field === 'mois' ? 'mois du cours ' :
                                                    field === 'annee' ? 'annee du cours ' :
                                                        field === 'descript' ? 'déscription du cours ' :
                                                            field === 'niveaux' ? 'niveaux du cours' :
                                                                null}</strong> est obligatoire à la block {index + 1}.</div>);
                if (inputRefs.current[index][field].current) {
                    inputRefs.current[index][field].current.classList.add('animate__animated', 'animate__shakeX', 'border-2', 'border-red-500', 'outline-none',);
                    setTimeout(() => {
                        inputRefs.current[index][field].current.classList.remove('animate__animated', 'animate__shakeX', 'border-2', 'border-red-500', 'outline-none',);
                    }, 5000);
                    // inputRefs.current[index][field].current.scrollIntoView({ behavior: 'smooth', block: 'center' });
                    inputRefs.current[index][field].current.focus();
                    return false;
                }
            }
            animateAndScrollToRef(inputRefs.current[index].titreMateriel);
        }
        return true;


    };
    // const validateTable = (table, blocIndex, tableIndex) => {
    //     const requiredFields = ['titre', 'moduleDescription'];
    //     for (const field of requiredFields) {
    //         if (!table[field]) {
    //             toast.warning(<div>Le<strong>{
    //                 field === 'titre' ? 'plage d\'heure' :
    //                     field === 'moduleDescription' ? 'prix est initialise à zéro' :
    //                         null}</strong> est obligatoire à l'horaire {blocIndex + 1}, table {tableIndex + 1}.</div>);
    //             if (inputRefs.current[blocIndex].moodule[tableIndex][field].current) {
    //                 inputRefs.current[blocIndex].moodule[tableIndex][field].current.classList.add('animate__animated', 'animate__shakeX', 'border-2', 'border-red-500', 'outline-none',);
    //                 setTimeout(() => {
    //                     inputRefs.current[blocIndex].moodule[tableIndex][field].current.classList.remove('animate__animated', 'animate__shakeX', 'border-2', 'border-red-500', 'outline-none',);
    //                 }, 5000);
    //                 inputRefs.current[blocIndex].moodule[tableIndex][field].current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    //                 inputRefs.current[blocIndex].moodule[tableIndex][field].current.focus();
    //                 return false;
    //             }
    //         }
    //     }
    //     return true;
    // };


    // const validateportion = (table, blocIndex, tableIndex) => {
    //     const requiredFields = ['titre', "prix"];
    //     for (const field of requiredFields) {
    //         if (!table[field]) {
    //             toast.warning(
    //                 <div>Le<strong>{
    //                     field === 'titre' ? 'titre' :
    //                         field === 'prix' ? 'prix' :
    //                             null
    //                 }</strong> est obligatoire à l'horaire {blocIndex + 1}, table {tableIndex + 1}.</div>);
    //             if (inputRefs.current[blocIndex].portion[tableIndex][field].current) {
    //                 inputRefs.current[blocIndex].portion[tableIndex][field].current.classList.add('animate__animated', 'animate__shakeX', 'border-2', 'border-red-500', 'outline-none',);
    //                 setTimeout(() => {
    //                     inputRefs.current[blocIndex].portion[tableIndex][field].current.classList.remove('animate__animated', 'animate__shakeX', 'border-2', 'border-red-500', 'outline-none',);
    //                 }, 5000);
    //                 inputRefs.current[blocIndex].portion[tableIndex][field].current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    //                 inputRefs.current[blocIndex].portion[tableIndex][field].current.focus();
    //                 return false;
    //             }
    //         }
    //     }
    //     return true;
    // };
    // const validateaAccomp = (table, blocIndex, tableIndex) => {
    //     const requiredFields = ['titre', "prix"];
    //     for (const field of requiredFields) {
    //         if (!table[field]) {
    //             toast.warning(
    //                 <div>Le<strong>{
    //                     field === 'titre' ? 'titre' :
    //                         field === 'prix' ? 'prix d\'accompagnement' :
    //                             null
    //                 }</strong> est obligatoire de la block {blocIndex + 1}, et {tableIndex + 1}.</div>);
    //             if (inputRefs.current[blocIndex].accomp[tableIndex][field].current) {
    //                 inputRefs.current[blocIndex].accomp[tableIndex][field].current.classList.add('animate__animated', 'animate__shakeX', 'border-2', 'border-red-500', 'outline-none',);
    //                 setTimeout(() => {
    //                     inputRefs.current[blocIndex].accomp[tableIndex][field].current.classList.remove('animate__animated', 'animate__shakeX', 'border-2', 'border-red-500', 'outline-none',);
    //                 }, 5000);
    //                 inputRefs.current[blocIndex].accomp[tableIndex][field].current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    //                 inputRefs.current[blocIndex].accomp[tableIndex][field].current.focus();
    //                 return false;
    //             }
    //         }
    //     }

    //     return true;
    // };


    function toRoman(num) {
        const romanNumerals = [
            "I", "II", "III", "IV", "V", "VI", "VII", "VIII", "IX", "X",
            "XI", "XII", "XIII", "XIV", "XV", "XVI", "XVII", "XVIII", "XIX", "XX",
            "XXI", "XXII", "XXIII", "XXIV", "XXV", "XXVI", "XXVII", "XXVIII", "XXIX", "XXX"
        ];
        return romanNumerals[num - 1] || num.toString();
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        for (let index = 0; index < cours.length; index++) {
            if (!validateBloc(cours[index], index)) {
                return;
            }

            // for (let tableIndex = 0; tableIndex < cours[index].accomp.length; tableIndex++) {
            //     if (!validateaAccomp(cours[index].accomp[tableIndex], index, tableIndex)) {
            //         return;
            //     }
            // }
        }
        toast.success("Le cours est enregistre avec succés")
        console.log("cours", cours)

    };



    return (
        <div className={`w-full overflow-y-auto ${isDarVisitLoggined === "dark" ? null : 'bg-gray-100'}  overflow-x-hidden h-[87vh]`}>
            <div className="w-full flex justify-start">
                <div onClick={() => navig("/cours")} className="text-[#3ad12c] p-2 cursor-pointer">
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-arrow-left-circle-fill" viewBox="0 0 16 16">
                        <path d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0m3.5 7.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5z" />
                    </svg>
                </div>
            </div>

            <form onSubmit={handleSubmit} className={`flex m-4  rounded-md ${isDarVisitLoggined === "dark" ? 'bg-gray-800' : 'bg-white'}  flex-col items-center`}>
                <div className="rounded-lg p-2   w-full   sm:p-4">
                    {cours.map((bloc, index) => (
                        <div key={index} className="justify-center rounded-xl w-full p-1 sm:p-2 mb-2  flex flex-col">
                            <div className="w-full">
                                <div className="h-max w-full">
                                    <div className="px-3 w-full">
                                        <div className="w-full flex items_center justify-between">
                                            <label className="block text-xs mt-5  font-[700]  first-letter:uppercase tracking_wide text-grey-darker sm:text-lg mb-1">
                                                {toRoman(index + 1)}. cours
                                            </label>
                                            {index > 0 && (
                                                <button
                                                    type="button"
                                                    className="text-red-500 px-1 max-sm:text-xs py-2"
                                                    onClick={() => removeBloc(index)}
                                                >
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-trash3-fill" viewBox="0 0 16 16">
                                                        <path d="M11 1.5v1h3.5a.5.5 0 0 1 0 1h-.538l-.853 10.66A2 2 0 0 1 11.115 16h-6.23a2 2 0 0 1-1.994-1.84L2.038 3.5H1.5a.5.5 0 0 1 0-1H5v-1A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5m-5 0v1h4v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5M4.5 5.029l.5 8.5a.5.5 0 1 0 .998-.06l-.5-8.5a.5.5 0 1 0-.998.06m6.53-.528a.5.5 0 0 0-.528.47l-.5 8.5a.5.5 0 0 0 .998.058l.5-8.5a.5.5 0 0 0-.47-.528M8 4.5a.5.5 0 0 0-.5.5v8.5a.5.5 0 0 0 1 0V5a.5.5 0 0 0-.5-.5" />
                                                    </svg>
                                                </button>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="mb-2">
                                <div className="rounded-xl p-2 w-full">
                                    <div className="flex flex-col">
                                        <div className="mb-2">
                                            <div className="flex flex-col items-center">
                                                <label className={`w-full  border border-gray-300  block cursor-pointer  outline-none focus:border-[#3ad12c]  rounded-md p-2.5  bg-transparent `}
                                                    ref={inputRefs.current[index].image}
                                                    htmlFor={`id_photo_${index}`}
                                                >
                                                    Photos de couverture
                                                </label>
                                                <input
                                                    type="file"
                                                    accept=".jpeg,.png,.jpg,.gif"
                                                    className="w-full hidden mt-1"
                                                    id={`id_photo_${index}`}
                                                    onChange={(e) => handleInputChange(index, 'image', e.target.files[0])}

                                                />
                                                <div className="flex w-full justify-start">
                                                    {bloc.preview && (
                                                        <div className='w-[15em] mt-[2.5em] overflow-hidden relative rounded-lg h-[15em] border border-gray-300'>
                                                            <img
                                                                src={bloc.preview}
                                                                alt={`Aperçu ${index}`}
                                                                className="object-cover w-full h-full"
                                                            />
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="flex flex-col">
                                            <label htmlFor={`titre_${index}`} className="block mt-5  first -letter:uppercase tracking-wide text-lg mb-1">Titre du cours</label>
                                            <input
                                                email="text"
                                                id={`titre_${index}`}
                                                value={bloc.titre}
                                                onChange={(e) => handleInputChange(index, 'titre', e.target.value)}
                                                placeholder="Le  titre  du cours"
                                                className="w-full border bg-transparent border-gray-300 rounded-md px-2 py-3 outline-none focus:border-[#3ad12c]"
                                                ref={inputRefs.current[index].titre}
                                            />
                                        </div>
                                        <div className="flex flex-col ">
                                            <label htmlFor={`data_${index}`} className="block mt-5  first -letter:uppercase tracking-wide text-lg mb-1">Date</label>
                                            <input
                                                type="date"
                                                id={`data_${index}`}
                                                value={bloc.date}
                                                onChange={(e) => handleInputChange(index, 'date', e.target.value)}
                                                placeholder="dd-mm-yyyy"
                                                min="19910-01-01"
                                                className="w-full border bg-transparent border-gray-300 rounded-md px-2 py-3 outline-none focus:border-[#3ad12c]"
                                                ref={inputRefs.current[index].date}
                                            />
                                        </div>
                                        <div className="flex flex-col mt-5 mb-5">
                                            <label htmlFor={`descript_${index}`} className="block mt-5  first -letter:uppercase tracking-wide text-lg mb-1">Déscription</label>
                                            <textarea
                                                email="text"
                                                id={`descript_${index}`}
                                                value={bloc.descript}
                                                onChange={(e) => handleInputChange(index, 'descript', e.target.value)}
                                                placeholder="Déscription"
                                                className="w-full border bg-transparent min-h-[35vh] border-gray-300 rounded-md px-2 py-3 outline-none focus:border-[#3ad12c]"
                                                ref={inputRefs.current[index].descript}
                                            >
                                            </textarea>
                                        </div>
                                        <div className="grid grid-cols-3 gap-2">
                                            <div className="flex flex-col ">
                                                <label htmlFor={`min_${index}`} className="block mt-5  first -letter:uppercase tracking-wide text-lg mb-1">Minute</label>
                                                <input
                                                    type="text"
                                                    id={`min_${index}`}
                                                    value={bloc.min}
                                                    onChange={(e) => handleInputChange(index, 'min', e.target.value)}
                                                    placeholder="Minute"

                                                    className="w-full border bg-transparent border-gray-300 rounded-md p-2  outline-none focus:border-[#3ad12c]"
                                                    ref={inputRefs.current[index].min}
                                                />
                                            </div>

                                            <div className="flex flex-col ">
                                                <label htmlFor={`heures_${index}`} className="block mt-5  first -letter:uppercase tracking-wide text-lg mb-1">Heures</label>
                                                <input
                                                    type="text"
                                                    id={`heures_${index}`}
                                                    value={bloc.heures}
                                                    onChange={(e) => handleInputChange(index, 'heures', e.target.value)}
                                                    placeholder="Heures"

                                                    className="w-full border bg-transparent border-gray-300 rounded-md p-2 outline-none focus:border-[#3ad12c]"
                                                    ref={inputRefs.current[index].heures}
                                                />
                                            </div>

                                            <div className="flex flex-col ">
                                                <label htmlFor={`jours_${index}`} className="block mt-5  first -letter:uppercase tracking-wide text-lg mb-1">Jours</label>
                                                <input
                                                    type="text"
                                                    id={`jours_${index}`}
                                                    value={bloc.jours}
                                                    onChange={(e) => handleInputChange(index, 'jours', e.target.value)}
                                                    placeholder="jours"

                                                    className="w-full border bg-transparent border-gray-300 rounded-md p-2 outline-none focus:border-[#3ad12c]"
                                                    ref={inputRefs.current[index].jours}
                                                />
                                            </div>

                                            <div className="flex flex-col ">
                                                <label htmlFor={`semaines_${index}`} className="block mt-5  first -letter:uppercase tracking-wide text-lg mb-1">Semaines</label>
                                                <input
                                                    type="text"
                                                    id={`semaines_${index}`}
                                                    value={bloc.semaines}
                                                    onChange={(e) => handleInputChange(index, 'semaines', e.target.value)}
                                                    placeholder="Semaines"

                                                    className="w-full border bg-transparent border-gray-300 rounded-md p-2 outline-none focus:border-[#3ad12c]"
                                                    ref={inputRefs.current[index].semaines}
                                                />
                                            </div>

                                            <div className="flex flex-col ">
                                                <label htmlFor={`date_${index}`} className="block mt-5  first -letter:uppercase tracking-wide text-lg mb-1">Mois</label>
                                                <input
                                                    type="text"
                                                    id={`mois_${index}`}
                                                    value={bloc.mois}
                                                    onChange={(e) => handleInputChange(index, 'mois', e.target.value)}
                                                    placeholder="Mois"

                                                    className="w-full border bg-transparent border-gray-300 rounded-md p-2 outline-none focus:border-[#3ad12c]"
                                                    ref={inputRefs.current[index].mois}
                                                />
                                            </div>

                                            <div className="flex flex-col ">
                                                <label htmlFor={`date_${index}`} className="block mt-5  first -letter:uppercase tracking-wide text-lg mb-1">Année</label>
                                                <input
                                                    type="text"
                                                    id={`date_${index}`}
                                                    value={bloc.annee}
                                                    onChange={(e) => handleInputChange(index, 'annee', e.target.value)}
                                                    placeholder="Annee"

                                                    className="w-full border bg-transparent border-gray-300 rounded-md p-2 outline-none focus:border-[#3ad12c]"
                                                    ref={inputRefs.current[index].annee}
                                                />
                                            </div>
                                        </div>
                                        <div className="flex flex-col mb-10">
                                            <label htmlFor={`niveaux_${index}`} className="block mt-5  first -letter:uppercase tracking-wide text-lg mb-1">Niveau</label>
                                            <select

                                                id={`niveaux_${index}`}
                                                value={bloc.niveaux}
                                                onChange={(option) => handleInputChange(index, 'niveaux', option.target.value, option, false)}
                                                placeholder="Le  titre du restaurant "
                                                className="w-full border bg-transparent border-gray-300 rounded-md px-2 py-3 outline-none focus:border-[#3ad12c]"
                                                ref={inputRefs.current[index].niveaux}
                                            >
                                                <option hidden value="  " >Sélectionne le niveau</option>
                                                <option className="text-black" value="avance" >Niveau avance</option>
                                                <option className="text-black" value="international" >Niveau international</option>
                                                <option className="text-black" value="international" >Niveau débutant</option>

                                            </select>
                                        </div>
                                        <div className="flex flex-col mb-10">
                                            {bloc.moodule.map((table, tableIndex) => (
                                                <div key={tableIndex} className="">
                                                    <div className="relative mt-2 text-lg">{toRoman(tableIndex + 1)}.Module <span className="pl-2 font-bold">{bloc.titre}</span> </div>
                                                    <div className="rounded-lg   w-[100%] pl-[4%]">
                                                        <div className="rounded-xl  w-full">
                                                            <div className="flex flex-col">
                                                                <div className="rounded-lg w-full">
                                                                    <div className="w-full mb-2">
                                                                        <label
                                                                            className="block tracking-wide text-grey-darker text-lg mb-2"
                                                                            htmlFor={`titre_${index}_${tableIndex}`}
                                                                        >
                                                                            Titre
                                                                        </label>
                                                                        <input
                                                                            ref={inputRefs.current[index].moodule[tableIndex].sous_titre}
                                                                            className="appearance-none block  w-full bg-transparent text-grey-darker border border-red rounded  p-2 mb-3"
                                                                            value={table.sous_titre}
                                                                            onChange={(e) => handleModuleInputChange(index, tableIndex, 'sous_titre', e.target.value)}

                                                                            placeholder="Titre"
                                                                            id={`titre_${index}_${tableIndex}`}
                                                                        />
                                                                    </div>
                                                                </div>


                                                                <div className="rounded-lg w-full">
                                                                    <div className="w-full mb-5">
                                                                        <label
                                                                            className="block  tracking-wide text-grey-darker text-lg  mb-2"
                                                                            htmlFor={`titre_${index}_${tableIndex}`}
                                                                        >                                                                    Déscription
                                                                        </label>
                                                                        <textarea
                                                                            type="number"
                                                                            id={`titre_${index}_${tableIndex}`}
                                                                            ref={inputRefs.current[index].moodule[tableIndex].sous_description}
                                                                            className="appearance-none block  min-h-[10em]  w-full bg-transparent text-grey-darker border border-red rounded  p-2 mb-3"
                                                                            value={table.sous_description}
                                                                            onChange={(e) => handleModuleInputChange(index, tableIndex, 'sous_description', e.target.value)}
                                                                            placeholder="Déscription"
                                                                        />
                                                                    </div>
                                                                </div>

                                                            </div>

                                                            <div>
                                                                <div className="w-full mb-2">
                                                                    <label
                                                                        className="block tracking-wide text-grey-darker text-lg mb-2"
                                                                        htmlFor={`titre_${index}_${tableIndex}`}
                                                                    >
                                                                        Option de module:
                                                                    </label>
                                                                    <div className="ml-10">
                                                                        {table.mooduleOption.map((option, option_index) => (
                                                                            <>
                                                                                {option_index > 0 && <button
                                                                                    className="text-red-500 my-1"
                                                                                    onClick={() => removeMooduleOption(index, tableIndex, option_index)}
                                                                                >
                                                                                    Supprimer cette option
                                                                                </button>
                                                                                }
                                                                                <div className="flex flex-col">

                                                                                    <div className="rounded-lg w-full">
                                                                                        <div className="w-full mb-2">
                                                                                            <label
                                                                                                className="block tracking-wide text-grey-darker text-lg mb-2"
                                                                                                htmlFor={`sous_titre_${index}_${tableIndex}_${option_index}`}
                                                                                            >
                                                                                                Sous-titre
                                                                                            </label>
                                                                                            <input
                                                                                                ref={inputRefs.current[index].moodule[tableIndex].mooduleOption[option_index].sous_titre}
                                                                                                className="appearance-none block  outline-none focus:border-[#3ad12c]  w-full bg-transparent text-grey-darker border border-red rounded  p-2 mb-3"
                                                                                                value={option.sous_titre}
                                                                                                onChange={(e) => handleModuleOptionInputChange(index, tableIndex, option_index, 'sous_titre', e.target.value)}
                                                                                                placeholder="Sous-titre"
                                                                                                id={`sous_titre_${index}_${tableIndex}_${option_index}`} />
                                                                                        </div>
                                                                                    </div>


                                                                                    <div className="rounded-lg w-full">
                                                                                        <div className="w-full mb-5">
                                                                                            <label
                                                                                                className="block  tracking-wide text-grey-darker text-lg  mb-2"
                                                                                                htmlFor={`sous_description_${index}_${tableIndex}_${option_index}`}
                                                                                            >
                                                                                                Déscription de sous titre
                                                                                            </label>
                                                                                            <textarea
                                                                                                type="number"
                                                                                                id={`sous_description_${index}_${tableIndex}_${option_index}`}
                                                                                                ref={inputRefs.current[index].moodule[tableIndex].mooduleOption[option_index].sous_description}
                                                                                                className="appearance-none block  min-h-[6em]  outline-none focus:border-[#3ad12c] w-full bg-transparent text-grey-darker border border-red rounded  p-2 mb-3"
                                                                                                value={option.sous_description}
                                                                                                onChange={(e) => handleModuleOptionInputChange(index, tableIndex, option_index, 'sous_description', e.target.value)}
                                                                                                placeholder="Déscription" />
                                                                                        </div>
                                                                                    </div>
                                                                                    {console.log("table.url", table.url)}
                                                                                    <div className="mb-2 flex gap-10">
                                                                                        {table.url !== '' &&
                                                                                            <div className="flex flex-col w-1/2 items-start">
                                                                                                <label
                                                                                                    className="block tracking-wide text-grey-darker text-lg mb-2"
                                                                                                    htmlFor={`titre_${index}_${tableIndex}`}
                                                                                                >
                                                                                                    Photos de couverture
                                                                                                </label>

                                                                                                <label className={`w-full  border border-gray-300  block cursor-pointer  outline-none focus:border-[#3ad12c]  rounded-md p-2.5  bg-transparent `}
                                                                                                    ref={inputRefs.current[index].moodule[tableIndex].mooduleOption[option_index].photo_cover}
                                                                                                    htmlFor={`photo_cover_${index}_${tableIndex}_${option_index}`}
                                                                                                >
                                                                                                    Photos
                                                                                                </label>
                                                                                                <input
                                                                                                    type="file"
                                                                                                    accept=".jpeg,.png,.jpg,.gif"
                                                                                                    className="w-full hidden mt-1"
                                                                                                    id={`photo_cover_${index}_${tableIndex}_${option_index}`}
                                                                                                    onChange={(e) => handleModuleOptionInputChange(index, tableIndex, option_index, 'photo_cover', e.target.files[0])}

                                                                                                />

                                                                                                <div className="flex w-full justify-start">
                                                                                                    {option.preview && (
                                                                                                        <div className='w-[15em] mt-[2.5em] overflow-hidden relative rounded-lg h-[15em] border border-gray-300'>
                                                                                                            <img
                                                                                                                src={option.preview}
                                                                                                                alt={`Aperçu ${index}`}
                                                                                                                className="object-cover w-full h-full"
                                                                                                            />
                                                                                                        </div>
                                                                                                    )}
                                                                                                </div>
                                                                                            </div>
                                                                                        }
                                                                                        {!option.preview && <div className="rounded-lg w-1/2">

                                                                                            <div className="w-full mb-2">
                                                                                                <label
                                                                                                    className="block tracking-wide text-grey-darker text-lg mb-2"
                                                                                                    htmlFor={`url_${index}_${tableIndex}_${option_index}`}
                                                                                                >
                                                                                                    Url de video de youtube
                                                                                                </label>
                                                                                                <input
                                                                                                    ref={inputRefs.current[index].moodule[tableIndex].mooduleOption[option_index].url}
                                                                                                    className="appearance-none block  outline-none focus:border-[#3ad12c]  w-full bg-transparent text-grey-darker border border-red rounded  p-2 mb-3"
                                                                                                    value={table.url}
                                                                                                    id={`url_${index}_${tableIndex}_${option_index}`}
                                                                                                    onChange={(e) => handleModuleOptionInputChange(index, tableIndex, option_index, 'url', e.target.value)}
                                                                                                    placeholder=" Url de video de youtube" />
                                                                                            </div>

                                                                                        </div>
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                            </>
                                                                        ))}

                                                                    </div>
                                                                    <div className="flex justify-end pl-[4%] w-full">
                                                                        <div
                                                                            className="bg-[#3ad12c] cursor-pointer hover-bg-blue-700 text-white py-1 px-2 max-sm:text-xs sm:py-2 sm:px-4 rounded"
                                                                            onClick={() => addMooduleOption(index, tableIndex)}
                                                                        >
                                                                            Ajouter autre option de module
                                                                        </div>
                                                                    </div>
                                                                    <hr />
                                                                </div>
                                                            </div>
                                                        </div>


                                                        {bloc.moodule.length > 1 && (
                                                            <div className="flex justify-end">
                                                                <div
                                                                    montant="button"
                                                                    className="text-red-500 cursor-pointer px-1 max-sm:text-xs py-2"
                                                                    onClick={() => removeMoodule(index, tableIndex)}
                                                                >
                                                                    Supprimer
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            ))}


                                            <div className="flex justify-start pl-[4%] w-full">
                                                <div

                                                    className="bg-[#3ad12c] cursor-pointer hover-bg-blue-700 text-white py-1 px-2 max-sm:text-xs sm:py-2 sm:px-4 rounded"
                                                    onClick={() => addMoodule(index)}
                                                >
                                                    Ajouter autre module
                                                </div>
                                            </div>
                                        </div>

                                        <div className="flex flex-col mb-10">
                                            <div className="relative mt-2 text-lg">Quiz</div>
                                            {bloc.quiz.map((table, tableIndex) => (
                                                <div key={tableIndex} className="">
                                                    <div className="rounded-lg   w-[100%] pl-[4%]">
                                                        <div className="rounded-xl  w-full">
                                                            <div className="flex flex-col">
                                                                <div className="rounded-lg w-full">
                                                                    <div className="w-full mb-2">
                                                                        <label
                                                                            className="block tracking-wide text-grey-darker text-lg mb-2"
                                                                            htmlFor={`question_${index}_${tableIndex}`}
                                                                        >
                                                                            {toRoman(tableIndex + 1)}.Question
                                                                        </label>
                                                                        <input
                                                                            ref={inputRefs.current[index].quiz[tableIndex].question}
                                                                            className="appearance-none block  outline-none focus:border-[#3ad12c]  w-full bg-transparent text-grey-darker border border-red rounded  p-2 mb-3"
                                                                            value={table.question}
                                                                            onChange={(e) => handleQuizInputChange(index, tableIndex, 'question', e.target.value)}
                                                                            placeholder="Question"
                                                                            id={`question_${index}_${tableIndex}`}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="rounded-lg w-full">
                                                                    <div className="w-full mb-2">
                                                                        <label
                                                                            className="block tracking-wide text-grey-darker text-lg mb-2"
                                                                            htmlFor={`reponse_${index}_${tableIndex}`}
                                                                        >
                                                                            Réponse
                                                                        </label>
                                                                        <input
                                                                            ref={inputRefs.current[index].quiz[tableIndex].reponse}
                                                                            className="appearance-none block  outline-none focus:border-[#3ad12c] w-full bg-transparent text-grey-darker border border-red rounded  p-2 mb-3"
                                                                            value={table.reponse}
                                                                            onChange={(e) => handleQuizInputChange(index, tableIndex, 'reponse', e.target.value)}
                                                                            placeholder="Réponse"
                                                                            id={`reponse_${index}_${tableIndex}`}
                                                                        />
                                                                    </div>

                                                                    <div>
                                                                        <div className="w-full mb-2">
                                                                            <label
                                                                                className="block tracking-wide text-grey-darker text-lg mb-2"
                                                                                htmlFor={`titre_${index}_${tableIndex}`}
                                                                            >
                                                                                Question à choisir:
                                                                            </label>
                                                                            <div className="ml-10">
                                                                                {table.option_choisir.map((dataOption_choisi, index_option_choisir) => (
                                                                                    <div className="flex items-center mb-5">
                                                                                        <div className="text-lg mr-1">{index_option_choisir + 1}.</div>
                                                                                        <div className="w-full">
                                                                                            <input
                                                                                                placeholder="Question à choisir"
                                                                                                className="appearance-none block  outline-none focus:border-[#3ad12c]  w-full bg-transparent text-grey-darker border border-red rounded  p-2"
                                                                                                value={dataOption_choisi.choix}
                                                                                                ref={inputRefs.current[index].quiz[tableIndex].option_choisir[index_option_choisir].choix}
                                                                                                onChange={(e) => handleOption_choisirInputChange(index, tableIndex, index_option_choisir, 'choix', e.target.value)}

                                                                                            />

                                                                                        </div>
                                                                                        {index_option_choisir > 0 &&
                                                                                            <div onClick={() => removeOption_choisir(index, tableIndex, index_option_choisir)} className="border-red-500 border cursor-pointer block p-2 ml-2 rounded-md">
                                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi text-red-700  bi-trash3-fill" viewBox="0 0 16 16">
                                                                                                    <path d="M11 1.5v1h3.5a.5.5 0 0 1 0 1h-.538l-.853 10.66A2 2 0 0 1 11.115 16h-6.23a2 2 0 0 1-1.994-1.84L2.038 3.5H1.5a.5.5 0 0 1 0-1H5v-1A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5m-5 0v1h4v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5M4.5 5.029l.5 8.5a.5.5 0 1 0 .998-.06l-.5-8.5a.5.5 0 1 0-.998.06m6.53-.528a.5.5 0 0 0-.528.47l-.5 8.5a.5.5 0 0 0 .998.058l.5-8.5a.5.5 0 0 0-.47-.528M8 4.5a.5.5 0 0 0-.5.5v8.5a.5.5 0 0 0 1 0V5a.5.5 0 0 0-.5-.5" />
                                                                                                </svg>
                                                                                            </div>
                                                                                        }
                                                                                    </div>
                                                                                ))}





                                                                            </div>
                                                                            <div className="flex justify-end pl-[4%] w-full">
                                                                                <div

                                                                                    className="bg-[#3ad12c] cursor-pointer hover-bg-blue-700 text-white py-1 px-2 max-sm:text-xs sm:py-2 sm:px-4 rounded"
                                                                                    onClick={() => addOption_choisir(index, tableIndex)}
                                                                                >
                                                                                    Autre à choisir
                                                                                </div>
                                                                            </div>
                                                                            <hr />
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </div>


                                                        {bloc.moodule.length > 1 && (
                                                            <div className="flex justify-end">
                                                                <div
                                                                    montant="button"
                                                                    className="text-red-500 cursor-pointer px-1 max-sm:text-xs py-2"
                                                                    onClick={() => removeQuiz(index, tableIndex)}
                                                                >
                                                                    Supprimer
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            ))}
                                            <div className="flex justify-start pl-[4%] w-full">
                                                <div

                                                    className="bg-[#3ad12c] cursor-pointer hover-bg-blue-700 text-white py-1 px-2 max-sm:text-xs sm:py-2 sm:px-4 rounded"
                                                    onClick={() => addQuiz(index)}
                                                >
                                                    Ajouter autre question
                                                </div>
                                            </div>
                                        </div>








                                        <div className="flex flex-col mb-10">
                                            <label htmlFor={`titre_${index}`} className="block mt-5  first -letter:uppercase tracking-wide text-lg mb-1">Etude de cas</label>

                                            <div className="flex flex-col ml-5">
                                                <label htmlFor={`titre_${index}`} className="block mt-1  first -letter:uppercase tracking-wide text-lg mb-1">Titre</label>
                                                <input
                                                    email="text"
                                                    id={`titre_${index}`}
                                                    value={bloc.titre}
                                                    onChange={(e) => handleInputChange(index, 'titre', e.target.value)}
                                                    placeholder="Le  titre"
                                                    className="w-full border bg-transparent border-gray-300 rounded-md px-2 py-3 outline-none focus:border-[#3ad12c]"
                                                    ref={inputRefs.current[index].titre}
                                                />
                                            </div>
                                            <div className="flex flex-col ml-5 mb-5">
                                                <label htmlFor={`descript_${index}`} className="block mt-5  first -letter:uppercase tracking-wide text-lg mb-1">Déscription</label>
                                                <textarea
                                                    email="text"
                                                    id={`descript_${index}`}
                                                    value={bloc.descript}
                                                    onChange={(e) => handleInputChange(index, 'descript', e.target.value)}
                                                    placeholder="Déscription"
                                                    className="w-full border bg-transparent min-h-[10em] border-gray-300 rounded-md px-2 py-3 outline-none focus:border-[#3ad12c]"
                                                    ref={inputRefs.current[index].descript}
                                                >
                                                </textarea>
                                            </div>

                                        </div>

                                        <div className="flex flex-col mb-10">
                                            <div className="relative mt-2 text-lg">Ressources Complémentaires</div>
                                            {bloc.ressources.map((table, tableIndex) => (
                                                <div key={tableIndex} className="">
                                                    <div className="rounded-lg   w-[100%] pl-[4%]">
                                                        <div className="rounded-xl  w-full">
                                                            <div className="flex flex-col">
                                                                <div className="rounded-lg w-full">
                                                                    <div className="w-full mb-2">
                                                                        <label
                                                                            className="block tracking-wide text-grey-darker text-lg mb-2"
                                                                            htmlFor={`titre_${index}_${tableIndex}`}
                                                                        >
                                                                            {toRoman(tableIndex + 1)}.ressource
                                                                        </label>
                                                                        <input
                                                                            ref={inputRefs.current[index].ressources[tableIndex].titre}
                                                                            className="appearance-none block  outline-none focus:border-[#3ad12c]  w-full bg-transparent text-grey-darker border border-red rounded  p-2 mb-3"
                                                                            value={table.titre}
                                                                            onChange={(e) => handleRessourcesInputChange(index, tableIndex, 'titre', e.target.value)}
                                                                            placeholder="Question"
                                                                            id={`titre_${index}_${tableIndex}`}
                                                                        />
                                                                    </div>
                                                                </div>


                                                                <div className="mb-2 flex gap-10">
                                                                    {table.url === ''
                                                                        &&
                                                                        <div className="flex flex-col w-1/2 items-start">
                                                                            <label
                                                                                className="block tracking-wide text-grey-darker text-lg mb-2"

                                                                            >
                                                                                Fichier
                                                                            </label>

                                                                            <label className={`w-full  border border-gray-300  block cursor-pointer  outline-none focus:border-[#3ad12c]  rounded-md p-2.5  bg-transparent `}
                                                                                ref={inputRefs.current[index].image_ressources}
                                                                                htmlFor={`image_ressources_${index}_${tableIndex}`}
                                                                            >
                                                                                Fichier
                                                                            </label>
                                                                            <input
                                                                                type="file"
                                                                                accept=".jpeg,.png,.jpg,.gif"
                                                                                className="w-full hidden mt-1"
                                                                                id={`image_ressources_${index}_${tableIndex}`}
                                                                                onChange={(e) => handleRessourcesInputChange(index, tableIndex, 'image', e.target.files[0])}

                                                                            />
                                                                            <div className="flex w-full justify-start">
                                                                                {table.preview && (
                                                                                    <div className='w-[15em] mt-[2.5em] overflow-hidden  relative  rounded-lg h-[15em] border border-gray-300'>
                                                                                        <img
                                                                                            src={table.preview}
                                                                                            alt={`Aperçu ${index}`}
                                                                                            className="object-cover w-full h-full"
                                                                                        />
                                                                                    </div>
                                                                                )}
                                                                            </div>
                                                                        </div>}

                                                                    {!table.preview &&
                                                                        <div className="rounded-lg w-1/2">
                                                                            <div className="w-full mb-2">
                                                                                <label
                                                                                    className="block tracking-wide text-grey-darker text-lg mb-2"
                                                                                    htmlFor={`url_ressource_${index}_${tableIndex}`}
                                                                                >
                                                                                    Url de video de youtube
                                                                                </label>
                                                                                <input
                                                                                    ref={inputRefs.current[index].ressources[tableIndex].url}
                                                                                    className="appearance-none block  outline-none focus:border-[#3ad12c]  w-full bg-transparent text-grey-darker border border-red rounded  p-2 mb-3"
                                                                                    value={table.url}
                                                                                    onChange={(e) => handleRessourcesInputChange(index, tableIndex, 'url', e.target.value)}
                                                                                    placeholder=" Url de video de youtube"
                                                                                    id={`url_ressource_${index}_${tableIndex}`}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>


                                                        {bloc.moodule.length > 1 && (
                                                            <div className="flex justify-end">
                                                                <div
                                                                    montant="button"
                                                                    className="text-red-500 cursor-pointer px-1 max-sm:text-xs py-2"
                                                                    onClick={() => removeRessources(index, tableIndex)}
                                                                >
                                                                    Supprimer
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            ))}
                                            <div className="flex justify-start pl-[4%] w-full">
                                                <div

                                                    className="bg-[#3ad12c] cursor-pointer hover-bg-blue-700 text-white py-1 px-2 max-sm:text-xs sm:py-2 sm:px-4 rounded"
                                                    onClick={() => addRessources(index)}
                                                >
                                                    Ajouter autre ressource
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr />
                        </div>
                    ))}

                    <div className="w-full flex justify-start">
                        <div
                            className="bg-[#3ad12c] cursor-pointer hover-bg-blue-700 text-white py-1 px-2 max-sm:text-xs sm:py-2 sm:px-4 rounded"
                            onClick={addBloc}
                        >
                            Ajouter cours
                        </div>
                    </div>

                </div>
                <div className="flex justify-end my-5  w-[98%]">
                    <button type="submit" disabled={boutLoading} htmlFor="send" className="mt-3 cursor-pointer w-max flex justify-end bg-[#3ad12c] p-2 rounded text-white">
                        Enregistre
                    </button>
                </div>
            </form>
            <Footer />
        </div>
    );
}


export default AjouterCoursAdmin;
